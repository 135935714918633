import { uniqueId } from 'lodash'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import Tab from 'src/components/courses/course-detail/create-tabs/Tabs'
import { CoursesAPI } from 'src/apis/courses'
import VideoSetting from 'src/components/courses/course-detail/create-tabs/tabVideo/videoSetting'
import { changeNodeAtPath } from '../../course-detail/tree-course'
import { useConfirm } from 'src/hooks/use-confirm'
import { CourseCondition } from 'src/type/courses'
import { ITabData, IOpenCreateTab, ITabContent } from 'src/type/course-medium/section'
import { ParentsNode } from 'src/type'

interface Iprops {
  open: boolean
  setOpen: (state: IOpenCreateTab) => void
  course_section_id: string
  edit?: boolean
  setTreeData?: (newTreeData: ParentsNode[]) => void
  path?: Array<number>
  treeData?: ParentsNode[]
  nodeData?: ParentsNode
  onlyEdit?: boolean
  courseCondition: CourseCondition
  updateFinishButton?: (newValue: boolean) => void
  showActivityInformation?: boolean
}

const CreateTabs = ({
  open,
  setOpen,
  course_section_id,
  setTreeData,
  path,
  treeData,
  nodeData,
  courseCondition,
  updateFinishButton,
  showActivityInformation,
}: Iprops) => {
  const [data, setData] = useState<ITabData[]>([])

  const [fistLoad, setFistLoad] = useState(true)
  const [tabActive, setTabActive] = useState<string>('')
  const { contextHolder } = useConfirm()
  useLayoutEffect(() => {
    async function getTabIds() {
      try {
        setFistLoad(true)
        const res = await CoursesAPI.getTabDetail(course_section_id)
        const newData: ITabData[] = []
        // for (let e of res.data?.course_tab_documents) {
        newData.push({
          tabId: res.data.id,
          value: {
            tab_name: undefined,
            document: [],
          },
        })
        // }
        setData((prev: ITabData[]) => {
          setFistLoad(false)
          return newData
        })
      } catch (err) {
        setFistLoad(false)
        console.error(err)
      }
    }
    if (course_section_id) {
      getTabIds()
    }
  }, [course_section_id])
  // set lại total_tab mỗi khi thay đổi data
  useEffect(() => {
    if (data && path) {
      const listTab = data.filter((e) => !e.creating)
      const newNode = { ...nodeData, total_tab: listTab.length }
      const getNodeKey = ({ treeIndex }: ParentsNode) => treeIndex
      setTreeData?.(changeNodeAtPath({ treeData: treeData, path, getNodeKey, newNode }))
    }
  }, [path, data])

  // Nếu chưa có tab thì default add new tab
  useEffect(() => {
    if (data?.length === 0) {
      handleAddTab()
    }
  }, [data])

  const handleAddTab = () => {
    const newId = uniqueId('tab')

    setData((e: ITabData[]) => {
      const arr = [...e]
      arr.push({
        tabId: newId,
        value: {
          tab_name: undefined,
          document: [],
        },
        creating: true,
      })
      return arr
    })
    setTabActive(newId)
  }

  const handleClose = () => {
    // let body = ['Bạn có chắc chắn muốn quay lại không?']
    // confirm({
    //   okButtonCaption: 'Yes',
    //   cancelButtonCaption: 'No',
    //   body,
    //   onClick: async () => {
    //     setOpen({status: false, course_section_id: ''})
    //     setData([])
    //     setFistLoad(true)
    //   },
    // })
    setOpen({ status: true, course_section_id: '' })
    setData([])
    setFistLoad(true)
  }
  const [openSetting, setOpenSetting] = useState(false)
  return (
    <React.Fragment>
      {/* <Spin spinning={true}> */}
      {contextHolder}

      {/* {!fistLoad && ( */}
      <Tab
        closeModal={handleClose}
        handleAddTab={handleAddTab}
        data={data as ITabContent[]}
        courseCondition={courseCondition}
        isFistLoad={fistLoad}
        setTabActive={setTabActive}
        tabActive={tabActive}
        setData={setData}
        course_section_id={course_section_id}
        updateFinishButton={updateFinishButton}
        showActivityInformation={showActivityInformation}
      />
      {/* )} */}

      <VideoSetting open={openSetting} setOpen={setOpenSetting} />
      {/* </Spin> */}
    </React.Fragment>
  )
}
export default CreateTabs
