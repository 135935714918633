import { FC } from 'react'
import { formatDateTime } from 'src/utils'

type Props = {
  created_at?: Date
  updated_at?: Date
  titleStart?: string
  titleFinish?: string
  type?: string
}

const DatetimeColumn: FC<Props> = ({
  created_at,
  updated_at,
  titleStart = 'Created',
  titleFinish = 'Updated',
  type,
}) => (
  <>
    <div className='text-nowrap'>
      {created_at ? `${titleStart}: ${formatDateTime(new Date(created_at))}` : '-'}{' '}
    </div>
    <div className='text-nowrap'>
      {updated_at ? `${titleFinish}: ${formatDateTime(new Date(updated_at))}` : '-'}{' '}
    </div>
  </>
)

export default DatetimeColumn
