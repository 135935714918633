import { debounce } from 'lodash'
import { useRef, useState } from 'react'
import { Control } from 'react-hook-form'
import { EventAPI } from 'src/apis/events'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { FILTER_SELECTALL_SORTBY } from 'src/constants'
import { useInfinitePrograms } from 'src/hooks/useInfiniteQueryHooks'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import HookFormTextField from '../base/textfield/HookFormTextField'
import ListFilterLayout from '../layout/listFilter'

interface IProps {
  control: Control
  onSubmit: () => void
  watch: any
  setValue: any
  handleResetFilter: () => void
  loadingTable: boolean
  queryParams: any
}

const HeaderEventParticipant = ({
  control,
  onSubmit,
  setValue,
  watch,
  handleResetFilter,
  loadingTable,
  queryParams,
}: IProps) => {
  const [subjects, setSubjects] = useState<any>()
  const {
    programs,
    refetch: refetchPrograms,
    hasNextPage: hasNextPageProgram,
    fetchNextPage: fetchNextPageProgram,
    debounceSearch,
  } = useInfinitePrograms()
  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await EventAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }
  const getSubjects = async ({ params }: any) => {
    const resMentor = await fetchSubjects(1, 20, params)
    setSubjects(resMentor?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    getSubjects({
      params: {
        name: e,
        ...(watch('course_category_id') && { course_category_id: watch('course_category_id') }),
      },
    })
  }, 500)

  const requestOngoingRef = useRef(false)
  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.subjects.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('event_id', '')
    getSubjects({
      params: {
        ...(watch('course_category_id') && { course_category_id: watch('course_category_id') }),
      },
    })
  }

  return (
    <>
      <div className='px-9 border-0 pt-6'>
        {/* begin:: Search */}
        <ListFilterLayout>
          <HookFormTextField
            control={control}
            name='text'
            placeholder='Search'
            defaultValue={queryParams?.name}
            onSubmit={onSubmit}
            isListScreen
          />

          <HookFormSelectAntd
            control={control}
            name='course_category_id'
            placeholder='Program'
            onSearch={(text) => {
              debounceSearch(text)
            }}
            defaultValue={queryParams.course_category_id ?? ''}
            showSearch
            onChange={handleCourseCategoryChange}
            classNameHeight='sapp-h-40'
            handleNextPage={hasNextPageProgram && fetchNextPageProgram}
            onDropdownVisibleChange={(open) => {
              if (open && !programs) {
                refetchPrograms()
                return
              }
            }}
            options={programs?.map((program) => ({ label: program.name, value: program.id })) ?? []}
          />

          <HookFormSelectAntd
            control={control}
            name='event_id'
            placeholder='Event'
            onSearch={(e: any) => {
              if (e === undefined) {
                return
              }
              debounceSearchSubject(e)
            }}
            handleNextPage={(e: any) =>
              handleNextPageSubject({
                name: e,
                course_category_id: watch('course_category_id'),
              })
            }
            showSearch
            classNameHeight='sapp-h-40'
            options={
              subjects?.events?.map((subject: any) => ({
                label: subject.name,
                value: subject.id,
              })) ?? []
            }
          />

          <HookFormSelectAntd
            name='sortType'
            placeholder='Sort by'
            control={control}
            size='large'
            // defaultValue={queryParams?.sortType ?? ''}
            classNameHeight='sapp-h-40'
            options={FILTER_SELECTALL_SORTBY}
          />
        </ListFilterLayout>
      </div>
      <div className='card-header border-0 pt-6 px-9'>
        <div className='d-flex'>
          <SAPPFIlterButton
            titleReset='Reset'
            titleSubmit='Search'
            okClick={onSubmit}
            resetClick={handleResetFilter}
            disabled={loadingTable}
            loading={loadingTable}
          />
        </div>
      </div>
    </>
  )
}

export default HeaderEventParticipant
