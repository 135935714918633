import { isNull, isUndefined } from 'lodash'
import SappBaseTable from 'src/common/SappBaseTable'
import './TableTestQuizDetail.scss'
import { IClassUserQuizzes, IQuizDetail } from 'src/type/quiz'
import SappActionCellV2 from 'src/components/base/action/SappActionCellV2'
import AccessTimeModal from './AccessTimeModal'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { ClassesApi } from 'src/apis/classes'
import { QUIZ_ATTEMPT_STATUS } from 'src/constants'
import { TITLE_ATTEMPT_GRADING_MANAGEMENT_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { hasPermission } from 'src/utils/permission'
import './TableTestQuizDetail.scss'
import { formatDateTime } from 'src/utils'

const headersGradingMethodAuto = [
  {
    label: '#',
    className: 'min-w-50px',
  },
  {
    label: 'Student ID',
    className: 'min-w-150px',
  },
  {
    label: 'Name',
    className: 'min-w-150px',
  },
  {
    label: 'Email',
    className: 'min-w-200px',
  },
  {
    label: 'Access Preiod',
    className: 'min-w-250px',
  },
  {
    label: 'Thời gian nộp',
    className: 'min-w-250px',
  },
  {
    label: 'Trạng thái',
    className: 'min-w-150px',
  },
  // {
  //   label: 'Số lần chấm',
  //   className: 'min-w-150px',
  // },
  {
    label: 'Điểm lần chấm cuối',
    className: 'min-w-150px',
  },
  // {
  //   label: 'Người chấm',
  //   className: 'min-w-150px',
  // },
  {
    label: '',
  },
]

const headersGradingMethodManual = [
  {
    label: '#',
    className: 'min-w-50px',
  },
  {
    label: 'Student ID',
    className: 'min-w-150px',
  },

  {
    label: 'Name',
    className: 'min-w-150px',
  },
  {
    label: 'Email',
    className: 'min-w-200px',
  },
  {
    label: 'Access Period',
    className: 'min-w-250px',
  },
  {
    label: 'Thời gian nộp',
    className: 'min-w-175px',
  },
  {
    label: 'Trạng thái',
    className: 'min-w-150px',
  },
  {
    label: 'Số lần chấm',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Điểm lần chấm cuối',
    className: 'min-w-150px',
  },
  {
    label: 'Người chấm',
    className: 'min-w-150px',
  },
  {
    label: '',
  },
]

interface IProps {
  quizDetail: IQuizDetail | undefined
  loading: boolean
  fetchTestQuiz: (page_index: number, page_size: number, params?: Object) => void
  filterTestQuiz: Object
  quiz_id: { id: string; name: string; grading_method: string }
  startDate?: string
  endDate?: string
  isDuration?: boolean
  classId: string
}

export interface IAccessTime {
  isOpen: boolean
  id: string
  defaultValue?: {
    start_time?: Date | string
    end_time?: Date | string
  }
  showTestQuiz?: boolean
  quiz_id?: string
  class_user_id?: string
  staff_id?: string | undefined
}

const TableTestQuizDetail = ({
  quizDetail,
  loading,
  fetchTestQuiz,
  filterTestQuiz,
  quiz_id,
  startDate,
  endDate,
  isDuration,
  classId,
}: IProps) => {
  const [dataQuiz, setDataQuiz] = useState<IClassUserQuizzes>()

  const defaultAccessTime = {
    isOpen: false,
    id: '',
    defaultValue: {
      start_time: new Date(),
      end_time: new Date(),
    },
    showTestQuiz: false,
    quiz_id: '',
    class_user_id: '',
    staff_id: undefined,
  }
  const [openAccessTime, setOpenAccessTime] = useState<IAccessTime>(defaultAccessTime)
  const [loadingAccessTime, setLoadingLoadingAccessTime] = useState(false)

  const handleCloseAccessTime = () => setOpenAccessTime(defaultAccessTime)

  const handleUpdateAccessTime = async (data: {
    start_time: Date
    end_time: Date
    quiz_id: string | undefined
    class_user_id?: string | undefined
    staff_id?: string | undefined
  }) => {
    setLoadingLoadingAccessTime(true)
    try {
      await ClassesApi.updateAccessTimeUser({ data })
      await fetchTestQuiz(1, 10)
      toast.success('update Access Time successfully!')
    } catch (err) {
    } finally {
      handleCloseAccessTime()
      setLoadingLoadingAccessTime(false)
    }
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchTestQuiz(page_index || 1, page_size || 10, filterTestQuiz)
  }

  const quizManual = quiz_id.grading_method === 'MANUAL'

  const { profileMe } = useUserContext()

  const allowRenderAwaitingGrade = hasPermission(
    profileMe?.roles,
    TITLE_ATTEMPT_GRADING_MANAGEMENT_GR.ATTEMPT_GRADING
  )

  return (
    <>
      <SappBaseTable
        headers={quizManual ? headersGradingMethodManual : headersGradingMethodAuto}
        data={quizDetail?.class_user_quizzes}
        hasCheck={false}
        loading={loading}
        // currentPage={quizDetail?.metadata?.page_index || 1}
        // pageSize={quizDetail?.metadata?.page_size || 10}
        totalItems={quizDetail?.metadata?.total_records || 10}
        handlePaginationChange={handlePaginationChange}
        classNameTable='max-w-none'
        dataResponse={quizDetail}
      >
        {quizDetail?.class_user_quizzes?.map((quiz, index: number) => {
          const userContact = quiz?.user?.user_contacts?.find((user) => user.is_default)
          const isAwaitingOrFinishedGrading = [
            QUIZ_ATTEMPT_STATUS.AWAITING_GRADING,
            QUIZ_ATTEMPT_STATUS.FINISHED_GRADING,
          ].includes(quiz?.attempt?.grading_status as string)

          const showAction =
            allowRenderAwaitingGrade && isAwaitingOrFinishedGrading && !isUndefined(quiz?.staff?.id)

          const isShowScore = !isNull(quiz?.attempt?.score) && !isUndefined(quiz?.attempt?.score)

          return (
            <tr className='border-0' key={index}>
              <td>{index + 1}</td>
              <td>{quiz?.user?.hubspot_contact_id || '-'}</td>
              <td>{quiz?.user?.detail?.full_name}</td>
              <td>{userContact?.email}</td>
              {quizManual ? (
                <>
                  <td>
                    {quiz?.start_time ? formatDateTime(new Date(quiz?.start_time)) : ''} -{' '}
                    {quiz?.end_time ? formatDateTime(new Date(quiz?.end_time)) : ''}
                  </td>
                  <td>
                    {quiz?.attempt?.finished_at
                      ? formatDateTime(new Date(quiz?.attempt?.finished_at))
                      : '-'}
                  </td>
                  <td>
                    {quiz?.attempt?.grading_status === QUIZ_ATTEMPT_STATUS.AWAITING_GRADING
                      ? 'Awating Grading'
                      : quiz?.attempt?.grading_status === QUIZ_ATTEMPT_STATUS.FINISHED_GRADING
                      ? 'Finish Grading'
                      : 'Unsubmitted'}
                  </td>
                  <td className='text-center'>{quiz?.attempt?.attempt_gradings?.length || 0}</td>
                  <td>
                    {isShowScore && (
                      <div
                        className='cursor-pointer text-hover-primary'
                        onClick={() => {
                          quiz?.attempt?.grading_status === QUIZ_ATTEMPT_STATUS.FINISHED_GRADING &&
                            window.location.replace(
                              `/result-dashboard/${quiz?.attempt.id}/class/${classId}`
                            )
                        }}
                      >
                        {quiz?.attempt?.score?.toString() ?? ''}
                      </div>
                    )}
                  </td>
                  <td>{quiz?.staff?.detail?.full_name}</td>
                  <td>
                    {(isDuration || showAction) && (
                      <SappActionCellV2
                        items={[
                          ...(isDuration
                            ? [
                                {
                                  label: 'Edit Access Time',
                                  onClick: () => {
                                    setDataQuiz(quiz)
                                    setOpenAccessTime({
                                      id: quiz.id,
                                      isOpen: true,
                                      defaultValue: {
                                        start_time: quiz.start_time
                                          ? new Date(quiz.start_time)
                                          : new Date(),
                                        end_time: quiz.end_time
                                          ? new Date(quiz.end_time)
                                          : new Date(),
                                      },
                                      quiz_id: quizDetail?.class_quiz?.quiz?.id,
                                      class_user_id: quiz?.class_user?.id,
                                      staff_id: quizManual ? quiz?.staff?.id : undefined,
                                    })
                                  },
                                },
                              ]
                            : []),
                          ...(showAction
                            ? [
                                {
                                  label: 'Grade work',
                                  onClick: () =>
                                    window.open(
                                      `/grade/${quiz?.attempt?.attempt_gradings?.[0]?.id}`
                                    ),
                                },
                              ]
                            : []),
                          ...(quiz?.attempt?.grading_status === QUIZ_ATTEMPT_STATUS.FINISHED_GRADING
                            ? [
                                {
                                  label: 'Result',
                                  onClick: () => {
                                    window.location.replace(
                                      `/result-dashboard/${quiz?.attempt.id}/class/${classId}`
                                    )
                                  },
                                },
                              ]
                            : []),
                        ]}
                      />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td>
                    {quiz?.start_time ? formatDateTime(new Date(quiz?.start_time)) : ''} -{' '}
                    {quiz?.end_time ? formatDateTime(new Date(quiz?.end_time)) : ''}
                  </td>
                  <td>
                    {quiz?.attempt?.finished_at
                      ? formatDateTime(new Date(quiz?.attempt?.finished_at))
                      : '--'}
                  </td>
                  <td>
                    {quiz?.attempt?.status === QUIZ_ATTEMPT_STATUS.SUBMITTED
                      ? 'Submitted'
                      : 'Unsubmitted'}
                  </td>
                  <td>
                    {['MULTIPLE_CHOICE', 'MIX'].includes(
                      quizDetail.class_quiz?.quiz?.quiz_question_type
                    )
                      ? isShowScore && (
                          <div
                            className='cursor-pointer text-hover-primary'
                            onClick={() => {
                              quiz?.attempt?.status === QUIZ_ATTEMPT_STATUS.SUBMITTED &&
                                window.location.replace(
                                  `/result-dashboard/${quiz?.attempt.id}/class/${classId}`
                                )
                            }}
                          >
                            {quiz?.attempt?.score?.toString() ?? ''}
                          </div>
                        )
                      : '--'}
                  </td>
                  <td>
                    {(isDuration || quiz?.attempt?.status === QUIZ_ATTEMPT_STATUS.SUBMITTED) && (
                      <SappActionCellV2
                        items={[
                          ...(isDuration
                            ? [
                                {
                                  label: 'Edit Access Time',
                                  onClick: () => {
                                    setDataQuiz(quiz)
                                    setOpenAccessTime({
                                      id: quiz.id,
                                      isOpen: true,
                                      defaultValue: {
                                        start_time: quiz.start_time
                                          ? new Date(quiz.start_time)
                                          : new Date(),
                                        end_time: quiz.end_time
                                          ? new Date(quiz.end_time)
                                          : new Date(),
                                      },
                                      quiz_id: quizDetail?.class_quiz?.quiz?.id,
                                      class_user_id: quiz?.class_user?.id,
                                    })
                                  },
                                },
                              ]
                            : []),
                          ...(quiz?.attempt?.status === QUIZ_ATTEMPT_STATUS.SUBMITTED
                            ? [
                                {
                                  label: 'Result',
                                  onClick: () => {
                                    window.location.replace(
                                      `/result-dashboard/${quiz?.attempt.id}/class/${classId}`
                                    )
                                  },
                                },
                              ]
                            : []),
                        ]}
                      />
                    )}
                  </td>
                </>
              )}
            </tr>
          )
        })}
      </SappBaseTable>
      <AccessTimeModal
        open={openAccessTime}
        onClose={handleCloseAccessTime}
        onSaveTime={handleUpdateAccessTime}
        startDate={startDate}
        endDate={endDate}
        defaultAccessTime={defaultAccessTime}
        dataQuiz={dataQuiz}
        quiz_id={quizDetail?.class_quiz?.quiz?.id}
        quizManual={quizManual}
        loading={loadingAccessTime}
      />
    </>
  )
}

export default TableTestQuizDetail
