import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { AppConfig, DAYS_IN_WEEK } from 'src/constants'
import 'src/global.d.ts'
import { PROGRAM } from 'src/type/courses'
import { IParticipantInTest } from 'src/type/entrancetest'

export const getActToken = (): string => {
  return localStorage.getItem('actToken') || ''
}

export const getRefreshToken = (): string => {
  return localStorage.getItem('refreshToken') || ''
}

export const setActToken = (accToken: string) => {
  localStorage.setItem('actToken', accToken)
}

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem('refreshToken', refreshToken)
}

export const removeJwtToken = () => {
  localStorage.removeItem('actToken')
  localStorage.removeItem('refreshToken')
  localStorage.clear()
}

export function getFileSizeMB(size: number, fractionDigits?: number | undefined): number {
  if (size <= 0) return 0
  if (fractionDigits === undefined || fractionDigits < 0) fractionDigits = 0
  return Number(parseFloat(`${size / (1024 * 1024)}`).toFixed(fractionDigits))
}
/**
 * @description Hiện thị bytes sang dạng khác gọn hơn
 *
 * @param {number} bytes
 * @return {*}
 */
export const displayBytes = (bytes?: number | null) => {
  if (!bytes) {
    return '-'
  }
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 KB'
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const result = (bytes / Math.pow(1024, i)).toFixed(2)
  return result + ' ' + sizes[i]
}

export function checkTypeFile(type: string) {
  return AppConfig.imageFileTypeWhiteList.includes(type)
}

export const isValidHttpUrl = (string: string | URL) => {
  let url: URL
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const validatePassword = /^(?=.*[A-Z])(?=.*\d).{8,}$/

export const phoneRegExp = /^(0\d{9}|0\d{10})$/

export const getUppercaseByNumber = (num: number): string => {
  let result = ''
  while (num > 0) {
    let remainder = num % 26
    if (remainder === 0) {
      remainder = 26
      num--
    }
    let char = String.fromCharCode(remainder + 64)
    result = char + result
    num = Math.floor(num / 26)
  }
  return result
}

export const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
export const fileExtension = '.xlsx'

export const usernamePattern = /^[A-Za-z0-9](?:[A-Za-z0-9_.-]*[A-Za-z0-9])?$/

export function secondsToMinutesAndSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  // Format the result as "minutes:seconds"
  const formattedTime = `${minutes > 9 ? '' : '0'}${minutes}:${
    remainingSeconds < 10 ? '0' : ''
  }${remainingSeconds}`

  return formattedTime
}

export const formatDate = (date: Date | null | undefined | string, formatType?: string) => {
  return date ? dayjs(new Date(date)).format(formatType ?? 'YYYY-MM-DD') : null
}

export const htmlToRaw = (html: string): string => {
  if (!html) {
    return ''
  }
  let result = ''
  let inTag = false
  for (let i = 0; i < html.length; i++) {
    let char = html[i]
    if (char === '<') {
      inTag = true
    } else if (char === '>') {
      inTag = false
    } else if (!inTag) {
      result += char
    }
  }
  result = result.replace(/&/g, '&')
  result = result.replace(/</g, '<')
  result = result.replace(/>/g, '>')
  result = result.replace(/"/g, '"')
  result = result.replace(/'/g, "'")
  return decodeHtml(result)?.trim()
}

const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export const cleanParamsAPI = (params: Object) => {
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== null && value !== '')
  )
}

export const removeDuplicateById = <T extends { dom_id: any }>(array: T[], id: keyof T): T[] => {
  const seen = new Set()
  return array.filter((item) => {
    if (seen.has(item[id])) {
      return false
    } else {
      seen.add(item[id])
      return true
    }
  })
}

export const sizeInBytes = (megabytes: number) => {
  return megabytes * 1024 * 1024
}

export const getCourseFullLevel = () => {
  return window.localStorage.getItem('fullLevel') === 'true' ? true : false
}

export const setCourseLevel = (level: string) => {
  localStorage.setItem('fullLevel', level)
}

export const formatISOFromDate = (year: number, month: number, day: number) => {
  return new Date(year, month, day, 0, 0, 0)
}

export const formatISOToDate = (year: number, month: number, day: number) => {
  return new Date(year, month, day, 23, 59, 59, 999)
}

export function getDateInfo(inputDate: Date) {
  const date = new Date(inputDate)

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return {
    day,
    month,
    year,
  }
}

export const bytesToGB = (bytes: number) => {
  const gigabytes = bytes / (1024 * 1024 * 1024)
  return gigabytes.toFixed(2) // Round to 2 decimal places
}

export const convertItemSelect = (items: Array<any> | undefined) => {
  return items && items?.map((item: any) => ({ label: item?.name, value: item?.id }))
}

export const convertDateStringDayMonthYear = (
  dateString: string,
  type: 'string' | 'iso' = 'string'
) => {
  // Tạo đối tượng Date từ chuỗi ngày tháng
  const dateObject = new Date(dateString)

  // Lấy ngày, tháng, năm từ đối tượng Date
  const year = dateObject.getFullYear()
  const month = `0${dateObject.getMonth() + 1}`.slice(-2) // Tháng bắt đầu từ 0
  const day = `0${dateObject.getDate()}`.slice(-2)

  // Tạo chuỗi định dạng mới
  const date = `${year}-${month}-${day}`
  const isoDateString = dayjs(dateString).utc().format()
  return type === 'iso' ? isoDateString : date
}

export const convertSnakeCaseToTitleCase = (snakeCaseString: string) => {
  return snakeCaseString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const convertMathToImage = async (element: any) => {
  const viewer = com?.wiris?.js?.JsPluginViewer
  if (viewer && element) {
    try {
      await viewer.parseElement(element, true, function () {})
    } catch (error) {
      console.error('Error:', error)
    }
  }
}

export function cleanObject(obj: any) {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === 'undefined' ||
      obj[propName] === 'null'
    ) {
      delete obj[propName]
    } else if (typeof obj[propName] === 'object') {
      cleanObject(obj[propName])
      if (Object.keys(obj[propName]).length === 0) {
        delete obj[propName]
      }
    }
  }
  return obj
}

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const useGetDataQuery = (
  queryKey: string,
  params: Object,
  fetchFunction: () => Promise<any>,
  enabled?: boolean,
  onError?: ((err: unknown) => void) | undefined
) => {
  const fetchData = async () => {
    const { data } = await fetchFunction()
    return data
  }

  return useQuery([queryKey, params], fetchData, {
    enabled: enabled,
    onError: onError,
    retry: false,
  })
}

export const beforeUpload = (file: File) => {
  const isExcel =
    file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'application/vnd.ms-excel'
  const isCSV = file.type === 'text/csv'
  if (!isExcel && !isCSV) {
    toast.error('You can only upload Excel (.xlsx) or CSV (.csv) files!')
    return false
  }
  if (file.size > 5 * 1024 * 1024) {
    toast.error('The file is too large! It must be less than or equal to 5 MB!')
    return false
  }
  return true
}

export const handleParticipantLevel = (data: IParticipantInTest, name?: string) => {
  switch (name) {
    case 'ACCA':
      return data?.detail?.acca_level ?? '--'
    case 'CFA':
      return data?.detail?.level ?? '--'
    case 'CMA':
      return data?.detail?.cma_level ?? '--'
    default:
      return '--'
  }
}

export const formatTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  const formattedHours = hours > 0 ? `${hours}h` : ''
  const formattedMinutes = remainingMinutes > 0 ? `${remainingMinutes}m` : ''

  return formattedHours + formattedMinutes || '--'
}

export const convertString = (input: string | undefined) => {
  // Tách các từ bằng dấu gạch dưới, sau đó viết hoa chữ cái đầu mỗi từ
  return (
    input
      ?.toLowerCase() // Chuyển tất cả về chữ thường
      .split('_') // Tách chuỗi dựa vào dấu gạch dưới
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Viết hoa chữ cái đầu mỗi từ
      .join(' ') || '--'
  ) // Ghép lại các từ bằng dấu cách
}

export const convertTime = (start: any, end: any) => {
  const startTime = new Date(start) as any
  const endTime = new Date(end) as any

  // Tính số giây chênh lệch
  const diffInSeconds = Math.abs(differenceInSeconds(endTime, startTime))

  // Nếu thời gian chênh lệch nhỏ hơn 1 phút, hiển thị giây
  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`
  }

  // Nếu thời gian lớn hơn 1 phút, tính số phút
  const diffInMinutes = Math.abs(differenceInMinutes(endTime, startTime))
  const remainingSeconds = diffInSeconds % 60

  if (diffInMinutes === 0) {
    return `${remainingSeconds}s` // Nếu chỉ có vài giây, không hiển thị phút
  }

  return diffInMinutes > 0 && remainingSeconds === 0
    ? `${diffInMinutes}m`
    : `${diffInMinutes}m ${remainingSeconds}s` // Hiển thị cả phút và giây nếu cần
}

export const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
}

export const convertQuizType = (quizType: string) => {
  // Convert the enum value to a readable string
  return quizType
    .split('_') // Split the string by underscores
    .map(
      (
        word,
        index // Capitalize the first letter of each word
      ) =>
        index === 0
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ')
}

export const getProgramLevel = (program: PROGRAM | undefined) => {
  switch (program) {
    case PROGRAM.ACCA:
      return 'acca_level'
    case PROGRAM.CFA:
      return 'level'
    case PROGRAM.CMA:
      return 'cma_level'
    default:
      return 'level'
  }
}

/**
 * Chuyển đổi chuỗi văn bản thành slug để sử dụng trong URL
 * @param {string} text - Chuỗi văn bản đầu vào cần chuyển đổi
 * @returns {string} - Chuỗi văn bản đã được chuyển đổi thành slug
 */
export const generateSlug = (text: string) => {
  return text
    ?.toLowerCase() // Chuyển toàn bộ chuỗi sang chữ thường
    ?.normalize('NFD') // Chuẩn hóa chuỗi, tách các ký tự tổ hợp (dấu và chữ cái)
    ?.replace(/[\u0300-\u036F]/g, '') // Loại bỏ các dấu kết hợp (dấu sắc, huyền, hỏi, ngã...)
    ?.replace(/đ/g, 'd') // Thay thế ký tự đặc biệt 'đ'
    ?.replace(/Đ/g, 'd') // Thay thế ký tự đặc biệt 'Đ'
    ?.replace(/[^a-z0-9]+/g, '-') // Loại bỏ các ký tự không phải chữ cái, số và thay bằng dấu gạch ngang
    ?.replace(/^-+|-+$/g, '') // Loại bỏ các dấu gạch ngang ở đầu và cuối chuỗi
    ?.replace(/-+/g, '-') // Thay thế nhiều dấu gạch ngang liên tiếp bằng một dấu gạch ngang duy nhất
}

export const onLinkSocial = (link: string) => {
  window.open(link, '_blank')
}

export const mappingAddress = (data: Array<string | null | undefined>) => {
  if (!data.length) return ''
  const clearArr = data.filter((el) => el)
  return clearArr?.join(', ')
}

export function clearField(obj: any, excludeField?: string[]): any {
  if (Array.isArray(obj)) {
    return obj
      .map((item) => clearField(item, excludeField))
      .filter(
        (item) =>
          item !== null &&
          item !== undefined &&
          item !== '' &&
          !(typeof item === 'object' && Object.keys(item).length === 0)
      )
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (excludeField?.includes(key)) {
        acc[key] = value
      } else {
        const cleanedValue = clearField(value, excludeField)
        if (
          cleanedValue !== null &&
          cleanedValue !== undefined &&
          cleanedValue !== '' &&
          !(Array.isArray(cleanedValue) && cleanedValue.length === 0) &&
          !(typeof cleanedValue === 'object' && Object.keys(cleanedValue).length === 0)
        ) {
          acc[key] = cleanedValue
        }
      }
      return acc
    }, {} as Record<string, any>)
  }
  return obj
}

type ObjectWithId = {
  id: number | string
  [key: string]: any
}

export function filterUniqueObjectsById<T extends ObjectWithId>(objects: T[]): T[] {
  const seenIds = new Set<number | string>()
  return objects.filter((obj) => {
    if (!obj.id || seenIds.has(obj.id)) {
      return false
    }
    seenIds.add(obj.id)
    return true
  })
}

export const getDayIndex = (startDate: Date) =>
  (Number(dayjs(startDate).format('d')) + DAYS_IN_WEEK - 1) % DAYS_IN_WEEK

/**
 * @description function sử dụng gọi API cho react-query
 */
export const useFetchQuery = (key: any[], fetchFn: () => any, options = {}) => {
  return useQuery(key, fetchFn, {
    staleTime: 5 * 60 * 1000, // Mặc định cache 5 phút
    cacheTime: 10 * 60 * 1000, // Cache giữ trong 10 phút
    refetchOnWindowFocus: false, // Không fetch lại khi focus vào tab
    ...options, // Cho phép ghi đè options nếu cần
  })
}

/**
 * @description: Chuyển đổi UTC time sang local time
 *
 */
export const convertUTCToLocal = (utcTime: string) => {
  const date = new Date(utcTime)
  const timezoneOffset = date.getTimezoneOffset()
  return new Date(date.getTime() - timezoneOffset * 60 * 1000)
}

export const convertLocalToUTC = (localDate: Date) => {
  const utcYear = localDate.getUTCFullYear()
  const utcMonth = (localDate.getUTCMonth() + 1).toString().padStart(2, '0')
  const utcDate = localDate.getUTCDate().toString().padStart(2, '0')
  const utcHour = localDate.getUTCHours().toString().padStart(2, '0')
  const utcMinute = localDate.getUTCMinutes().toString().padStart(2, '0')
  const utcSecond = localDate.getUTCSeconds().toString().padStart(2, '0')
  return `${utcYear}-${utcMonth}-${utcDate}T${utcHour}:${utcMinute}:${utcSecond}Z`
}

export const convertTimeUTCToLocalTime = (timeUtc: string) => {
  const now = new Date()
  const [utcHour, utcMinute, utcSecond] = timeUtc.split(':').map(Number)
  const utcDate = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), utcHour, utcMinute, utcSecond)
  )
  const localHour = utcDate.getHours()
  const localMinute = utcDate.getMinutes()
  const localSecond = utcDate.getSeconds()
  return `${localHour.toString().padStart(2, '0')}:${localMinute
    .toString()
    .padStart(2, '0')}:${localSecond.toString().padStart(2, '0')}`
}

export const formatDateTime = (value: Date, format: string = 'DD/MM/YYYY HH:mm') => {
  return dayjs(value).format(format)
}

export const formatDistanceToNow = (loginTime: string | Date) => {
  const now = Date.now()
  const distance = now - new Date(loginTime).getTime()
  const seconds = Math.abs(distance) / 1000
  const minutes = seconds / 60
  const hours = minutes / 60
  const days = hours / 24
  const months = days / 30 // Ước tính tháng = 30 ngày
  const years = days / 365 // Ước tính năm = 365 ngày

  let result
  let suffix = ''

  if (seconds < 60) {
    result = Math.round(seconds) + ' second'
    if (seconds !== 1) result += 's'
  } else if (minutes < 60) {
    result = Math.round(minutes) + ' minute'
    if (minutes !== 1) result += 's'
  } else if (hours < 24) {
    result = Math.round(hours) + ' hour'
    if (hours !== 1) result += 's'
  } else if (days < 30) {
    result = Math.round(days) + ' day'
    if (days !== 1) result += 's'
  } else if (months < 12) {
    result = Math.round(months) + ' month'
    if (months !== 1) result += 's'
  } else {
    result = Math.round(years) + ' year'
    if (years !== 1) result += 's'
  }

  // Thêm hậu tố 'ago' nếu thời gian đã qua, hoặc 'in' nếu trong tương lai
  if (distance < 0) {
    suffix = 'in the future'
  } else {
    suffix = 'ago'
  }

  return `${result} ${suffix}`
}

export const differenceInSeconds = (dateLeft: Date | string, dateRight: Date | string) => {
  const timeLeft = new Date(dateLeft).getTime()
  const timeRight = new Date(dateRight).getTime()

  const difference = Math.abs(timeLeft - timeRight) / 1000

  return Math.floor(difference)
}

export const differenceInMinutes = (dateLeft: Date | string, dateRight: Date | string) => {
  // Chuyển đổi ngày sang timestamp (số milliseconds từ Unix Epoch)
  const timeLeft = new Date(dateLeft).getTime()
  const timeRight = new Date(dateRight).getTime()

  // Tính sự chênh lệch giữa hai ngày và chuyển sang phút
  const difference = Math.abs(timeLeft - timeRight) / (1000 * 60)

  // Trả về số phút
  return Math.floor(difference)
}
