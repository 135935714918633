import { zodResolver } from '@hookform/resolvers/zod'
import { Spin } from 'antd'
import CreatedContent from 'src/components/courses/course-detail/create-tabs/created-content/CreatedContent'
import AddTabQuiz from 'src/components/courses/course-detail/create-tabs/tabQuiz/addTabQuiz'
import AddTabVideo from 'src/components/courses/course-detail/create-tabs/tabVideo/addTabVideo'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { CoursesAPI } from 'src/apis/courses'
import { PlusVideoIcon } from 'src/common/icons'
import SappLabel from 'src/components/base/label/SappLabel'
import SAPPSelect from 'src/components/base/select/SAPPSelect'
import { TYPE_DOCUMENT, VALIDATE_FILED_MAX, VALIDATION_FIELD } from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { CourseCondition, ITabActivity } from 'src/type/courses'
import TabText from 'src/components/courses/course-detail/create-tabs/tabText/tabText'
import { z } from 'zod'
import { QuizState } from 'src/type/quiz'
import { ITabContent } from 'src/type/course-medium/section'
import { ParentsNode } from 'src/type'
import { useLocation } from 'react-router-dom'

interface IProps {
  data: ITabContent[] //all tab
  setTabActive: React.Dispatch<React.SetStateAction<string>>
  tabActive: string
  setData: React.Dispatch<React.SetStateAction<ITabContent[]>>
  closeModal: () => void
  course_section_id: string
  tabData: ITabContent //tab
  tabIndex: number
  // onlyEdit?: boolean
  courseCondition: CourseCondition
  updateFinishButton?: (newValue: boolean) => void
  showActivityInformation?: boolean
}

interface IQuizData {
  quiz_id?: string | number
}

interface ITextData {
  editor?: string
}

interface IVideoData {
  quiz_id?: string | number
}

type DataValue = IQuizData | ITextData | IVideoData
interface IQuizPayload {
  type: 'QUIZ'
  payload: DataValue
}

interface ITabDocumentData {
  type: string
  id: string
  text_editor_content?: string
  video_data?: IVideoData
  quiz?: {
    state?: QuizState
  }
}

interface ITextPayload {
  type: 'TEXT'
  payload: {
    content?: string
    attachments?: []
  }
  text_editor_files?: []
  course_id?: string
}

interface IVideoPayload {
  type: 'VIDEO'
  payload: {
    video_quiz_ids: string[]
  }
}

type Body = IQuizPayload | ITextPayload | IVideoPayload
const TabContentMedium = ({
  data,
  setTabActive,
  tabActive,
  setData,
  course_section_id,
  tabData,
  tabIndex,
  // onlyEdit,
  closeModal,
  courseCondition,
  updateFinishButton,
  showActivityInformation,
}: IProps) => {
  const [createDoc, setCreateDoc] = useState<{
    type?: string
    tabIndex?: number
  }>()
  const [editDoc, setEditDoc] = useState<{
    type?: string
    tabIndex?: number
    tabDocumentIndex?: number
    DocId?: string
    dataVideo?: ParentsNode
  }>()

  const [loadingAddTab, setLoadingAddTab] = useState(false)
  const { idCourse } = useCourseContext()
  // const [loadingDeleteTab, setLoadingDeleteTab] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const elementRef = useRef<HTMLDivElement | null>(null)
  const [isDraggable, setDraggable] = useState<boolean>(false)
  const [docItems, setDocItems] = useState<ITabActivity[]>()
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false)
  const templateUrl = useLocation()
  const template = templateUrl.pathname.includes('/course-medium/') ? 3 : 4
  const [editingIndex, setEditingIndex] = useState<number | null>(null)

  const validationSchema = z.object({
    [tabData.tabId]: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD)
      .max(1000, VALIDATE_FILED_MAX),
  })
  const useFormProp = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const { confirm, contextHolder } = useConfirm()
  const { control, setValue, handleSubmit, unregister } = useFormProp
  async function getDetailTab() {
    try {
      setLoading(true)
      let id = tabData.tabId.includes('tab') ? course_section_id : tabData.tabId
      const res = await CoursesAPI.getTabDetail(id)

      setValue(id, res.data.name)
      setData((prev: ITabContent[]) => {
        const data = [...(prev as ITabContent[])]
        data[tabIndex].value.tab_name = res.data.name
        for (let j in res.data.course_tab_documents) {
          const docList = data[tabIndex].value.document ?? []
          const index = Number(j)
          docList[index] = { ...res.data.course_tab_documents[j] }
          data[tabIndex].value.document = docList
        }

        // handleClose()
        setLoading(false)
        return data
      })
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }
  useEffect(() => {
    if (!tabData.creating && tabActive === tabData.tabId) {
      getDetailTab()
    }
  }, [tabActive])
  // scroll den vi tri ad new doc
  useEffect(() => {
    if (createDoc?.tabIndex !== undefined && elementRef?.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    }
  }, [createDoc, elementRef?.current])
  const handleClose = (cb?: () => void) => {
    let body = ['Bạn có chắc chắn muốn huỷ không?']
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        setCreateDoc({})
        // setValue('editor', undefined)
        // unregister('editor')
        typeof cb === 'function' && cb()
        setEditDoc({})
        setIsOptionSelected(false) // Reset trạng thái khi đóng modal
        if (updateFinishButton) {
          updateFinishButton(true)
        }
        setEditingIndex(null) // Hiển thị lại các button Edit & Delete
      },
    })
  }
  const handleOpenAdd = (e: number, tabIndex: number) => {
    if (e === 1) {
      // setOpenAddTabText({status: true, tabIndex: tabIndex})
      setCreateDoc({ type: TYPE_DOCUMENT.TEXT, tabIndex })
    } else if (e === 2) {
      setCreateDoc({ type: TYPE_DOCUMENT.QUIZ, tabIndex })
    } else if (e === 3) {
      setCreateDoc({ type: TYPE_DOCUMENT.VIDEO, tabIndex })
    }
  }
  const handleOpenEdit = async (e: string, tabIndex: number, tabDocumentIndex: number) => {
    if (e === TYPE_DOCUMENT.TEXT) {
      // setValue('editor', data[tabIndex].value.document[indexItem].text_editor_content)
      setEditDoc({
        type: e,
        tabIndex,
        tabDocumentIndex,
        DocId: data[tabIndex]?.value?.document?.[tabDocumentIndex]?.id as string,
      })
      return
    }
    if (e === TYPE_DOCUMENT.QUIZ) {
      // setValue('editor', data[tabIndex].value.document[indexItem].payload.content)
      setEditDoc({
        type: e,
        tabIndex,
        tabDocumentIndex,
        DocId: data[tabIndex]?.value?.document?.[tabDocumentIndex]?.id as string,
      })
      return
    }
    if (e === TYPE_DOCUMENT.VIDEO) {
      const res = await CoursesAPI.getDocumentDetail(
        data[tabIndex]?.value?.document?.[tabDocumentIndex]?.id as string
      )
      setEditDoc({
        type: e,
        tabIndex,
        tabDocumentIndex,
        DocId: data[tabIndex]?.value?.document?.[tabDocumentIndex]?.id as string,
        dataVideo: res.data,
      })
      return
    }
  }
  const handleDeleteDoc = async (index: number, tabIndex: number) => {
    let body = ['Bạn có chắc chắn muốn xoá Doc này không?']
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        try {
          const docId = data[tabIndex]?.value?.document?.[index]?.id as string
          if (!docId) return
          await CoursesAPI.deleteDocumentTab(docId)
          const data2 = [...data]
          data2[tabIndex]?.value?.document?.splice(index, 1)
          data2[tabIndex] = {
            ...data2[tabIndex],
            value: { ...data2[tabIndex].value, document: data2[tabIndex].value.document },
          }
          setData(data2)
        } catch (err) {
          console.error(err)
        }
      },
    })
  }

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  const onSubmit = async (dataValue: DataValue, type?: string) => {
    try {
      setLoadingSubmit(true)
      const newDoc = [...data]
      let body: Body
      if (type === TYPE_DOCUMENT.QUIZ) {
        body = {
          type: TYPE_DOCUMENT.QUIZ,
          payload: dataValue,
        } as IQuizPayload
        // newDoc[createDoc?.tabIndex || 0].value.document.push(body)
      } else if (type === TYPE_DOCUMENT.TEXT) {
        body = {
          type: createDoc?.type,
          payload: { content: (dataValue as ITextData).editor, attachments: [] },
          text_editor_files: textEditorFiles,
        } as ITextPayload
      } else if (type === TYPE_DOCUMENT.VIDEO) {
        const listQuiz_ids = []
        for (let e of dataValue as IVideoData[]) {
          if (e.quiz_id && e.quiz_id !== null) {
            listQuiz_ids.push(e.quiz_id)
          }
        }
        body = {
          type: createDoc?.type,
          payload: {
            video_quiz_ids: listQuiz_ids,
          },
        } as IVideoPayload
      } else {
        // Thêm một nhánh else để xử lý trường hợp `type` không hợp lệ, nếu cần
        throw new Error('Invalid type for document')
      }

      const res = await CoursesAPI.addDocumentTab({
        ...body,
        course_id: idCourse,
        id: course_section_id,
        isDocumentActivity: true,
      })

      if (res?.success) {
        setFileEditor([])
      }
      // if (!newDoc[createDoc?.tabIndex || 0].value.document) {
      //   newDoc[createDoc?.tabIndex || 0].value.document = []
      // }
      // // body.DocId = res.data.id
      // newDoc[createDoc?.tabIndex || 0].value.document.push(res.data)
      // setData((prev: any) => {
      //   setCreateDoc({})
      //   // setValue('editor', undefined)
      //   // unregister('editor')
      //   // typeof cb === 'function' && cb()
      //   setEditDoc({})
      //   return newDoc
      // })
      await getDetailTab()
      setCreateDoc({})
      setEditDoc({})
      toast.success('Create Doc Success!')
      setIsOptionSelected(false) // Reset trạng thái khi đóng modal
      if (updateFinishButton) {
        updateFinishButton(true)
      }
      setEditingIndex(null) // Hiển thị lại các button Edit & Delete
    } catch (err: any) {
      if (err?.response?.status === 413) {
        toast.error('Request entity too large!')
      }
    } finally {
      setLoadingSubmit(false)
    }
  }
  const onEdit = async (dataValue: DataValue, type?: string) => {
    try {
      setLoadingSubmit(true)
      let body: Body
      if (type === TYPE_DOCUMENT.QUIZ) {
        body = {
          type: type,
          payload: dataValue,
          course_id: idCourse,
        } as IQuizPayload
        // newDoc[createDoc?.tabIndex || 0].value.document.push(body)
      } else if (type === TYPE_DOCUMENT.TEXT) {
        body = {
          type: type,
          payload: {
            content: (dataValue as ITextData)?.editor,
            attachments: [],
          },
          text_editor_files: textEditorFiles,
          course_id: idCourse,
        } as ITextPayload
      } else if (type === TYPE_DOCUMENT.VIDEO) {
        const listQuiz_ids = []
        for (let e of dataValue as IVideoData[]) {
          if (e.quiz_id && e.quiz_id !== null) {
            listQuiz_ids.push(e.quiz_id)
          }
        }
        body = {
          type: type,
          payload: {
            video_quiz_ids: listQuiz_ids,
          },
          course_id: idCourse,
        } as IVideoPayload
      } else {
        throw new Error('Invalid type for document')
      }
      const res = await CoursesAPI.editDocumentTab({
        ...body,
        id: editDoc?.DocId,
      })

      if (res?.success) {
        setFileEditor([])
      }

      await getDetailTab()
      setCreateDoc({})
      setEditDoc({})
      toast.success('Edit Doc Success!')
      setIsOptionSelected(false) // Reset trạng thái khi đóng modal
      if (updateFinishButton) {
        updateFinishButton(true)
      }
      setEditingIndex(null) // Hiển thị lại các button Edit & Delete
    } catch (err: any) {
      if (err?.response?.status === 413) {
        toast.error('Request entity too large!')
      }
    } finally {
      setLoadingSubmit(false)
    }
  }

  const submitTab = async (dataValue: Record<string, string>, i: number, edit?: boolean) => {
    setLoadingAddTab(true)
    try {
      if (!edit) {
        const res = await CoursesAPI.addTab({
          course_section_id: course_section_id,
          tab_name: dataValue[tabActive],
          course_id: idCourse,
        })
        setData((prev: ITabContent[]) => {
          const data = [...prev]
          data[i].tabId = res.data.id
          data[i].creating = false
          setTabActive(res.data.id)
          setValue(res.data.id, dataValue[tabActive])
          unregister(tabActive)
          return data
        })
      } else {
        const res = await CoursesAPI.editTab({
          id: data[i].tabId,
          course_section_id: course_section_id,
          tab_name: dataValue[tabActive],
        })
        setData((prev: ITabContent[]) => {
          const data = [...prev]
          data[i].edit = false
          setValue(res.data.id, dataValue[tabActive])
          return data
        })
      }
    } finally {
      setLoadingAddTab(false)
    }
  }
  const handleDeleteTab = async (tabId: string, close: boolean = false) => {
    let body = [`Bạn có chắc chắn muốn ${close ? 'đóng' : 'xóa'} tab không?`]
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        // setLoadingDeleteTab(true)
        try {
          if (!tabData.creating) {
            await CoursesAPI.deleteTab(tabId)
          }
          unregister(tabId)
          const index = data.findIndex((e) => e.tabId === tabId)
          const arr = [...data]
          arr.splice(index, 1)
          setData((prev: ITabContent[]) => {
            return arr
          })
          setTabActive(data[index - 1]?.tabId || '')
        } catch (err) {
          console.error(err)
        } finally {
          // setLoadingDeleteTab(false)
        }
      },
    })
  }
  function titleCase(str: string) {
    var splitStr = str?.toLowerCase().split(' ')
    for (var i = 0; i < splitStr?.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    // Directly return the joined string
    return splitStr?.join(' ')
  }

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('text/plain', event.currentTarget.id)
    event.dataTransfer.effectAllowed = 'move'
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>, dropId: string) => {
    event.preventDefault()
    const draggedId = event.dataTransfer.getData('text/plain')
    if (docItems?.length) {
      const dragPos = docItems?.findIndex((el: ITabActivity) => el.id === draggedId)
      const dropPos = docItems?.findIndex((el: ITabActivity) => el.id === dropId)
      let data = docItems
      let dragItem = docItems?.splice(dragPos, 1)[0]
      data?.splice(dropPos, 0, dragItem)
      setDocItems(data?.map((item, index: number) => ({ ...item, position: index + 1 })))
    }
  }

  useEffect(() => {
    setDocItems(tabData?.value?.document as ITabActivity[])
  }, [data])
  console.log(docItems)

  const updatePositionOfDoc = async () => {
    const documents = docItems?.map((item: ITabActivity) => ({
      document_id: item.id,
      position: item.position,
    }))
    setLoading(true)

    if (documents) {
      try {
        await CoursesAPI.updateDocPosition({ id: tabData.tabId, data: { documents } })
        toast.success('Update position of documents successfully!')
      } catch (err) {
      } finally {
        setLoading(false)
      }
    }
    setDraggable(false)
    getDetailTab()
  }

  const handleGenEdit = (tabDocumentIndex: number, tabDocumentData: ITabDocumentData) => {
    if (
      editDoc?.tabIndex === undefined ||
      editDoc.tabIndex !== tabIndex ||
      editDoc.tabDocumentIndex !== tabDocumentIndex
    ) {
      let can_delete: boolean
      let can_edit: boolean

      if (tabDocumentData.type === TYPE_DOCUMENT.QUIZ) {
        can_delete = tabDocumentData?.quiz?.state?.condition?.can_delete_quiz as boolean
        can_edit = (tabDocumentData?.quiz?.state as QuizState)?.condition?.can_edit_quiz?.other
      } else {
        can_delete = true
        can_edit = true
      }
      return (
        <CreatedContent
          classNameContainer='sapp-h-48px mb-8 text-gray-600'
          key={tabDocumentIndex}
          {...(template === 3
            ? {
                actionDelete:
                  !isOptionSelected &&
                  (editingIndex === null || editingIndex === tabDocumentIndex) &&
                  showActivityInformation
                    ? () => {
                        handleDeleteDoc(tabDocumentIndex, tabIndex)
                      }
                    : undefined,
                actionEdit:
                  !isOptionSelected &&
                  (editingIndex === null || editingIndex === tabDocumentIndex) &&
                  showActivityInformation
                    ? () => {
                        setEditingIndex(tabDocumentIndex) // Khi click, lưu index vào state
                        handleOpenEdit(tabDocumentData.type, tabIndex, tabDocumentIndex)
                        if (updateFinishButton) {
                          updateFinishButton(false)
                        }
                      }
                    : undefined,
                actionPreview:
                  !can_edit && !editDoc?.type
                    ? () => {
                        handleOpenEdit(tabDocumentData.type, tabIndex, tabDocumentIndex)
                      }
                    : undefined,
              }
            : {
                actionDelete:
                  can_delete && !editDoc?.type
                    ? () => {
                        handleDeleteDoc(tabDocumentIndex, tabIndex)
                      }
                    : undefined,
                actionEdit:
                  can_edit && !editDoc?.type
                    ? () => {
                        handleOpenEdit(tabDocumentData.type, tabIndex, tabDocumentIndex)
                      }
                    : undefined,
                actionPreview:
                  !can_edit && !editDoc?.type
                    ? () => {
                        handleOpenEdit(tabDocumentData.type, tabIndex, tabDocumentIndex)
                      }
                    : undefined,
              })}
          iconGrayColor={'500'}
          name={titleCase(tabDocumentData?.type)}
          disableHoverButtonBg={true}
          customConfirm
          isDraggable={isDraggable}
          onDragStart={handleDragStart}
          onDrop={(e) => handleDrop(e, tabDocumentData.id)}
          docId={tabDocumentData.id}
        />
      )
    }
    if (tabDocumentData.type === TYPE_DOCUMENT.TEXT) {
      return (
        <TabText
          onSubmit={onEdit}
          data={data[tabIndex]?.value?.document?.[tabDocumentIndex]?.text_editor_content}
          handleClose={handleClose}
          key={tabDocumentIndex}
          loading={loadingSubmit}
        />
      )
    } else if (tabDocumentData.type === TYPE_DOCUMENT.QUIZ) {
      return (
        <AddTabQuiz
          tabId={tabData.tabId}
          doc={tabDocumentData}
          setData={setData}
          handleCancel={handleClose}
          onSubmit={(e: DataValue) => onEdit(e, TYPE_DOCUMENT.QUIZ)}
          // edit={false}
          key={tabDocumentIndex}
        />
      )
    } else if (tabDocumentData.type === TYPE_DOCUMENT.VIDEO) {
      return (
        <AddTabVideo
          key={tabDocumentIndex}
          handleClose={handleClose}
          onSubmit={onEdit}
          data={editDoc?.dataVideo}
          loading={loading}
        />
      )
    }
  }

  return (
    <div
      key={tabData.tabId}
      className={`tab-pane fade ${
        tabActive === tabData.tabId && 'show active'
      } sapp-tab-pane-content`}
      id={`kt_tab_pane_${tabIndex}`}
      role='tabpanel'
    >
      {contextHolder}
      <Spin spinning={loading}>
        <div className={`d-flex flex-column`}>
          {/* {docItems && docItems?.length > 0 && ( */}
          <>
            <SappLabel label='List Documents' />
            <div className='pb-0 testsss'>
              {docItems?.map((tabDocument: ITabActivity, tabDocumentIndex: number) =>
                handleGenEdit(tabDocumentIndex, tabDocument as ITabDocumentData)
              )}
            </div>
          </>
          {/* )} */}
          {createDoc?.type === TYPE_DOCUMENT.TEXT ? (
            <div className='' ref={elementRef} style={{ minHeight: '600px' }}>
              <TabText onSubmit={onSubmit} handleClose={handleClose} loading={loadingSubmit} />
            </div>
          ) : createDoc?.type === TYPE_DOCUMENT.QUIZ ? (
            <div className='' ref={elementRef}>
              <AddTabQuiz
                tabId={tabData.tabId}
                setData={setData}
                handleCancel={handleClose}
                onSubmit={(e: DataValue) => onSubmit(e, TYPE_DOCUMENT.QUIZ)}
              />
            </div>
          ) : createDoc?.type === TYPE_DOCUMENT.VIDEO ? (
            <div className='' ref={elementRef}>
              <AddTabVideo handleClose={handleClose} onSubmit={onSubmit} />
            </div>
          ) : (
            <></>
          )}
          {!editDoc?.type &&
            !createDoc?.type &&
            !tabData.creating &&
            !isDraggable &&
            showActivityInformation && (
              <div className='pb-0'>
                <SAPPSelect
                  onChange={(e) => {
                    handleOpenAdd(e, tabIndex)
                    setIsOptionSelected(true) // Khi chọn giá trị, disable các nút
                    if (updateFinishButton) {
                      updateFinishButton(false)
                    }
                  }}
                  options={[
                    { label: 'Text', value: 1 },
                    { label: 'Quiz', value: 2 },
                    { label: 'Video', value: 3 },
                    //   {label: 'Past Exam analysis', value: 4},
                  ]}
                  size='large'
                  placeholder={
                    <div className='d-flex align-items-center gap-2 sapp-item-column sapp-text--label'>
                      <PlusVideoIcon /> Add Document
                    </div>
                  }
                  className='w-100 sapp-h-45px'
                  suffixIcon={null}
                />
              </div>
            )}
        </div>
      </Spin>
    </div>
  )
}
export default TabContentMedium
