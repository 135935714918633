import { useInfiniteQuery } from 'react-query' // Import useInfiniteQuery
import { UsersAPI } from 'src/apis/user'
import { userKeys } from 'src/constants/queryKeyFactory'
import { IExamSubjectsRes } from 'src/type/users'

const useInfiniteExamSubjects = (subject_id: string) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery({
      queryKey: userKeys.examSubjects(subject_id),
      queryFn: ({ pageParam = 1 }) => {
        return UsersAPI.getExaminationSubjects({
          page_index: pageParam,
          page_size: 10,
          subject_id: subject_id,
        })
      },
      enabled: !!subject_id,
      getNextPageParam: (lastPage: IExamSubjectsRes) => {
        return lastPage.metadata.page_index < lastPage.metadata.total_pages
          ? lastPage.metadata.page_index + 1
          : undefined
      },
      refetchOnWindowFocus: false,
    })

  return {
    examSubjects: data?.pages.flatMap((page) => page?.examination_subjects) ?? [], // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
  }
}

export default useInfiniteExamSubjects
