import { Popover, Tooltip } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { useUserContext } from 'src/context/UserProvider'
import sapp from 'src/_metronic/assets/icons/sapp_menu.svg'
import PopoverProfile from './PopoverProfile'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import MenuItem from './MenuItem'
import { isEmpty } from 'lodash'

interface IProps {
  item?: any
  isOpen?: boolean
  menuLMS?: Array<any> | undefined
  menuAdmin?: Array<any> | undefined
  isActiveAdmin?: boolean
  menuShop?: Array<any> | undefined
  menuUser?: Array<any> | undefined
  isActiveUser?: boolean
  isActiveShop?: boolean
  menuFAQs?: Array<any> | undefined
  isActiveFAQs?: boolean
  menuMarketing?: Array<any> | undefined
  activeMarketing?: boolean
  activeAdmin?: boolean
  activeCX?: boolean
  menuCX?: Array<any> | undefined
}

const sidebarLeft = true

const MenuPopover = ({
  placement,
  title,
  iconClass,
  menuItems,
  isActive,
}: {
  placement: 'rightBottom' | 'rightTop'
  title: string
  iconClass: string
  menuItems: Array<any> | undefined
  isActive: boolean | undefined
}) => (
  <Popover
    placement={placement}
    arrow={false}
    content={
      <div className='menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto'>
        <div className='menu-item'>
          <div className='menu-content'>
            <span className='menu-section fs-5 fw-bolder ps-1 py-1'>{title}</span>
          </div>
        </div>
        {menuItems?.map((item) => (
          <MenuItem key={item.id} item={item} sidebarLeft={sidebarLeft} />
        ))}
      </div>
    }
  >
    <span className={`${isActive ? 'active' : ''} menu-link menu-center`}>
      <span className='menu-icon me-0'>
        <i className={`ki-outline ${iconClass} fs-1`}></i>
      </span>
    </span>
  </Popover>
)

const MenuLeft = ({
  menuLMS,
  menuAdmin,
  activeAdmin,
  menuShop,
  menuUser,
  isActiveUser,
  isActiveShop,
  menuFAQs,
  isActiveFAQs,
  activeMarketing,
  menuMarketing,
  activeCX,
  menuCX,
}: IProps) => {
  const { logOut, profileMe } = useUserContext()
  const navigate = useNavigate()

  const menu: Array<any> = [
    {
      placement: 'rightBottom',
      icon: 'ki-home-1',
      title: LANG_SIDEBAR.lms,
      menuItems: menuLMS,
      isActive:
        !isActiveShop &&
        !activeAdmin &&
        !isActiveUser &&
        !isActiveFAQs &&
        !activeMarketing &&
        !activeCX,
      showMenu: !isEmpty(menuLMS),
    },
    {
      placement: 'rightTop',
      icon: 'ki-shop',
      title: 'Shop',
      menuItems: menuShop,
      isActive: isActiveShop,
      showMenu: !isEmpty(menuShop),
    },
    {
      placement: 'rightTop',
      icon: 'ki-profile-circle',
      title: 'User Management',
      menuItems: menuUser,
      isActive: isActiveUser,
      showMenu: !isEmpty(menuUser),
    },
    {
      placement: 'rightTop',
      icon: 'ki-shield-tick',
      title: LANG_SIDEBAR.admin,
      menuItems: menuAdmin,
      isActive: activeAdmin,
      showMenu: !isEmpty(menuAdmin),
    },
    {
      placement: 'rightTop',
      icon: 'ki-message-question',
      title: 'FAQs',
      menuItems: menuFAQs,
      isActive: isActiveFAQs,
      showMenu: !isEmpty(menuFAQs),
    },
    {
      placement: 'rightTop',
      icon: 'ki-abstract-26',
      title: 'Marketing',
      menuItems: menuMarketing,
      isActive: activeMarketing,
      showMenu: !isEmpty(menuMarketing),
    },
    {
      placement: 'rightTop',
      icon: 'ki-like-shapes',
      title: LANG_SIDEBAR.menuCX,
      menuItems: menuCX,
      isActive: activeCX,
      showMenu: !isEmpty(menuCX),
    },
  ].filter((menu) => menu?.showMenu === true)

  return (
    <div className='app-sidebar-primary'>
      <div className='app-sidebar-logo d-none d-md-flex flex-center pt-8 pb-2'>
        <Link to={PageLink.DASHBOARD}>
          <img alt='Logo' src={sapp} className='sapp-h-45px' />
        </Link>
      </div>

      <div className='app-sidebar-menu flex-grow-1 my-5'>
        <div className='menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-6'>
          {menu.map((menu) => (
            <div className='menu-item py-2' key={menu.title}>
              <MenuPopover
                placement={menu.placement}
                title={menu.title}
                iconClass={menu.icon}
                menuItems={menu.menuItems}
                isActive={menu.isActive}
              />
            </div>
          ))}
        </div>
      </div>

      <div className='d-flex flex-column flex-center pb-4 pb-lg-8' id='kt_app_sidebar_footer'>
        <Popover
          placement='rightTop'
          content={<PopoverProfile profile={profileMe} />}
          arrow={false}
        >
          <div className='cursor-pointer symbol symbol-40px mb-9'>
            <img
              src={
                profileMe?.detail?.avatar?.['40x40'] ?? profileMe?.detail?.avatar?.ORIGIN ?? avatar
              }
              alt='user'
            />
          </div>
        </Popover>

        <Tooltip title='Log out' color='#ffffff' placement='right'>
          <div
            className='app-navbar-item cursor-pointer'
            onClick={() => logOut().then(() => navigate(PageLink.AUTH_LOGIN))}
          >
            <i className='ki-outline ki-exit-right fs-1'></i>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default MenuLeft
