import { Workbook, WorkbookInstance } from '@fortune-sheet/react'
import React, { Dispatch, memo, SetStateAction, useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  GradingDetail,
  QuestionAnswer,
  RESPONSE_OPTION,
  Question,
  Resource,
  IFilterTabProps,
  IAttemptAnswerData,
  GradingStatus,
  ICell,
} from 'src/type/grade'
import EditorReader from '../base/editor/EditorReader'
import {
  getSelectionRange,
  highlightSelection,
  indexCharacters,
  PREFIX,
  replaceTextAlignCenterToWebKitCenter,
  restoreHighlights,
} from 'src/utils/grade'
import { Control } from 'react-hook-form'
import ModalPreviewFile from 'src/components/question-bank/preview-question/ModalPreviewFile'
import { KTIcon } from 'src/_metronic/helpers'
import GradeIcon from '../grade/GradeIcon'
import ModalUploadFile from '../base/upload-file/ModalUploadFile/ModalUploadFile'
import { UploadFile } from 'antd'
import { RESOURCE_LOCATION } from '../base/upload-file/ModalUploadFile/UploadFileInterface'
import { useConfirm } from 'src/hooks/use-confirm'
import { GradeAPI } from 'src/apis/grade'
import { COMMENTS } from 'src/constants/grade'
import { numberToColumn } from 'src/utils/excel-helper'

interface IFormValues {
  answer: string
}

interface TooltipPosition {
  top: number
  left: number
}

type IPreviewProp = {
  data: Question
  control: Control<IFormValues>
  name: string
  questionData: QuestionAnswer
  index: number
  listGrades: GradingDetail[]
  setListGrades: Dispatch<SetStateAction<GradingDetail[]>>
  editingItem: GradingDetail | null
  setEditingItem: Dispatch<SetStateAction<GradingDetail | null>>
  activeRequirement: number
  questionGradingId: string
  activeQuestion: QuestionAnswer[]
  gradeStatus: string
  fetchGrade: (activeQuestion: QuestionAnswer[]) => void
  getQuizAttemptInfo: () => void
  setFilteredTabs: Dispatch<SetStateAction<IFilterTabProps[]>>
  attemptAnswerData: IAttemptAnswerData
}

const EssayQuestionPreview = ({
  data,
  name,
  control,
  questionData,
  index,
  listGrades,
  setListGrades,
  editingItem,
  setEditingItem,
  activeRequirement,
  questionGradingId,
  activeQuestion,
  gradeStatus,
  fetchGrade,
  getQuizAttemptInfo,
  setFilteredTabs,
  attemptAnswerData,
}: IPreviewProp) => {
  const { confirm, contextHolder } = useConfirm()
  const [openPreviewFile, setOpenPreviewFile] = useState({ status: false, url: '' })
  const [htmlOutput, setHtmlOutput] = useState<string[]>([])
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({ top: 0, left: 0 })
  const [modalUpload, setModalUpload] = useState<boolean>(false)
  const [uploadFile, setUploadFile] = useState<UploadFile[] | []>([])

  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const refDocument = useRef<HTMLDivElement>(null)
  const refEditor = useRef<HTMLDivElement>(null)

  const submitted = questionData?.active === 'SUBMITED'
  // const submitted = true
  const defaultValue = questionData?.short_answer || ''
  const text_editor_content = questionData?.question?.question_content || ''

  // Highlights when mouse up
  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Check if the target element is a math element and exit early if true
    const isMathElement =
      (e?.target as HTMLElement)?.tagName?.charAt(0) === 'm' ||
      (e?.target as HTMLElement)?.firstChild?.nodeName === 'math'
    if (isMathElement) return

    const isDisableEvent = (e?.target as HTMLElement)?.parentElement?.hasAttribute('disable-event')
    if (isDisableEvent) return

    if (gradeStatus === GradingStatus.FINISHED) return

    const selection = window.getSelection()
    const selectedString = selection?.toString()
    const rangePosition = getSelectionRange(PREFIX)

    // Exit if no text is selected or range is invalid
    if (!selectedString || !rangePosition) return

    const range = selection?.getRangeAt(0)
    const rects = range?.getClientRects()

    // Exit if no rectangles are found in the selection
    if (!rects || rects.length === 0) return

    const rect = rects[0]
    const { begin, end } = rangePosition

    // Check if the highlighted text has already been graded
    const isHighlighted = listGrades.some(
      (item: GradingDetail) =>
        item.begin === begin && (item.end === end || Number(item.end) + 1 === end)
    )
    if (isHighlighted) return

    const tooltipWidth = 150
    const tooltipHeight = 40
    const parentElement = (e.target as HTMLElement).closest('.layout-grade-relative')
    const parentRect = parentElement?.getBoundingClientRect()

    // Exit if parent element is not found
    if (!parentRect) return

    // Calculate the initial tooltip position
    let leftPosition = rect.left + rect.width / 2 - tooltipWidth / 2
    let topPosition = rect.top - parentRect.top - tooltipHeight - 10

    // Adjust tooltip position if it exceeds the viewport boundaries
    const viewportWidth = window.innerWidth
    leftPosition = Math.max(10, Math.min(leftPosition, viewportWidth - tooltipWidth - 10))
    topPosition = topPosition < 0 ? rect.bottom - parentRect.top + 10 : topPosition

    // Set the tooltip position and make it visible
    const tooltipPosition: TooltipPosition = { top: topPosition, left: leftPosition }
    setTooltipPosition(tooltipPosition)
    setShowTooltip(true)
  }

  const handleSaveHighLight = (highlightItem: GradingDetail) => {
    setListGrades((prevGrades) => [...prevGrades, highlightItem])
    setEditingItem(highlightItem)
    scrollToHighlight(Number(highlightItem?.begin), Number(highlightItem?.end))
  }

  // Hàm scroll tới highlight đã lưu
  const scrollToHighlight = (begin: number, end: number) => {
    setTimeout(() => {
      const targetElement = document.querySelector(
        `.grade-comment-detail[data-begin="${begin}"][data-end="${end}"]`
      ) as HTMLElement

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }, 1000)
  }

  const handleOptionSelect = (type: 'comment' | 'grading') => {
    let className

    // Xác định class sẽ thêm vào các ký tự dựa trên loại highlight
    if (type === 'comment') {
      className = 'sapp-comment-highlight'
    } else {
      className = 'sapp-grading-highlight'
    }

    // Thêm highlight và lấy vị trí begin và end
    const highlightPosition = highlightSelection(className)

    if (highlightPosition) {
      const { begin, end } = highlightPosition
      const highlightItem = {
        begin,
        end,
        comment: '',
        grade: null,
        validate: type === 'comment' ? 'comment' : 'grade',
      }

      handleSaveHighLight(highlightItem)
    }

    setShowTooltip(false)
  }

  const handleUploadGradeFile = (files: Resource[]) => {
    const highlightItem = {
      begin: 0,
      end: 0,
      comment: '',
      grade: null,
      validate: 'file',
      grading_file: {
        file_key: files?.[0]?.file_key || '',
        file_name: files?.[0]?.name || '',
      },
    }
    handleSaveHighLight(highlightItem)
  }

  const getCutRange = (sheet: any) => {
    let range = { rowEnd: 1, colEnd: 1, data: [] }

    if (sheet?.data?.length) {
      sheet.data.map((item: ICell[], index: number) => {
        const cellIndex = [...item].reverse().findIndex((e: ICell) => e?.v || e?.f)
        const lastIndex = cellIndex != -1 ? item.length - cellIndex - 1 : -1
        if (lastIndex >= range.colEnd) range.colEnd = lastIndex + 1
        if (lastIndex >= 0 && range.rowEnd <= index) range.rowEnd = index + 1
      })

      range.data = sheet.data.slice(0, range.rowEnd).map((e: ICell[]) => e.slice(0, range.colEnd))
    }

    return range
  }

  const handleSheetHtml = (data: any) => {
    let sheetHtml = `<table data-type="fortune-copy-action-table">`
    const initArray = Array.apply(null, Array(data[0].length + 1 || 1))
    const colGroupsHtml = initArray.map((e, idx) =>
      idx == 0 ? `<colgroup width='50px'></colgroup>` : `<colgroup width='75px'></colgroup>`
    )
    // Add row&column index
    const colsIndex = initArray.map((e, index: number) => {
      return { v: numberToColumn(index), disable: true }
    })
    const newSheet = data.map((e: ICell[], index: number) => {
      const newRow = [...e]
      newRow.unshift({ v: index + 1, disable: true })
      return newRow
    })

    newSheet.unshift(colsIndex)
    sheetHtml += colGroupsHtml.join('')

    newSheet.map((row: []) => {
      let rowHtml = `<tr>`
      row.map((cel: ICell) => {
        const value = cel?.v || ''
        const formula = cel?.f || ''
        const celHtml = formula
          ? `<td style="height: 27px" data-formula="${formula}">${value}</td>`
          : `<td ${
              cel?.disable
                ? "disable-event=true style='height: 27px; text-align: center'"
                : "style='height: 27px'"
            }>${value}</td>`

        rowHtml += celHtml
      })
      rowHtml += `</tr>`
      sheetHtml += rowHtml
    })
    sheetHtml += `</table>`

    return sheetHtml
  }

  const handleWorkbookRef = (instance: WorkbookInstance) => {
    if (instance) {
      try {
        const sheets = instance?.getAllSheets()
        let allSheetsHtml: string[] = []

        sheets.forEach((sheetData) => {
          const cutRange = getCutRange(sheetData)
          const sheetHtml = handleSheetHtml(cutRange.data)
          allSheetsHtml.push(`${sheetHtml}`)
        })

        if (JSON.stringify(htmlOutput) !== JSON.stringify(allSheetsHtml)) {
          setHtmlOutput(allSheetsHtml)
        }
      } catch (error) {
        console.error('Error in getHtmlByRange:', error)
      }
    }
  }

  // File
  const handleSetFiles = (files: Resource[], type: string) => {
    if (type === 'resources') {
      handleSetUploadFiles([...(files || [])])
    } else {
      handleSetUploadFiles([...(uploadFile || []), ...(files || [])])
    }
  }

  const handleSetUploadFiles = (files: any) => {
    setUploadFile(files)
    handleUploadGradeFile(files)
  }

  const handleRemoveAdditional = async () => {
    try {
      const response = await GradeAPI.deleteQuestionByFile(
        questionGradingId,
        activeQuestion?.[activeRequirement]?.requirement_id || null
      )

      if (response) {
        fetchGrade(activeQuestion)
        getQuizAttemptInfo()
      }
    } catch (error) {}
  }

  const handleRemove = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        try {
          const gradeToDelete = listGrades.find(
            (grade) => grade?.begin === editingItem?.begin && grade?.end === editingItem?.end
          )

          if (gradeToDelete && !gradeToDelete.comment && !gradeToDelete.grade) {
            setListGrades((prevGrades) => {
              const updatedGrades = prevGrades.filter(
                (grade) => grade.begin !== editingItem?.begin && grade.end !== editingItem?.end
              )

              return updatedGrades
            })
            setEditingItem(null)
          } else {
            handleRemoveAdditional && (await handleRemoveAdditional())
          }
          setUploadFile([])
        } catch (error) {}
      },
    })
  }

  const showFormulaTooltip = (e: MouseEvent) => {
    const td = e.target as HTMLElement
    const text = td.getAttribute('data-formula') || ''
    const tooltip = document.getElementById('formula-tooltip')
    const parentElement = (e.target as HTMLElement).closest('.layout-grade-relative')
    const parentRect = parentElement?.getBoundingClientRect()
    const rect = td.getBoundingClientRect()

    if (tooltip && parentRect) {
      let leftPosition = rect.left + rect.width / 2 - 100
      let topPosition = rect.top - parentRect.top + 30

      tooltip.style.display = 'block'
      tooltip.style.padding = '4px'
      tooltip.style.background = '#ffb800'
      tooltip.style.left = `${leftPosition}px`
      tooltip.style.top = `${topPosition}px`
      tooltip.innerText = `${text}`
    }
  }

  const hideFormulaTooltip = (e: MouseEvent) => {
    const tooltip = document.getElementById('formula-tooltip')
    if (tooltip) tooltip.style.display = 'none'
  }

  // Sử dụng MutationObserver để theo dõi khi EditorReader render xong
  useEffect(() => {
    const editorArea = document.getElementById(`editor_area_${index}`)

    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          indexCharacters(`#editor_area_${index}`)
          observer.disconnect()
          break
        }
      }
    })

    if (editorArea && submitted) {
      observer.observe(editorArea, { childList: true, subtree: true })
    }

    return () => {
      observer.disconnect()
    }
  }, [htmlOutput])

  useEffect(() => {
    if (activeRequirement === index && submitted) {
      const timeoutId = setTimeout(() => {
        restoreHighlights(listGrades, String(index))
        const gradeFindFile = listGrades.find((grade) => grade?.validate === 'file')
        if (gradeFindFile) {
          const gradeFile = {
            uid: gradeFindFile?.grading_file?.file_key || '',
            file_key: gradeFindFile?.grading_file?.file_key,
            name: gradeFindFile?.grading_file?.file_name,
          }
          setUploadFile([gradeFile as UploadFile])
        } else {
          setUploadFile([])
        }
      }, 1000)
      return () => clearTimeout(timeoutId)
    }
  }, [listGrades, htmlOutput])

  useEffect(() => {
    if (submitted) {
      const handleClickOutside = (event: MouseEvent) => {
        if (tooltipRef?.current && !tooltipRef?.current?.contains(event.target as Node)) {
          setShowTooltip(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [])

  useEffect(() => {
    if (refDocument) {
      refDocument?.current?.addEventListener('click', handleHighlightClick)

      return () => {
        refDocument?.current?.removeEventListener('click', handleHighlightClick)
      }
    }
  }, [refDocument?.current])

  useEffect(() => {
    if (refEditor?.current) {
      const collection = document.getElementsByTagName('td')
      const tds = [...collection]

      tds.map((e: HTMLElement) => {
        if (e.hasAttribute('data-formula')) {
          e.addEventListener('mouseenter', (e) => showFormulaTooltip(e))
          e.addEventListener('mouseleave', (e) => hideFormulaTooltip(e))
        }
      })

      return () => {
        tds.map((e: HTMLElement) => {
          if (e.hasAttribute('data-formula')) {
            e.removeEventListener('mouseenter', (e) => showFormulaTooltip(e))
            e.removeEventListener('mouseleave', (e) => hideFormulaTooltip(e))
          }
        })
      }
    }
  }, [refEditor?.current])

  const handleHighlightClick = (event: MouseEvent) => {
    const targetElement = event?.target as HTMLElement
    let spanElement = targetElement

    if (targetElement?.classList?.contains('sapp-grade-tooltip')) {
      spanElement = targetElement?.closest('.sapp-grading-highlight') as HTMLElement

      if (!spanElement || !spanElement?.id?.match(/^h_(\d+)$/)) {
        return
      }
    }

    if (spanElement.tagName === 'SPAN') {
      const spanId = spanElement?.id

      const indexMatch = spanId?.match(/^h_(\d+)$/)

      if (indexMatch) {
        const index = parseInt(indexMatch[1], 10)

        const gradeCommentElements = document.querySelectorAll('.grade-comment-detail')

        let foundElement: HTMLElement | null = null

        gradeCommentElements.forEach((element) => {
          const elementBegin = parseInt(
            (element as HTMLElement).getAttribute('data-begin') || '0',
            10
          )
          const elementEnd = parseInt((element as HTMLElement).getAttribute('data-end') || '0', 10)

          if (index >= elementBegin && index <= elementEnd) {
            foundElement = element as HTMLElement
          }
        })

        if (foundElement) {
          ;(foundElement as HTMLElement).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })

          if (typeof (foundElement as HTMLElement).focus === 'function') {
            ;(foundElement as HTMLElement).focus()
          }
        }
      }
    }
  }

  return (
    <div className='bg-white'>
      {contextHolder}
      <ModalUploadFile
        open={modalUpload}
        setOpen={setModalUpload}
        fileType={'ALL_RESOURCE'}
        setSelectedFile={handleSetFiles}
        isMultiple={false}
        resourceLocation={'' as RESOURCE_LOCATION}
        fileChecked={uploadFile}
        onlyTab={'UPLOAD_FILE'}
        title='Upload files'
        maxCount={10}
        is_grading={true}
      ></ModalUploadFile>
      <div>
        {/*Topic description*/}
        <EditorReader
          className='sapp-questions mb-4'
          text_editor_content={data?.question_topic?.description}
        />
        {/*Topic content*/}
        <EditorReader
          text_editor_content={replaceTextAlignCenterToWebKitCenter(text_editor_content)}
          className='sapp-questions mb-10'
        />
        {/*Requirement content, and file*/}
        {data?.requirements?.length > 0 && (
          <div className='topic-requirement'>
            <div className='border my-6' />
            <div className='fs-4 fw-bold mb-2'>Requirement:</div>

            <div className='mt-4'>
              <div className='fs-4 fw-bold'>{`${data?.requirements[index]?.name}`}</div>
              {data?.requirements[index]?.description && (
                <EditorReader
                  className='sapp-questions mt-1.5'
                  text_editor_content={replaceTextAlignCenterToWebKitCenter(
                    data?.requirements[index]?.description || ''
                  )}
                />
              )}
              {data?.requirements[index]?.files?.length > 0 &&
                data?.requirements[index]?.files.map((e, index: number) => {
                  return (
                    <div
                      className='fs-4 cursor-pointer sapp-text-gray-800 sapp-width-max'
                      onClick={() => {
                        setOpenPreviewFile({ status: true, url: e?.resource?.url || '' })
                      }}
                      key={index}
                    >
                      {e?.resource?.name}
                    </div>
                  )
                })}
            </div>
          </div>
        )}
        {/*Topic Resource*/}
        <>
          {data.question_topic?.files?.length > 0 && (
            <div className='topic-resource'>
              <div className='border my-6' />
              <div>
                <div className='fs-4 fw-bold mb-2'>Topic Resource:</div>
                {data.question_topic?.files.map((e, index: number) => {
                  return (
                    <div
                      className='fs-4 cursor-pointer sapp-text-gray-800 sapp-width-max'
                      onClick={() =>
                        setOpenPreviewFile({
                          status: true,
                          url: e?.resource?.url || '',
                        })
                      }
                      key={index}
                    >
                      {e?.resource?.name}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </>
        {/*Question and answer file*/}
        {questionData?.answer_file && submitted && (
          <div className='pt-8' id={`topic_aswer_file_${index}`}>
            <a
              className='sapp-download-file sapp-border-grade-2 p-5 text-gray-800 sapp-text-hover-grade-primary d-flex justify-content-between flex-wrap align-items-center'
              key={questionData?.answer_file?.file_key ?? ''}
              href={questionData?.answer_file?.url || ''}
              download={questionData?.answer_file?.file_name ?? ''}
            >
              <span className='fs-4 d-flex align-items-center'>
                <GradeIcon className='sapp-text-gray me-3' iconType='link' />
                {questionData?.answer_file?.file_name ?? ''}
              </span>
              <GradeIcon className='sapp-text-gray' iconType='download' />
            </a>
            <div className='mt-8 sapp-border-grade-2 p-5 text-gray-800 grade-bg-1 d-flex align-items-center fs-4'>
              <span
                className='sapp-border-grade-1 bg-white d-flex align-items-center cursor-pointer sapp-width-max px-4 py-3 me-4'
                onClick={() => {
                  uploadFile?.length === 0 &&
                    gradeStatus !== GradingStatus.FINISHED &&
                    setModalUpload(true)
                }}
              >
                <GradeIcon className='text-gray-800 me-3' iconType='cloud-upload' />
                {COMMENTS?.UpLoad}
              </span>
              <span className='fs-4 text-gray-800'>
                {COMMENTS?.FileDescription}
                <br />
                {COMMENTS?.FileSize}
              </span>
            </div>
            <>
              {uploadFile?.map((file: Resource) => (
                <>
                  <div
                    className='mt-8 sapp-remove-file cursor-pointer sapp-border-grade-2 p-5 text-gray-800 sapp-text-remove-grade-primary d-flex justify-content-between flex-wrap align-items-center grade-bg-1'
                    onClick={() => {
                      gradeStatus !== GradingStatus.FINISHED && handleRemove()
                    }}
                    key={file?.file_key}
                  >
                    <span className='fs-4 d-flex align-items-center'>
                      <GradeIcon className='sapp-text-gray me-3' iconType='link' />
                      {file?.name || file?.file_name || ''}
                    </span>
                    <GradeIcon className='sapp-text-gray' iconType='trash' />
                  </div>
                </>
              ))}
            </>
          </div>
        )}
        {/*Question aswer*/}
        {submitted && defaultValue && (
          <div className='layout-grade-relative w-100 position-relative mt-8'>
            {showTooltip && !editingItem && (
              <div
                className='sapp-tooltip-grade'
                ref={tooltipRef}
                style={{
                  position: 'absolute',
                  top: `${tooltipPosition.top}px`,
                  left: `${tooltipPosition.left}px`,
                }}
              >
                <button
                  className='sapp-grade-action edit-icon'
                  onClick={() => handleOptionSelect('comment')}
                >
                  <KTIcon
                    iconName='message-edit'
                    iconType='outline'
                    className='sapp-color-gray-a1 pe-2'
                    sizeIcon='fs-4'
                  />
                  {COMMENTS.Comment}
                </button>
                <button className='sapp-grade-action' onClick={() => handleOptionSelect('grading')}>
                  <KTIcon
                    iconName='triangle'
                    iconType='outline'
                    className='sapp-color-gray-a1 pe-2'
                    sizeIcon='fs-4'
                  />
                  {COMMENTS.Grading}
                </button>
              </div>
            )}
            {data?.response_option === RESPONSE_OPTION.WORD ||
            data?.response_option_answer === RESPONSE_OPTION.WORD ? (
              <div
                className={`pt-8 ${gradeStatus !== GradingStatus.FINISHED ? 'grade-unfinish' : ''}`}
                id={`editor_area_${index}`}
                onMouseUp={handleMouseUp}
                ref={refDocument}
              >
                <EditorReader
                  text_editor_content={replaceTextAlignCenterToWebKitCenter(
                    defaultValue ? defaultValue : ''
                  )}
                  className='sapp-grade-questions-content border overflow-auto p-10'
                />
              </div>
            ) : data?.response_option === RESPONSE_OPTION.SHEET ||
              data?.response_option_answer === RESPONSE_OPTION.SHEET ? (
              <React.Fragment>
                <div className='d-none'>
                  <Controller
                    name={(name as 'answer') || 'answer'}
                    control={control}
                    defaultValue={defaultValue ? defaultValue : undefined}
                    render={({ field: { value } }) => {
                      let parsedData = [{ name: 'Sheet1' }]
                      try {
                        if (value) {
                          parsedData = JSON.parse(value.replace(/\\/g, ''))
                        }
                      } catch (error) {
                        console.error('Error parsing JSON:', error)
                      }
                      return <Workbook ref={handleWorkbookRef} data={parsedData} />
                    }}
                  ></Controller>
                </div>
                <div
                  className={`${
                    gradeStatus !== GradingStatus.FINISHED ? 'grade-unfinish' : ''
                  } position-relative`}
                  id={`editor_area_${index}`}
                  onMouseUp={handleMouseUp}
                  ref={refDocument}
                >
                  <div id='formula-tooltip' className='sapp-formula-tooltip'></div>
                  {(htmlOutput || [])?.map((sheetHtml, index) => (
                    <EditorReader
                      key={index}
                      text_editor_content={replaceTextAlignCenterToWebKitCenter(sheetHtml)}
                      className='sapp-grade-questions-content table-content overflow-auto pt-10'
                      extenalRef={refEditor}
                    />
                  ))}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        )}
      </div>
      <ModalPreviewFile
        open={openPreviewFile.status}
        setOpen={setOpenPreviewFile}
        url={openPreviewFile?.url || ''}
      />
    </div>
  )
}
export default memo(EssayQuestionPreview)
