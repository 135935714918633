import { zodResolver } from '@hookform/resolvers/zod'
import { TablePaginationConfig } from 'antd'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import {
  ACCOUNT_TYPE,
  FILTER_CLASS,
  FILTER_EXAM,
  FILTER_SELECTALL_SORTBY,
  GENDER,
  PageLink,
  STATUS,
} from 'src/constants'
import { BUTTON_TEXT } from 'src/constants/lang'
import { formatDate, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import HookFormTextField from '../base/textfield/HookFormTextField'
import UserListGrouping from '../user-management/UserListGrouping'
import { ListViewProvider } from './components/core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest } from './components/core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseDataPagination,
} from './components/core/QueryResponseProvider'
import { UsersListToolbar } from './components/header/UserListToolbar'
import { UsersTable } from './components/table/UsersTable'
import ListFilterLayout from '../layout/listFilter'

const fieldNames = [
  'sex',
  'text',
  'status',
  // 'class',
  // 'examType',
  // 'examList',
  'sortType',
  'fromDate',
  'toDate',
  'type_user',
]
const initialValues: any = {
  sex: '',
  text: '',
  status: '',
  // class: '',
  // examType: '',
  // examList: '',
  sortType: '',
  fromDate: '',
  toDate: '',
  type_user: '',
}

const StudentsList = () => {
  const { updateState } = useQueryRequest()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const response = useQueryResponseDataPagination()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Map<number, React.Key[]>>(new Map())
  const selectedRowId = useMemo(() => [...selectedRowKeys.values()].flat(), [selectedRowKeys])
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: Number(searchParams.get('page_index')) || 1,
    pageSize: Number(searchParams.get('page_size')) || 10,
    total: 10,
    showSizeChanger: true,
    showQuickJumper: true,
  })
  const { refetch, isLoading } = useQueryResponse()
  const navigate = useNavigate()

  const [openBlocked, setOpenBlocked] = useState(false)

  const queryParams = {
    text: searchParams.get('text') ?? '',
    sex: searchParams.get('sex') ?? '',
    status: searchParams.get('status') ?? '',
    sortType: searchParams.get('sortType') ?? '',
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: Number(searchParams.get('page_index')) || 1,
    page_size: Number(searchParams.get('page_size')) || 10,
    type_user: searchParams.get('type_user') ?? '',
  }
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const validationSchema = z.object({
    text: z.string().optional(),
    sex: z.string().optional(),
    status: z.string().optional(),
    class: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    // examDate: z.any(),
    type_user: z.any(),
  })

  // Using validate for input
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      text: getValues('text'),
      sex: replaceValueAll(getValues('sex')),
      status: replaceValueAll(getValues('status')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      page_index: currenPage,
      page_size: size,
      type_user: getValues('type_user'),
      dateField:
        getValues('text') ||
        getValues('status') ||
        getValues('sortType') ||
        getValues('fromDate') ||
        getValues('toDate') ||
        getValues('type_user')
          ? 'updated_at'
          : 'created_at',
    }
    updateState(queryParam)
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const fromDate = getDateInfo(getValues('fromDate'))
    const toDate = getDateInfo(getValues('toDate'))

    updateState({
      text: getValues('text').trimStart().trimEnd() ?? '',
      status: replaceValueAll(getValues('status')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: getValues('fromDate')
        ? formatISOFromDate(fromDate.year, fromDate.month, fromDate.day)
        : '',
      toDate: getValues('toDate') ? formatISOToDate(toDate.year, toDate.month, toDate.day) : '',
      type_user: getValues('type_user'),
      dateField:
        getValues('text') ||
        getValues('status') ||
        getValues('sortType') ||
        getValues('fromDate') ||
        getValues('toDate') ||
        getValues('type_user')
          ? 'updated_at'
          : 'created_at',
      sex: replaceValueAll(getValues('sex')),
      page_index: 1,
    })
    refetch()
    handleChangeParams(1, queryParams.page_size || 10)
  }

  const onReset = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    navigate(PageLink.STUDENTS)
    updateState({
      text: '',
      sex: '',
      status: '',
      fromDate: null,
      toDate: null,
      class: '',
      // examType: '',
      // examList: '',
      sortType: '',
      dateField: '',
      page_index: 1,
      page_size: 10,
      type_user: '',
    })
  }

  const blockUser = useMutation(() => UsersAPI.blocked(selectedRowId as string[]), {
    onSuccess: () => {
      refetch()
      toast.success('Block Successfully!')
      setOpenBlocked(false)
      cancel(true)
    },
  })

  return (
    <div className='card'>
      <div className='px-10 border-0 pt-10'>
        <div className=''>
          <ListFilterLayout>
            <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
              <HookFormTextField
                control={control}
                name='text'
                placeholder='Search'
                defaultValue={queryParams?.text}
                onSubmit={onSubmit}
                isListScreen
              />
            </div>
            <HookFormSelectAntd
              control={control}
              name='sex'
              placeholder='Gender'
              defaultValue={queryParams.sex ?? ''}
              classNameHeight='sapp-h-40'
              options={GENDER}
            />
            <HookFormSelectAntd
              control={control}
              name='status'
              placeholder='Status'
              defaultValue={queryParams.status ?? ''}
              classNameHeight='sapp-h-40'
              options={STATUS}
            />
            <HookFormSelectAntd
              control={control}
              name='class'
              placeholder='Class'
              disabled
              classNameHeight='sapp-h-40'
              options={FILTER_CLASS}
            />
            <HookFormSelectAntd
              control={control}
              name='examType'
              placeholder='Type of Exams'
              // defaultValue={queryParams.examType ?? ''}
              classNameHeight='sapp-h-40'
              disabled
              options={FILTER_EXAM}
            />
            <HookFormSelectAntd
              control={control}
              name='examList'
              placeholder='List of Exams'
              // defaultValue={queryParams.examList ?? ''}
              classNameHeight='sapp-h-40'
              disabled
              options={STATUS}
            />
            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort by'
              control={control}
              size='large'
              defaultValue={queryParams?.sortType ?? ''}
              classNameHeight='sapp-h-40'
              options={FILTER_SELECTALL_SORTBY}
            />
            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />
            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
            <HookFormSelectAntd
              name='type_user'
              control={control}
              placeholder='Account Type'
              classNameHeight='sapp-h-40'
              defaultValue={queryParams.type_user ?? ''}
              options={ACCOUNT_TYPE}
            />
          </ListFilterLayout>
        </div>
      </div>
      <div className='card-header border-0 pt-6'>
        <div className='d-flex'>
          <SAPPFIlterButton
            titleReset='Reset'
            okClick={onSubmit}
            resetClick={onReset}
            titleSubmit={BUTTON_TEXT.SEARCH}
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
        <>
          {/* start:: button open modal */}
          {selectedRowId.length > 0 ? (
            <div className='col-xl-8 col-sm-8 col-lg-9 px-xl-3 pe-xl-0'>
              <UserListGrouping
                selected={selectedRowId}
                blockUser={async () => await blockUser.mutateAsync()}
                openBlocked={openBlocked}
                setOpenBlocked={setOpenBlocked}
              />
            </div>
          ) : (
            <div className='col-xl-8 col-sm-8 col-lg-9 px-xl-3'>
              <UsersListToolbar
                searchTerm={watch('text')}
                sortSex={watch('sex')}
                sortSortBy={watch('sortType')}
                sortStatus={watch('status')}
                // sortTypeofExam={watch('examType')}
                // sortListofExam={watch('examList')}
                fromDate={watch('fromDate')}
                toDate={watch('toDate')}
                location={location}
                type_user={watch('type_user')}
              />
            </div>
          )}
          {/* end:: button open modal */}
        </>
      </div>
      <UsersTable
        studentList={response?.data || []}
        pagination={pagination}
        setPagination={setPagination}
        handleChangeParams={handleChangeParams}
        queryParams={queryParams}
        setSelected={setSelectedRowKeys}
        selected={selectedRowKeys}
      />
    </div>
  )
}

const UsersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StudentsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { UsersListWrapper }
