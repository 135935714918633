import { Dispatch, SetStateAction, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import ClassroomCalendarApi from 'src/apis/classroom-calendar'
import { WarningBorderIcon } from 'src/common/icons'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import ButtonDanger from 'src/components/ui/button-danger/ButtonDanger'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { convertLocalToUTC } from 'src/utils'

interface IProps {
  open: boolean
  setOpen: Dispatch<
    SetStateAction<{ isOpen: boolean; startDate?: Date; endDate?: Date; id: string }>
  >
  onClose?: () => void
  loading?: boolean
  event?: { isOpen: boolean; startDate?: Date; endDate?: Date; id: string }
  roomId: string
  refresh: () => void
}

interface IInputProps {
  approve_all_event: 'true' | 'false'
}

const DeleteEventModal = ({ open, setOpen, onClose, event, roomId, refresh }: IProps) => {
  const { control, handleSubmit } = useForm<IInputProps>({
    mode: 'onSubmit',
    defaultValues: {
      approve_all_event: 'true',
    },
  })

  const handleDelete = async (data: IInputProps) => {
    try {
      if (event?.id && event?.startDate && event?.endDate) {
        const payload = {
          approve_all_event: data?.approve_all_event === 'true' ? true : false,
          old_start_date: event?.startDate && convertLocalToUTC(event?.startDate),
          old_end_date: event?.endDate && convertLocalToUTC(event?.endDate),
        }
        await ClassroomCalendarApi.deleteScheduleRental(event?.id, roomId, payload)
        toast.success('Delete successfully')
        handleClose()
        refresh()
      }
    } catch (error) {}
  }

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <Modal
      dialogClassName={`modal-dialog swal2-container sapp-popup-confirm mw-430px`}
      contentClassName='sapp-p-content-modal'
      centered
      show={open}
      onHide={handleClose}
    >
      <div className='d-flex justify-content-center align-items-center mb-7'>
        <WarningBorderIcon />
      </div>
      <div className='d-flex justify-content-center text-center d-block mb-5'>
        <HookFormRadioGroup
          control={control}
          name='approve_all_event'
          options={[
            { label: 'This Event', value: 'false' },
            { label: 'This and following events', value: 'true' },
          ]}
          hasBorder={false}
        />
      </div>

      <div className='swal2-actions d-flex gap-3'>
        <div className='swal2-loader'></div>
        <ButtonDanger title='Yes' onClick={handleSubmit(handleDelete)} />
        <ButtonSecondary title='No' onClick={handleClose} />
      </div>
    </Modal>
  )
}

export default DeleteEventModal
