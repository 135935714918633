import { PageLink } from 'src/constants'
import { Link } from 'react-router-dom'
import { IRoles } from 'src/type/roles'
import PermissionInfo from 'src/components/roles/detail/PermissionInfo'
import { useEffect, useState } from 'react'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import { KTIcon } from 'src/_metronic/helpers'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_ROLE_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import ButtonIconSecondary from 'src/components/ui/button-icon-secondary/ButtonIconSecondary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

interface IProps {
  role: IRoles
  handleOpenModelStaffs: (id: string, role?: any) => void
  handleOpenModelRole: (id: string, role: any) => void
}

const RoleInfo = ({ role, handleOpenModelStaffs, handleOpenModelRole }: IProps) => {
  const [showRoles, setShowRoles] = useState<Array<any>>(role?.permissions)
  const [countMore, setCountMore] = useState<number>(0)
  const { profileMe } = useUserContext()
  const allowRenderEditRoles = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_ROLE_GR.EDIT_ROLES) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowAddUserRoles = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_ROLE_GR.EDIT_ROLES) ||
      role.permissions?.includes(TITLE_ROLE_GR.CREATE_ROLES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  useEffect(() => {
    if (role?.permissions.length > 5) {
      setShowRoles(role?.permissions.slice(0, 5))

      const getMore = role?.permissions.slice(5)
      setCountMore(getMore.length)
    } else if (role?.permissions.length < 5) {
      setShowRoles(role?.permissions)
      setCountMore(0)
    }
  }, [role])
  return (
    <div className='sapp-table-min-height'>
      <div className='card card-flush h-md-100'>
        <div className='card-header px-8'>
          <div className='card-title'>
            <h2 className='sapp-fs-18 text-gray-800'>{role?.name}</h2>
          </div>
        </div>
        <div className='card-body px-8 pt-1'>
          <div className='fw-bold text-gray-600 mb-5'>
            Total users with this role: {role?.staff_count}
          </div>
          <div className='d-flex flex-column text-gray-600'>
            {showRoles.map((item: any, index: number) => (
              <PermissionInfo key={index} title={item?.display_name} />
            ))}
            {countMore > 0 && (
              <div className='d-flex align-items-center py-2'>
                <span className='bullet bg-primary me-3'></span>
                <em>and {countMore} more...</em>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex flex-wrap px-8 pt-2 pb-7 gap-4 sapp-3xl-space-between'>
          <ButtonSecondary
            className='px-5'
            title='View Role'
            link={`${PageLink.ROLE_DETAIL}/${role?.id}`}
            size='small'
          />
          {allowRenderEditRoles && (
            <ButtonSecondary
              className='px-5'
              title='Edit Role'
              onClick={() => handleOpenModelRole(role?.id as string, role)}
              size='small'
            />
          )}

          <ButtonIconSecondary
            className='px-5'
            size='small'
            onClick={() => handleOpenModelStaffs(role?.id as string, role)}
            iconName={'plus'}
            title={'Add User'}
            disabled={!allowAddUserRoles}
          />
        </div>
      </div>
    </div>
  )
}

export default RoleInfo
