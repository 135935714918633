import { PageLink } from 'src/constants/pageLink'

export const navigateToCourseContent = (
  template: string | null,
  courseId: string | undefined,
  navigate: (path: string) => void
) => {
  const targetPage = template === '3' ? PageLink.COURSE_MEDIUM : PageLink.COURSE_FULL
  navigate(`${targetPage}/${courseId}?page=1`)
}

export const navigateToCourseList = (template: number | null, navigate: (path: string) => void) => {
  const targetPage = template === 3 ? PageLink.COURSE_3_LEVEL : PageLink.COURSE_4_LEVEL
  navigate(`${targetPage}`)
}
