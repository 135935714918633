import { Col, Collapse, Row, Space } from 'antd'
import clsx from 'clsx'
import { useTeachingProgramContext } from 'src/context/user/teacher/TeachingProgramContext'
import { PROGRAM } from 'src/type/courses'
import PriorityBadge from './PriorityBadge'
import styles from './styles.module.scss'
import FolderEmpty from 'src/common/CustomIcons/FolderEmpty'

const ViewProgram = () => {
  const { data, program } = useTeachingProgramContext()
  if (Object.values(data.pages[0].teacher_teachables).length === 0) {
    return (
      <div className={styles.emptySubject}>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <FolderEmpty />
          <span className='text-center text-gray-1000 mt-5'>
            This instructor has not taught any subjects in this program.
          </span>
          <span className='text-center text-gray-1000'>Please add more subjects</span>
        </div>
      </div>
    )
  } else {
    return (
      <Space direction='vertical' size={'middle'} className='w-100'>
        {data?.pages.flatMap((page) => {
          if (program === PROGRAM.CFA || program === PROGRAM.CMA) {
            return Object.entries(page.teacher_teachables).map(([subject, rows]) => {
              return (
                <Collapse
                  expandIconPosition={'end'}
                  className={clsx(styles.collapse)}
                  key={subject}
                  defaultActiveKey={[subject]}
                  items={[
                    {
                      key: subject,
                      label: <span className={styles.collapseHeader}>{subject}</span>,
                      children: (
                        <>
                          {rows?.map((section) => (
                            <Row
                              className='my-6 w-100'
                              justify={'space-between'}
                              gutter={26}
                              key={section.feature_section_id}
                            >
                              <Col span={9}>
                                <span className={clsx('flex-grow-1')}>
                                  {section?.feature_section?.name}
                                </span>
                              </Col>
                              <Col span={15}>
                                <PriorityBadge
                                  className={clsx('flex-grow-1', styles.badge)}
                                  priorityStatus={section?.priority_status}
                                />
                              </Col>
                            </Row>
                          ))}
                        </>
                      ),
                    },
                  ]}
                />
              )
            })
          }
          if (program === PROGRAM.ACCA || program === PROGRAM.CERT_DIP) {
            const featuresSections = Object.values(page.teacher_teachables)
              .flatMap((entries) =>
                entries.map((entry) => ({
                  feature_section: entry.feature_section,
                  priority_status: entry.priority_status,
                }))
              )
              .filter((item) => item.feature_section)
            return (
              <Collapse
                expandIconPosition={'end'}
                className={clsx(styles.collapse)}
                key={program}
                defaultActiveKey={[program]}
                items={[
                  {
                    key: program,
                    label: <span className={styles.collapseHeader}>{program}</span>,
                    children: featuresSections.map((section) => (
                      <div key={section.feature_section.id}>
                        <Row className='my-3 w-100' justify={'space-between'} gutter={26}>
                          <Col span={9}>
                            <span className={clsx('flex-grow-1')}>
                              {section?.feature_section?.name}
                            </span>
                          </Col>
                          <Col span={15}>
                            <PriorityBadge
                              className={clsx('flex-grow-1', styles.badge)}
                              priorityStatus={section?.priority_status}
                            />
                          </Col>
                        </Row>
                      </div>
                    )),
                  },
                ]}
              />
            )
          }
        })}
      </Space>
    )
  }
}

export default ViewProgram
