import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { ITabs } from 'src/type'
import { CacheTable } from 'src/components/caches/components/table/CacheTable'
import { useEffect, useState } from 'react'
import { CacheAPI } from 'src/apis/cache'
import { ICache } from 'src/type/cache'
import { Metadata } from 'src/type/common'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.CACHE}`,
    title: 'Clear Cache',
  },
]

const CachePage = () => {
  const [caches, setCaches] = useState<ICache[]>([])
  const [loading, setLoading] = useState(true)
  const [metadata, setMetadata] = useState<Metadata>({
    page_index: 1,
    page_size: 10,
    total_pages: 0,
    total_records: 0,
  })

  useEffect(() => {
    getCacheList()
  }, [])

  const setData = (data: ICache[]) => {
    setCaches(data)
  }

  const getCacheList = async (page_index = 1, page_size = 10) => {
    setLoading(true)
    try {
      const { data } = await CacheAPI.getCacheList({ page_index, page_size })
      setCaches(data.caching_setttings)
      setMetadata(data.metadata)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <PageLayouts pageTitle='Clear Cache' breadcrumbs={breadcrumbs}>
      <div className='card'>
        <CacheTable
          loading={loading}
          data={caches}
          metadata={metadata}
          fetchList={getCacheList}
          setData={setData}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(CachePage)
