import { fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import {
  IFAQ,
  IFAQDetail,
  IFAQList,
  IFAQResponse,
  IFaqCategory,
  IFaqCategoryCreate,
  IFaqCategoryDetail,
  IFaqCategoryList,
  IQuestion,
  IQuestionFAQ,
  IQuestionFAQList,
  QuestionList,
} from 'src/type/shop/faqs'
import { IProductCategory } from 'src/type/shop/product'

export class FaqsAPI {
  // FAQ Category
  static getFaqCategories({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IFaqCategoryList>> {
    return fetcher(`/shop-faqs/categories?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }

  static getFaqCategory(id: string): Promise<IResponse<IFaqCategoryDetail>> {
    return fetcher(`/shop-faqs/categories/${id}`)
  }

  static createFaqCategory({
    data,
  }: {
    data: IFaqCategoryCreate
  }): Promise<IResponse<IFaqCategory>> {
    return fetcher('/shop-faqs/categories', { method: 'POST', data: data })
  }

  static updateFaqCategory({
    id,
    data,
  }: {
    id: string
    data: IFaqCategoryCreate
  }): Promise<IResponse<IFaqCategory>> {
    return fetcher(`/shop-faqs/categories/${id}`, { method: 'PUT', data: data })
  }

  static deleteFaqCategory(id: string): Promise<IResponse<IProductCategory>> {
    return fetcher(`/shop-faqs/categories/${id}`, { method: 'DELETE' })
  }

  static deleteBulkFaqCategory(shopFaqCategoryIds: string[]): Promise<IResponse<IProductCategory>> {
    return fetcher('/shop-faqs/categories/bulk-delete', {
      method: 'POST',
      data: { shopFaqCategoryIds },
    })
  }

  // FAQ Api
  static getFaqs({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IFAQList>> {
    return fetcher(`/shop-faqs?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }

  static getFaq(id: string): Promise<IResponse<IFAQDetail>> {
    return fetcher(`/shop-faqs/${id}`, { method: 'GET' })
  }

  static createFAQ(data: IFAQ): Promise<IResponse<IFAQResponse>> {
    return fetcher('/shop-faqs', { method: 'POST', data: data })
  }

  static editFAQ(id: string, data: IFAQ): Promise<IResponse<IFAQResponse>> {
    return fetcher(`/shop-faqs/${id}`, { method: 'PUT', data: data })
  }

  static deleteBulkFaq(shopFaqIds: string[]): Promise<IResponse<IProductCategory>> {
    return fetcher('/shop-faqs/bulk-delete', {
      method: 'POST',
      data: { shopFaqIds },
    })
  }

  static deleteFaq(id: string): Promise<IResponse<IFAQResponse>> {
    return fetcher(`/shop-faqs/${id}`, { method: 'DELETE' })
  }
  // FAQ Question
  static getQuestionFaqList({
    faqId,
    page_index,
    page_size,
    params,
  }: {
    faqId: string
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IQuestionFAQList>> {
    return fetcher(
      `/shop-faqs/${faqId}/questions?page_index=${page_index}&page_size=${page_size}`,
      {
        params,
      }
    )
  }

  static createQuestionFAQ(faqId: string, data: IQuestion): Promise<IResponse<IQuestionFAQ>> {
    return fetcher(`/shop-faqs/${faqId}/questions`, { method: 'POST', data: data })
  }

  static editQuestionFAQ(
    faqId: string,
    questionId: string,
    data: IQuestion
  ): Promise<IResponse<IQuestionFAQ>> {
    return fetcher(`/shop-faqs/${faqId}/questions/${questionId}`, { method: 'PUT', data: data })
  }

  static deleteQuestionFAQ(faqId: string, questionId: string): Promise<IResponse<IQuestionFAQ>> {
    return fetcher(`/shop-faqs/${faqId}/questions/${questionId}`, { method: 'DELETE' })
  }

  static getQuestionList({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<QuestionList>> {
    return fetcher(`/shop-faqs/questions?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }
}
