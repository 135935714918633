import { Spin, Tooltip } from 'antd'
import clsx from 'clsx'
import { groupBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { MY_PROFILE, TEACHER_PROFILE, teacherKeys } from 'src/constants'
import { useTeacherContext } from 'src/context/user/teacher/TeacherContext'
import { TeachingProgramProvider } from 'src/context/user/teacher/TeachingProgramContext'
import { Program } from 'src/type'
import { TeachingProgramMode as Mode, UpdateTeachingPrograms } from 'src/type/teacher'
import { GroupedTeacherTeachableRes, TeacherTeachableRes } from 'src/type/users/teacher'
import EditProgram from './EditProgram'
import ViewProgram from './ViewProgram'

const ProgramTab = ({ program }: { program: Program }) => {
  const DEFAULT_PAGESIZE = 100
  const { id } = useParams()
  const { teacherDetail } = useTeacherContext()
  const [emptySubject, setEmptySubject] = useState<boolean>(true)
  const [isPrimaryCategory, setIsPrimaryCategory] = useState<boolean | undefined>()
  const [mode, setMode] = useState<Mode>(Mode.VIEW)
  const location = useLocation()

  const { data, isSuccess, isLoading } = useInfiniteQuery<
    TeacherTeachableRes,
    unknown,
    GroupedTeacherTeachableRes
  >({
    queryKey: teacherKeys.all(id, program),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await UsersAPI.getTeacherTeachable(pageParam, DEFAULT_PAGESIZE, id, {
        course_category_name: program,
        sort: {
          'feature_section.name': 'ASC',
          'subject.name': 'ASC',
        },
      })

      return res
    },
    getNextPageParam: (lastPage) => {
      if (lastPage) {
        return lastPage.metadata.page_index < lastPage.metadata.total_pages
          ? lastPage.metadata.page_index + 1
          : false
      }
    },
    enabled: !!id && !!program,
    select: useCallback((data: InfiniteData<TeacherTeachableRes>) => {
      const grouped = {
        pageParams: data.pageParams,
        pages: data.pages.map((page) => ({
          ...page,
          teacher_teachables: groupBy(page.teacher_teachables, (item) => item.subject.name),
        })),
      }
      return grouped
    }, []),
  })

  const methods = useForm<UpdateTeachingPrograms>({
    // resolver: zodResolver(schema),
    defaultValues: {
      category_primary_id: teacherDetail?.category_primary_id,
    },
  })
  const { control } = methods
  const teacherTeachableInstances = useWatch({
    control,
    name: 'teacher_teachable_instances', // Name of the field you want to watch
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccess) {
      setIsPrimaryCategory(data.pages[0].course_category_id === teacherDetail?.category_primary_id)
      setEmptySubject(Object.values(data.pages[0].teacher_teachables).length === 0)
    }
  }, [program, mode, teacherDetail])

  useEffect(() => {
    const baseUrl = `${TEACHER_PROFILE}/${id}/${MY_PROFILE.TEACHING_PROGRAMS}/${program
      ?.replace('/', '-')
      ?.toLowerCase()}`

    if (location.pathname === baseUrl) {
      setMode(Mode.VIEW)
      return
    }
    if (location.pathname === baseUrl + '/edit') {
      setMode(Mode.EDIT)
      return
    }
  }, [location, program])

  const currentInstancesLength = teacherTeachableInstances
    ?.flat()
    .map((i) => i.feature_sections)
    .flat()
    .filter((i) => i.feature_section_id).length
  return (
    <div
      className={clsx(`px-9`, {
        'py-7': mode === Mode.VIEW,
        'py-9': mode === Mode.EDIT,
      })}
    >
      {isLoading && <Spin className='mx-auto' />}
      {isSuccess && (
        <>
          <div className='d-flex justify-content-between mb-7'>
            <Tooltip
              title={mode === Mode.EDIT ? 'One subject must be selected.' : ''}
              color='white'
            >
              <div className='d-flex align-items-center gap-3'>
                {(mode === Mode.EDIT || !emptySubject) && (
                  <Controller
                    name='category_primary_id'
                    control={control}
                    render={({ field }) => {
                      return (
                        <>
                          <SAPPRadio
                            id='category_primary_id'
                            checked={isPrimaryCategory}
                            onChange={() => {
                              mode === Mode.EDIT && setIsPrimaryCategory(true)

                              field.onChange(data.pages[0].course_category_id)
                            }}
                            {...(mode === Mode.VIEW ? { readOnly: true, disabled: true } : {})}
                            disabled={currentInstancesLength === 0}
                            readOnly={mode === Mode.VIEW}
                            className={clsx({
                              'cursor-pointer': mode === Mode.EDIT,
                            })}
                          />
                          <label
                            {...(mode === Mode.EDIT && {
                              htmlFor: 'category_primary_id',
                            })}
                            className={clsx({
                              'cursor-pointer': mode === Mode.EDIT,
                              'text-gray-1000': currentInstancesLength === 0,
                            })}
                          >
                            Primary Responsibility
                          </label>
                        </>
                      )
                    }}
                  />
                )}
              </div>
            </Tooltip>
            {mode === Mode.VIEW && (
              <ButtonIconPrimary
                title={emptySubject ? `Add ${program}'s subjects` : `Edit ${program}'s subjects`}
                onClick={() =>
                  navigate(
                    `${TEACHER_PROFILE}/${id}/${MY_PROFILE.TEACHING_PROGRAMS}/${program
                      .replace('/', '-')
                      .toLowerCase()}/edit`
                  )
                }
                size='small'
                iconName={emptySubject ? 'plus' : ''}
              />
            )}
          </div>
          <TeachingProgramProvider
            value={{
              data: data,
              program: program,
              mode: mode,
              isPrimaryCategory: isPrimaryCategory,
            }}
          >
            {mode === Mode.VIEW && <ViewProgram />}
            {mode === Mode.EDIT && (
              <FormProvider {...methods}>
                <EditProgram />
              </FormProvider>
            )}
          </TeachingProgramProvider>
        </>
      )}
    </div>
  )
}

export default ProgramTab
