import clsx from 'clsx'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ExamAPI } from 'src/apis/exams'
import SappBaseTable from 'src/common/SappBaseTable'
import ActionCell from 'src/components/base/action/ActionCell'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectSubject from 'src/components/base/select/HookFormSelectSubject'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { useConfirm } from 'src/hooks/use-confirm'
import { ExamRouteParams } from 'src/type'
import { getProgramLevel } from 'src/utils'
import { removeEmptyKey } from 'src/utils/removeEmptyKey'
import useChecked from '../../../../hooks/use-checked'
import ButtonDanger from '../../../base/button/ButtonDanger'
import SAPPCheckbox from '../../../base/checkbox/SAPPCheckbox'
import styles from '../../ExamList/ExamList.module.scss'
import ExamDeferModal from './ExamDeferModal'
import { headers } from './headers'
import {
  examDateRegisteredSelect,
  ExamStudentsFilters,
  ExamStudentsList,
  IExamStudentsFilters,
  Student,
} from './types'

const ExamStudentsListTab = () => {
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState(false)
  const [currentStudent, setCurrentStudent] = useState<Student>()
  const { confirm, contextHolder } = useConfirm()
  const { handleSubmit, control, watch, reset } = useForm<IExamStudentsFilters>()
  const { id, program } = useParams<ExamRouteParams>()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  if (!id) {
    return <p>Error: Missing exam ID.</p>
  }

  const { data, isLoading, refetch, isFetching } = useQuery<ExamStudentsList>({
    queryKey: ['ListExamStudents', pageIndex, pageSize, isReset],
    queryFn: () => {
      const filteredFilter = removeEmptyKey(filters)
      return ExamAPI.getExamStudents({
        exam_id: id!,
        params: {
          ...filteredFilter,
          page_index: pageIndex,
          page_size: pageSize,
        },
      })
    },
    retry: 1,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!id,
  })
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    data?.data?.data
  )

  const { mutate: markAsRegister, isLoading: isMarkingLoading } = useMutation({
    mutationFn: (examIds: string[]) =>
      ExamAPI.markRegisteredExamDates({
        exam_id: id!,
        data: {
          class_user_ids: examIds,
        },
      }),
    onSuccess: (res) => {
      const errorData = res?.data?.data

      if (errorData && errorData?.length !== 0) {
        toast.error(
          <div className={styles.deleteErrorContainer}>
            {errorData.map(
              (error: { data: string[]; error_message: string }, index: number) =>
                error.data.length > 0 && (
                  <div key={index} className={styles.deleteError}>
                    <p>Error: {error.error_message}</p>
                    <p>Exam: {error.data.join(', ')}</p>
                  </div>
                )
            )}
          </div>,
          {
            duration: 5000,
          }
        )
      } else {
        toast.success(res.data.message)
      }

      void queryClient.invalidateQueries('ListExamStudents')
      toggleCheckAll(false)
    },
    onError: () => {
      toast.error("There's something wrong")
    },
  })
  const handlePaginationChange = (page_index: number, page_size: number) => {
    setPageIndex(page_index)
    setPageSize(page_size)
    setIsReset(false)
  }

  const filters: IExamStudentsFilters = watch()

  const onSubmit: SubmitHandler<IExamStudentsFilters> = () => {
    void refetch()

    setIsReset(false)
  }

  const handleReset = () => {
    const emptyFilters: Partial<IExamStudentsFilters> = Object.keys(watch()).reduce((acc, key) => {
      acc[key as keyof IExamStudentsFilters] = undefined // Explicit type assertion
      return acc
    }, {} as Partial<IExamStudentsFilters>)
    reset(emptyFilters)
    navigate({
      pathname: location.pathname,
    })
    setPageIndex(1)
    setPageSize(10)
    setIsReset(true)
  }

  const handleRegister = (id: string | string[]) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: `Are you sure you want to set ${
        Array.isArray(id) && id.length > 1 ? 'these exams' : 'this exam'
      } as officially registered? Once confirmed, students will no longer be able to make changes.`,
      onClick: () => (Array.isArray(id) ? markAsRegister(id) : markAsRegister([id])),
      isMoreDescription: true,
    })
  }

  return (
    <div className='pt-8'>
      {/* Filters */}
      {contextHolder}
      <div>
        <ListFilterLayout className='px-9'>
          <HookFormTextField
            control={control}
            name={ExamStudentsFilters.text}
            placeholder='Search by name, email or phone number'
            defaultValue={filters?.text}
          />
          <HookFormTextField
            control={control}
            name={ExamStudentsFilters.classCode}
            placeholder='Class Code'
            defaultValue={filters?.class_code}
          />
          <HookFormSelectSubject
            control={control}
            filters={filters}
            isListLoading={isLoading}
            program={program}
          />
          <HookFormSelectAntd
            name={ExamStudentsFilters.registeredExamDate}
            control={control}
            placeholder='Registered Exam Date'
            showSearch
            defaultValue={
              filters?.is_final_examination_subject === ''
                ? undefined
                : filters.is_final_examination_subject
            }
            options={examDateRegisteredSelect}
          />
        </ListFilterLayout>
        <div className={'card-header border-0 mt-5 mb-0'}>
          <div className='d-flex'>
            <ButtonSecondary
              title={'Reset'}
              className={`me-4`}
              onClick={handleReset}
              type='button'
            />
            <ButtonPrimary title={'Search'} onClick={handleSubmit(onSubmit)} loading={isLoading} />
          </div>
          <div className={clsx('d-flex justify-content-between', styles.createDeleteButtons)}>
            {/* Don't show upload button when there selected items */}
            {checkedList?.length > 0 && (
              <>
                <div className='fw-bold sapp-checkbox-text-custom me-5 pt-5'>
                  <span>{checkedList?.length}</span> Selected
                </div>
                <ButtonDanger
                  className={'fs-base sapp-h-40px'}
                  title={'Set as registered exam date'}
                  onClick={() => handleRegister(checkedList)}
                  loading={isMarkingLoading}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {/* Table */}
      <div className='position-relative'>
        <SappBaseTable
          headers={headers}
          loading={isLoading || isFetching}
          handlePaginationChange={handlePaginationChange}
          classNameTable='max-w-none'
          dataResponse={data?.data}
          data={data?.data?.data}
          showPagination={3}
          hasCheck={true}
          isCheckedAll={isCheckedAll}
          onChange={() => toggleCheckAll(!isCheckedAll, true)}
        >
          {data?.data?.data?.map((student) => {
            const isChecked = checkedList && checkedList.includes(student.id)
            return (
              <tr key={student?.id}>
                <td className='min-w-50px'>
                  <SAPPCheckbox
                    checked={isChecked}
                    ktCheck={isChecked}
                    onChange={() => {
                      toggleCheck(student?.id)
                    }}
                  />
                </td>
                {/* Name */}
                <td>{student?.user?.detail?.full_name ?? '-'}</td>
                {/* Email */}
                <td>{student?.user?.user_contacts?.[0]?.email ?? '-'}</td>
                {/* Phone */}
                <td>{student?.user?.user_contacts?.[0]?.phone ?? '-'}</td>
                {/* Class (code) */}
                <td>{student?.class?.code ?? '-'}</td>
                {/* Level */}
                <td className='text-center'>
                  {program && getProgramLevel(program)
                    ? student?.user?.detail?.[getProgramLevel(program)] || '-'
                    : '-'}
                </td>
                {/* Registered Exam Date */}
                <td className='text-center'>
                  {student?.is_final_examination_subject ? 'Yes' : 'No'}
                </td>
                {/* Defer */}
                <td className='text-center'>
                  {student?.defer_examination_reason === null ? 'No' : 'Yes'}
                </td>
                {/* Reason */}
                <td className={'text-center'}>{student?.defer_examination_reason || '-'}</td>
                <td>
                  <ActionCell>
                    {student?.is_final_examination_subject === false && (
                      <div className='menu-item px-3' onClick={() => handleRegister(student?.id)}>
                        <div className='menu-link px-3'>Set as registered exam date</div>
                      </div>
                    )}
                    {student.examination_subject_id && student.is_final_examination_subject && (
                      <div
                        className='menu-item px-3'
                        onClick={(e) => {
                          setCurrentStudent(student)
                          setOpenModal(true)
                        }}
                      >
                        <div className='menu-link px-3'>Defer</div>
                      </div>
                    )}
                  </ActionCell>
                </td>
              </tr>
            )
          })}
        </SappBaseTable>
        {currentStudent && (
          <ExamDeferModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            student={currentStudent}
          />
        )}
      </div>
    </div>
  )
}

export default ExamStudentsListTab
