import { memo, useEffect, useState } from 'react'
import { CacheAPI } from 'src/apis/cache'
import LoadingTable from 'src/common/LoadingTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappDrawer from 'src/components/base/SappDrawer'
import SappTable from 'src/components/base/SappTable'
import { HEADER_CACHE_LIST } from 'src/constants'
import {
  DEACTIVE_POPUPCONFIRM,
  DESCRIPTION_POPUPCONFIRM,
  REFRESH_POPUPCONFIRM,
} from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { ICache } from 'src/type/cache'
import { Metadata } from 'src/type/common'
import { convertMsToMinutes, convertSToMinutes } from 'src/utils/time'
import './CacheModal.scss'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

interface Iprops {
  open: boolean
  setOpen: any
  data?: ICache
  trigger?: any
}

const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Key',
    className: 'min-w-350px pe-5 ',
  },
  {
    label: 'Ttl',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Action',
    className: 'text-end min-w-30px pe-5',
  },
]

const default_metadata = {
  page_index: 1,
  page_size: 10,
  total_pages: 0,
  total_records: 0,
}

const CacheModal = ({ open, setOpen, data, trigger }: Iprops) => {
  const [loading, setLoading] = useState(false)
  const [thirdLoading, setThirdLoading] = useState(true)
  const [showThirdBtn, setShowThirdBtn] = useState(false)
  const [keys, setKeys] = useState([])
  const [metadata, setMetadata] = useState<Metadata>(default_metadata)
  const { confirm, contextHolder } = useConfirm()

  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        setLoading(false)
        setThirdLoading(false)
        setOpen({})
      },
    })
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    if (data) {
      getDetailCachingKeys(data?.id, page_index, page_size)
    }
  }

  const getPrefixData = async () => {
    if (data) {
      const result = await CacheAPI.getDetailPrefix(data.id)
      setShowThirdBtn(result.data.status === 'ENABLED')
    }
  }

  const getDetailCachingKeys = async (id: string, page_index = 1, page_size = 10) => {
    setLoading(true)
    setThirdLoading(true)
    try {
      const result = await CacheAPI.getDetailCachingKeys({
        id,
        page_index,
        page_size,
      })

      if (result) {
        setKeys(result?.data?.keys || [])
        setMetadata(result?.data?.metadata)
      }
    } catch (error) {
    } finally {
      setLoading(false)
      setThirdLoading(false)
    }
  }

  const removeAction = (key: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: async () => {
        await CacheAPI.deleteSpecificKey(key)
        if (data) {
          await getDetailCachingKeys(data?.id, 1, 10)
        }
      },
    })
  }

  const changeCacheStatus = async (id: string) => {
    setThirdLoading(true)
    try {
      await CacheAPI.changeCacheStatus(id)
    } catch (error) {
    } finally {
      setThirdLoading(false)
      setOpen({})
      trigger()
    }
  }

  const clearCaching = async (id: string) => {
    setLoading(true)
    try {
      await CacheAPI.deleteCachingViaPrefix(id)
    } catch (error) {
    } finally {
      setLoading(false)
      setKeys([])
      setMetadata(default_metadata)
    }
  }

  const handleDisable = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DEACTIVE_POPUPCONFIRM],
      onClick: async () => {
        if (data) {
          await changeCacheStatus(data?.id)
        }
      },
    })
  }

  const handleClear = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [REFRESH_POPUPCONFIRM],
      onClick: () => {
        if (data) {
          clearCaching(data.id)
        }
      },
    })
  }
  useEffect(() => {
    if (data) {
      getDetailCachingKeys(data?.id, 1, 10)
      getPrefixData()
    }
  }, [data])

  return (
    <SappDrawer
      rootClassName='sapp-question_essay-drawer'
      open={open}
      title={data?.endpoint || ''}
      cancelButtonCaption={'Cancel'}
      okButtonCaption={'Clear'}
      handleSubmit={handleClear}
      handleClose={handleClose}
      width='55%'
      loading={loading}
      footerClassName='border-top-0'
      thirdOnClick={handleDisable}
      showThirdBtn={showThirdBtn}
      thirdButtonCaption='Disable'
      thirdButtonLoading={thirdLoading}
    >
      {contextHolder}
      <div id='modal-information'>
        <div className='descriptiton d-flex'>
          <p className='w-40p description-text'>Description:</p>
          <p className='description-value'>{data?.description}</p>
        </div>
        <div className='ttl d-flex'>
          <p className='w-40p description-text'>TTL</p>
          <p className='description-value'>{convertMsToMinutes(data?.ttl || 0)}m</p>
        </div>
      </div>
      <div className='border-content' />
      <SappTable
        headers={headers}
        loading={loading}
        data={keys}
        onChange={() => {}}
        hasCheck={false}
        classResponsive={'px-10'}
      >
        {loading ? (
          <>
            {HEADER_CACHE_LIST.map((header, i) => (
              <LoadingTable key={header} headers={headers} />
            ))}
          </>
        ) : (
          <>
            {keys.map((e: any, index: number) => {
              return (
                <tr className='position-relative' key={e.key}>
                  <td>{index + 1}</td>
                  <td className='text-start'>
                    <span className='text-gray-800 cursor-pointer text-hover-primary'>{e.key}</span>
                  </td>
                  <td className='text-start sapp-text-light-inverse'>
                    {convertSToMinutes(e.ttl || 0)}m
                  </td>
                  <td className='text-end sapp-text-light-inverse pe-5'>
                    <div className='d-flex justify-content-end'>
                      <ButtonSecondary
                        title='Remove'
                        onClick={() => removeAction(e.key)}
                        size='small'
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <div className='px-10'>
        <PagiantionSAPP
          currentPage={metadata.page_index}
          pageSize={metadata.page_size || 10}
          totalItems={metadata.total_records}
          handlePaginationChange={handlePaginationChange}
        />
      </div>
    </SappDrawer>
  )
}
export default memo(CacheModal)
