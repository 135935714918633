import { IconBaseProps } from '@ant-design/icons/lib/components/Icon'

const FolderEmpty = (props: IconBaseProps) => {
  return (
    <span {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='114' height='122' fill='none'>
        <path
          fill='#F1F3F9'
          stroke='#D6DCE8'
          strokeMiterlimit='10'
          stroke-width='2'
          d='M54.192 120.991c28 0 50.7-22.7 50.7-50.8 0-28.1-22.7-50.8-50.7-50.8-28 0-50.7 22.7-50.7 50.8 0 28.1 22.7 50.8 50.7 50.8Z'
        />
        <path
          fill='#F1F3F9'
          stroke='#D6DCE8'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M16.892 37.29h72.1c2.9 0 5.1 2.2 5.1 5.1v45.7c0 2.9-2.2 5.1-5.1 5.1h-72.1c-2.9 0-5.1-2.2-5.1-5.1v-45.7c0-2.9 2.4-5.1 5.1-5.1Z'
        />
        <path
          stroke='#1676EE'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='3'
          d='M95.492 66.59v22.5c0 2.9-2.3 5.3-5.3 5.3h-74.1c-2.9 0-5.3-2.4-5.3-5.3v-24.4'
        />
        <path
          fill='#fff'
          d='M89.192 53.99h-23.4c-2.3 0-4.5.8-6.2 2.2l-6.1 5c-1.7 1.4-3.9 2.2-6.2 2.2h-26.4c-2.7 0-4.9 2.2-4.9 4.9 0 .2 0 .5.1.7l4.9 25.8c.4 2.4 2.4 4.2 4.9 4.2h56.5c2.4 0 4.5-1.7 4.9-4.1l6.8-35.3c.5-2.7-1.3-5.1-4-5.6h-.9Z'
        />
        <path
          fill='#fff'
          stroke='#D6DCE8'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M99.693 47.09h-30.4c-3 0-5.8 1-8.1 2.9l-8 6.5c-2.2 1.8-5.1 2.9-8.1 2.9h-34.4c-3.5 0-6.3 2.9-6.3 6.3 0 .3 0 .6.1.9l6.3 33.7c.5 3.2 3.2 5.5 6.3 5.5h73.6c3.2 0 5.8-2.2 6.3-5.4l8.9-46.1c.6-3.5-1.7-6.6-5.2-7.3-.3.1-.7.1-1 .1Z'
        />
        <path
          fill='#AAB2C5'
          d='M40.892 81.99c1.8 0 3.3-1.5 3.3-3.3 0-1.8-1.5-3.3-3.3-3.3-1.8 0-3.3 1.5-3.3 3.3 0 1.8 1.5 3.3 3.3 3.3ZM66.592 81.89c1.8 0 3.3-1.5 3.3-3.3 0-1.8-1.5-3.3-3.3-3.3-1.8 0-3.3 1.5-3.3 3.3 0 1.9 1.5 3.3 3.3 3.3Z'
        />
        <path
          fill='#D6DCE8'
          stroke='#AAB2C5'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M104.592 103.491c-.4 1.7-1 3.5-1.7 5-1.9 3.7-4.9 6.6-8.6 8.5-3.8 1.9-8.3 2.7-12.8 1.7-10.6-2.2-17.4-12.6-15.2-23.2 2.2-10.6 12.5-17.5 23.1-15.2 3.8.8 7.1 2.7 9.9 5.3 4.7 4.7 6.7 11.5 5.3 17.9Z'
        />
        <path
          fill='#fff'
          stroke='#AAB2C5'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M91.692 97.79h-4.5v-4.5c0-.9-.7-1.7-1.7-1.7-.9 0-1.7.7-1.7 1.7v4.5h-4.5c-.9 0-1.7.7-1.7 1.7s.7 1.7 1.7 1.7h4.5v4.5c0 .9.7 1.7 1.7 1.7.9 0 1.7-.7 1.7-1.7v-4.5h4.5c.9 0 1.7-.7 1.7-1.7s-.8-1.7-1.7-1.7Z'
        />
        <path
          stroke='#D6DCE8'
          strokeDasharray='4 4'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M49.692 58.29c-3.6-7-4.4-15.4-2-23 2.3-7.6 7.8-14.1 14.6-17.8 2.1-1.1 4.5-2 6.9-2.1 2.4-.1 5 .7 6.6 2.7 1.6 1.8 1.9 4.8.6 6.8-1.4 1.9-4.2 2.7-6.5 2.1-3.7-.7-6.7-3.6-7.6-7.1-.9-3.5.3-7.6 3.1-9.9 1.8-1.6 4.3-2.5 6.6-3.2 11.2-3.3 23.4-3.7 34.8-1.2'
        />
        <path fill='#AAB2C5' d='M56.892 85.49h-6.2v1.5h6.2v-1.5Z' />
        <path
          fill='#F1F3F9'
          stroke='#D6DCE8'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M112.792 2.99c-.1 1.5-.2 2.9-1.3 3.2-1.1.3-1.6-.7-2.3-2.1-.7-1.3-.3-2.7.9-3 1.1-.3 2.9.1 2.7 1.9ZM111.593 10.19c.3-1.8.6-2.8-.4-3.3-1.1-.5-1.8.4-3 1.6-1 1.1-.4 2.7.6 3.2 1.2.6 2.5 0 2.8-1.5Z'
        />
        <path
          fill='#F1F3F9'
          d='M112.992 6.89c-.1.7-.6 1.2-1.3 1.3h-1c-1.4-.2-2.5-1.1-2.4-2 .1-.9 1.4-1.4 3-1.2.3 0 .6.1.8.2.6.2 1 .9.9 1.7 0 0 0-.1 0 0Z'
        />
        <path
          stroke='#D6DCE8'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M112.992 6.89c-.1.7-.6 1.2-1.3 1.3h-1c-1.4-.2-2.5-1.1-2.4-2 .1-.9 1.4-1.4 3-1.2.3 0 .6.1.8.2.6.2 1 .9.9 1.7Zm0 0c0-.1 0 0 0 0Z'
        />
        <path
          fill='#F1F3F9'
          stroke='#D6DCE8'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M1.393 34.99c0-1.7 0-3.4 1.2-3.9 1.3-.5 2 .7 3 2.4.9 1.5.5 3.1-.8 3.6-1.1.5-3.4.2-3.4-2.1ZM2.092 26.59c-.2 2.1-.5 3.3.8 3.8s2-.6 3.3-2.2c1-1.4.3-3.2-1-3.7s-2.9.4-3.1 2.1Z'
        />
        <path
          fill='#F1F3F9'
          stroke='#D6DCE8'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M.992 30.59c0-.8.6-1.4 1.3-1.5.3-.1.7-.1 1.1 0 1.6.1 3 1 2.9 2-.1 1-1.4 1.7-3.1 1.5-.3 0-.6-.1-.9-.2-.8-.1-1.3-.9-1.3-1.8Z'
        />
        <path
          stroke='#D6DCE8'
          strokeDasharray='4 4'
          stroke-miterlimit='10'
          stroke-width='2'
          d='M7.792 30.89c10.5 0 29.9 6.1 30.2 28.5'
        />
      </svg>
    </span>
  )
}

export default FolderEmpty
