import Modal from 'react-bootstrap/Modal'

interface IProps {
  title: string
  open: boolean
  onClose?: () => void
  children: React.ReactNode
  handleOk?: () => void
  handleCancel?: () => void
  loading?: boolean
  className?: string
  centered?: boolean
  size?: 'sm' | 'lg' | 'xl'
}

const PopupViewer = ({
  title,
  open,
  onClose,
  children,
  handleOk,
  loading = false,
  className,
  centered = true,
  size,
}: IProps) => {
  return (
    <Modal
      show={open}
      onHide={onClose}
      title={title}
      open={open}
      onOk={handleOk}
      onCancel={onClose}
      loading={loading}
      className={`custom-modal ${className ?? ''}`}
      centered={centered}
      size={size}
    >
      <Modal.Header closeButton>
        <Modal.Title id='example-custom-modal-styling-title'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  )
}

export default PopupViewer
