import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PageLayouts from 'src/components/layout/PageLayouts'
import { useCourseContext } from 'src/context/CourseProvider'
import '../course-detail/Tree.scss'
import CreateAndUpdateCourseInfo from 'src/pages/courses/CreateAndUpdateCourseInfo'
import CourseCertificate from '../course-detail/certificate/CourseCertificate'
import CourseResource from '../course-detail/course-resource'
import CourseContentMedium from './course-medium-content'
import { TStep } from '../../../type/course-medium/section'

const CourseDetailMedium = () => {
  const { setId } = useCourseContext()
  const { id } = useParams()
  const navigate = useNavigate() // hook để điều hướng lập trình
  const [searchParams, setSearchParams] = useSearchParams() // hook để lấy và cập nhật các
  const [newStep, setNewStep] = useState<TStep[]>([
    {
      title: 'Course Info',
      status: 'current',
    },
    {
      title: 'Course Content',
      status: '',
    },
    {
      title: 'Resource',
      status: '',
    },
    {
      title: 'Certificate',
      status: '',
    },
  ])

  useEffect(() => {
    if (id) {
      setId(id as string)
      // set default page bằng tham số page
      if (searchParams.has('page')) {
        const index = Number(searchParams.get('page'))
        setNewStep((step: TStep[]) => {
          const _new = step.map((e, idx) => {
            if (index > idx) {
              return { ...e, status: 'active' }
            }
            if (index === idx) {
              return { ...e, status: 'current' }
            }
            return { ...e, status: '' }
          })
          return _new
        })
        searchParams.delete('page')
        setSearchParams(searchParams)
        navigate('.', { replace: true })
      }
    }
  }, [id])

  const handleGotoStep = (index: number) => {
    const _new = newStep.map((e: TStep, idx: number) => {
      if (index > idx) {
        return { ...e, status: 'active' }
      }
      if (index === idx) {
        return { ...e, status: 'current' }
      }
      return { ...e, status: '' }
    })
    setNewStep(_new)
  }

  return (
    <PageLayouts
      breadcrumbs={[]}
      pageTitle=''
      showSideBar={false}
      titleTab='Course'
      classNameLayout='pt-15'
      removePadding={true}
      showFooter={false}
      backgroudColor='bg-white'
    >
      <div className='py-6'>
        {newStep[0].status === 'current' && (
          <CreateAndUpdateCourseInfo setStep={setNewStep} step={newStep} />
        )}
        {newStep[1].status === 'current' && (
          <CourseContentMedium
            setStep={setNewStep}
            step={newStep}
            handleGotoStep={handleGotoStep}
          />
        )}
        {newStep[2].status === 'current' && (
          <CourseResource setStep={setNewStep} step={newStep} handleGotoStep={handleGotoStep} />
        )}
        {newStep[3].status === 'current' && (
          <CourseCertificate setStep={setNewStep} step={newStep} />
        )}
      </div>
    </PageLayouts>
  )
}
export default CourseDetailMedium
