export enum TEACHING_STATUS {
  'TRIAL' = 'Giảng thử',
  'TRAINING' = 'Đang Training',
  'SCHEDULING' = 'Đặt lịch giảng dạy',
  'TEACHING' = 'Đang dạy',
  'HIATUS' = 'Tạm ngừng dạy',
  'STOP_SCHEDULING' = 'Dừng đặt lịch',
  'END_COOPERATION' = 'Ngừng hợp tác',
}

// Convert enum to array of objects with label and value
export const teachingStatusArray = Object.entries(TEACHING_STATUS).map(([key, value]) => ({
  label: value,
  value: key,
}))

export enum TEACHER_PRIORITY_STATUS {
  HIGH_PRIORITY = 'HIGH_PRIORITY',
  MEDIUM_PRIORITY = 'MEDIUM_PRIORITY',
  NON_PRIORITY = 'NON_PRIORITY',
  NO_LESSON_PLAN = 'NO_LESSON_PLAN',
}

export const TEACHER_PRIORITY_STATUS_LABEL = {
  HIGH_PRIORITY: 'Ưu tiên 1',
  MEDIUM_PRIORITY: 'Ưu tiên 2',
  NON_PRIORITY: 'Không ưu tiên',
  NO_LESSON_PLAN: 'Chưa có giáo án',
}

// Map enum values to custom labels
export const TEACHER_PRIORITY_OPTIONS = [
  { label: 'Ưu tiên 1', value: TEACHER_PRIORITY_STATUS.HIGH_PRIORITY },
  { label: 'Ưu tiên 2', value: TEACHER_PRIORITY_STATUS.MEDIUM_PRIORITY },
  { label: 'Không ưu tiên', value: TEACHER_PRIORITY_STATUS.NON_PRIORITY },
  { label: 'Chưa có giáo án', value: TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN },
]
