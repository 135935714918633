import { zodResolver } from '@hookform/resolvers/zod'
import { debounce } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { EntranceApi } from 'src/apis/entrance-test'
import { SubjectAPI } from 'src/apis/subject'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ModalImportClasses from 'src/components/classes/ModalImportClasses'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import {
  FILTER_SELECTALL_SORTBY,
  PageLink,
  VALIDATE_DIFFICULTY,
  VALIDATE_NUMBER,
  VALIDATION_FIELD,
} from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { useInfinitePrograms } from 'src/hooks/useInfiniteQueryHooks'
import { ITabs } from 'src/type'
import { ISubject, ISubjectList } from 'src/type/subject'
import { cleanParamsAPI, formatDate } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'
import TableParticipant from './tableParticipant'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: PageLink.ENTRANCE_TEST_LIST,
    title: 'Entrance Tests',
  },
  {
    link: '',
    title: 'Participants',
  },
]
const fieldNames = ['text', 'course_category_id', 'subject_id', 'quiz_id', 'fromDate', 'toDate']
const initialValues: any = {
  text: '',
  subject_id: '',
  course_category_id: '',
  course_level_id: '',
  quiz_id: '',
  sortType: '',
  fromDate: '',
  toDate: '',
  quizType: 'ENTRANCE_TEST',
  dateField: 'updated_at',
}
const ParticipantList = () => {
  const [EntranceTestList, setEntranceTestList] = useState<any>({
    participants: [
      {
        quiz: {},
      },
    ],
    metadata: {},
  })
  const [openImportClass, setOpenImportClass] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState('')
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(search)
  const [queryParamsChanged, setQueryParamsChanged] = useState(false)

  const queryParams = {
    text: searchParams.get('text') ?? '',
    course_category_id: searchParams.get('course_category_id') ?? '',
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    quiz_id: searchParams.get('quiz_id'),
    subject_id: searchParams.get('subject_id'),
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }
  const validationSchema = z.object({
    text: z.string(),
    course_category_id: z.string().optional(),
    quiz_id: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    subject: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(/^[0-9]*$/, VALIDATE_NUMBER)
      .transform((val) => parseInt(val))
      .refine((val) => val >= 1, { message: VALIDATE_DIFFICULTY })
      .refine((val) => val <= 50, { message: VALIDATE_DIFFICULTY })
      .or(z.number().int(VALIDATE_NUMBER).min(1).max(50)),
  })
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: getValues('full_name'),
      course_category_id: replaceValueAll(getValues('course_category_id')),
      subject_id: replaceValueAll(getValues('subject_id')) ?? '',
      quiz_id: replaceValueAll(getValues('quiz_id')) ?? '',
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    text: queryParams.text ?? '',
    course_category_id: queryParams.course_category_id ?? '',
    subject_id: queryParams.subject_id ?? '',
    quiz_id: queryParams.quiz_id ?? '',
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
  })
  const getParams = (
    text: string,
    course_category_id: string,
    subject_id?: string,
    quiz_id?: string,
    sortType?: string,
    fromDate?: any,
    toDate?: any,
    quizType?: any,
    dateField?: string
  ) => ({
    text,
    course_category_id,
    subject_id,
    quiz_id,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    quizType,
    dateField,
  })
  const { toggleCheckAll } = useChecked<any>(EntranceTestList?.classes)
  useEffect(() => {
    fetchParticipantList(1, queryParams.page_size || 10)
  }, [])
  useEffect(() => {
    if (queryParamsChanged) {
      fetchParticipantList(1, queryParams.page_size || 10)
      setQueryParamsChanged(false)
    }
  }, [queryParamsChanged])

  const fetchParticipantList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await EntranceApi.getAPI({
        page_index: currentPage,
        page_size: pageSize,
        params: {
          ...params, // Mở rộng các prop từ đối tượng params
          quizType: 'ENTRANCE_TEST',
          dateField: 'updated_at',
        } as Record<string, any>,
      })
      setEntranceTestList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
    return
  }
  const onSubmit = () => {
    const nameValue = getValues('full_name')
    const trimmedName = nameValue ? nameValue.trimStart().trimEnd() : ''
    const fromDateValue = getValues('fromDate')
    const toDateValue = getValues('toDate')
    const fromDateParam = fromDateValue
      ? typeof fromDateValue === 'string'
        ? fromDateValue
        : fromDateValue.toISOString()
      : ''

    const toDateParam = toDateValue
      ? typeof toDateValue === 'string'
        ? toDateValue
        : toDateValue.toISOString()
      : ''

    const cleanedParams = cleanParamsAPI(
      getParams(
        trimmedName,
        replaceValueAll(getValues('course_category_id')),
        replaceValueAll(getValues('subject_id')),
        replaceValueAll(getValues('quiz_id')),
        replaceValueAll(getValues('sortType')),
        fromDateParam,
        toDateParam
      )
    )

    setLoading(true)

    fetchParticipantList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
  }

  const fetchEntranceTest = async (name: string, page_index: number, page_size: number) => {
    try {
      if (!entranceTest) {
        const res = await EntranceApi.get({
          page_index: page_index,
          page_size: page_size,
          params: {
            name,
            quizType: 'ENTRANCE_TEST',
          },
        })
        setEntranceTest(res?.data)
      } else {
        const res = await EntranceApi.get({
          page_index: page_index,
          page_size: page_size,
          params: {
            name,
            quizType: 'ENTRANCE_TEST',
          },
        })
        const newData = res?.data?.quizzes || []
        setEntranceTest((prevState: { quizzes: any; meta: any }) => ({
          ...prevState,
          quizzes: [...prevState.quizzes, ...newData],
          meta: res?.data?.meta || prevState.meta,
        }))
      }
    } catch (error) {
      console.error('Error fetching entrance test:', error)
    }
  }

  /**
   * @description state lưu giá trị của subject
   */

  const [subjects, setSubjects] = useState<ISubjectList>()
  const requestOngoingRef = useRef(false)

  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await SubjectAPI.getSubjects(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.subjects.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  const getSubjects = async ({ params }: any) => {
    const resMentor = await fetchSubjects(1, 20, params)
    setSubjects(resMentor?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    getSubjects({
      params: {
        name: e,
        ...(watch('course_category_id') && { course_category_id: watch('course_category_id') }),
      },
    })
  }, 500)

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
      navigate(PageLink.PARTICIPANTS_LIST)
    })
    setLoading(true)
    fetchParticipantList(1, 10)
    setSelectedOption('')
    setSubjects(undefined)
    setEntranceTest({ meta: { page_index: 1, page_size: 10 }, quizzes: [] })
  }
  const [entranceTest, setEntranceTest] = useState<any>()

  const newEntranceTest = useMemo<{ label: string; value: string }[]>(
    () =>
      entranceTest?.quizzes.map((test: any) => ({
        label: test.name,
        value: test.id,
      })),
    [entranceTest]
  )

  const debouncedGetEntranceTest = debounce((e) => {
    fetchEntranceTest(e, 1, 10)
  }, 200)
  const handleNextPage = (e: any) => {
    const total_pages = entranceTest.meta?.total_pages
    const page_index = entranceTest.meta?.page_index
    const page_size = entranceTest.meta?.page_size
    if (total_pages) {
      if (page_index && page_index < total_pages) {
        fetchEntranceTest(e, page_index + 1, page_size)
      }
    }
  }
  const debouncedHandleNextPage = debounce(handleNextPage, 200)
  useEffect(() => {
    const savedLabel = localStorage.getItem('selectedOptionLabel')
    if (savedLabel) {
      setSelectedOption(savedLabel)
    }
  }, [])

  const handleSelectChange = (value: any, option: any) => {
    localStorage.setItem('selectedOptionLabel', option.children)
    setSelectedOption(option.children)
  }
  useEffect(() => {
    if ((selectedOption && entranceTest?.quizzes.length === 0) || !entranceTest) {
      fetchEntranceTest(selectedOption, 1, queryParams.page_size || 10)
    }
    return
  }, [selectedOption])

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('subject_id', '')
    getSubjects({
      params: {
        ...(watch('course_category_id') && { course_category_id: watch('course_category_id') }),
      },
    })
  }

  const {
    programs,
    refetch: refetchPrograms,
    hasNextPage: hasNextPageProgram,
    fetchNextPage: fetchNextPageProgram,
    debounceSearch,
  } = useInfinitePrograms()

  return (
    <PageLayouts pageTitle='Participants of All Entrance Tests' breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className=' px-9 pt-9 w-100'>
          <ListFilterLayout>
            <HookFormTextField
              control={control}
              name='full_name'
              placeholder='Search participant name'
              defaultValue={queryParams?.text}
              onSubmit={onSubmit}
              isListScreen
            />

            <HookFormSelectAntd
              control={control}
              name='course_category_id'
              placeholder='Program'
              defaultValue={queryParams.course_category_id ?? ''}
              onSearch={(text) => {
                debounceSearch(text)
              }}
              showSearch
              classNameHeight='sapp-h-40'
              handleNextPage={hasNextPageProgram && fetchNextPageProgram}
              onChange={handleCourseCategoryChange}
              onDropdownVisibleChange={(open) => {
                if (open && !programs) {
                  refetchPrograms()
                  return
                }
              }}
              options={
                programs?.map((program) => ({ label: program.name, value: program.id })) ?? []
              }
            />

            <HookFormSelectAntd
              control={control}
              name='subject_id'
              placeholder='Subject'
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                debounceSearchSubject(e)
              }}
              handleNextPage={(e: any) =>
                handleNextPageSubject({
                  name: e,
                  course_category_id: watch('course_category_id'),
                })
              }
              showSearch
              classNameHeight='sapp-h-40'
              loading={loading}
              defaultValue={queryParams?.subject_id ?? ''}
              options={subjectCourse ?? []}
            />

            <HookFormSelectAntd
              control={control}
              name='quiz_id'
              placeholder='Entrance Test'
              defaultValue={queryParams.quiz_id ?? ''}
              showSearch
              handleNextPage={debouncedHandleNextPage}
              onFocus={async () => {
                if (entranceTest?.length <= 0 || !entranceTest) {
                  setEntranceTest({ meta: { page_index: 1, page_size: 10 }, quizzes: [] })
                  await fetchEntranceTest('', 1, 10)
                  return
                }
                fetchEntranceTest('', 1, 10)
              }}
              onSearch={async (e) => {
                debouncedGetEntranceTest(e)
                setEntranceTest({ meta: { page_index: 1, page_size: 10 }, quizzes: [] })
                return
              }}
              className='w-100'
              onSelect={handleSelectChange}
              classNameHeight='sapp-h-40'
              options={
                newEntranceTest
                  ?.filter((e) => e.label !== '')
                  ?.map((e) => ({
                    label: e.label,
                    value: e.value,
                  })) ?? []
              }
            />

            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort by'
              defaultValue={queryParams?.sortType ?? ''}
              control={control}
              classNameHeight='sapp-h-40'
              options={FILTER_SELECTALL_SORTBY}
            />

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />

            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-6 '>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
            />
          </div>
        </div>
        <TableParticipant
          EntranceTestList={EntranceTestList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchParticipantList={fetchParticipantList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          toggleCheckAll={toggleCheckAll}
        />
        <ModalImportClasses
          open={openImportClass}
          setOpen={setOpenImportClass}
        ></ModalImportClasses>
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(ParticipantList)
