import { Dayjs } from 'dayjs'
import { debounce } from 'lodash'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { Control, UseFormSetValue, UseFormWatch, useWatch } from 'react-hook-form'
import { ClassesApi } from 'src/apis/classes'
import { SubjectAPI } from 'src/apis/subject'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import FacilitySelect from 'src/components/base/select/common/FacilitySelect'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import { FILTER_SELECTALL_SORTBY } from 'src/constants'
import {
  OPTIONS_OF_CONTRUCTION_MODE,
  OPTIONS_OF_STATUS_FILTER,
  OPTIONS_OF_TYPE_CLASS_LIST,
} from 'src/constants/classes'
import { useInfinitePrograms } from 'src/hooks/useInfiniteQueryHooks'
import { IMeta } from 'src/type'
import { ISubject, ISubjectList } from 'src/type/subject'

interface IProps {
  control: Control<any, any>
  queryParams: Record<string, any>
  onSubmit: () => void
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
}

interface IClassCode {
  meta: IMeta
  class_codes: Array<{ code: string; id: string }>
}

const FilterClassList = ({ control, queryParams, onSubmit, watch, setValue }: IProps) => {
  const [classCodes, setClassCodes] = useState<IClassCode>()
  // goi API class code
  const fetchClassCodes = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await ClassesApi.getCodes(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  // gọi tiếp codes nếu kéo hết scroll
  const handlNextPageCodes = async (params: Object) => {
    const totalPages = classCodes?.meta?.total_pages
    const pageIndex = classCodes?.meta?.page_index as number
    const pageSize = classCodes?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchClassCodes(pageIndex + 1, pageSize, params)
      const results = classCodes.class_codes.concat(res?.data?.class_codes)
      setClassCodes({
        meta: res?.data?.meta,
        class_codes: results,
      })
    }
  }

  const getCodes = async ({ params }: any) => {
    const resMentor = await fetchClassCodes(1, 20, params)
    setClassCodes(resMentor?.data)
  }

  // call search codes
  const debounceSearchCodes = debounce((e) => {
    getCodes({ params: { search_key: e } })
  }, 500)

  const newCourseCodes = classCodes?.class_codes?.map((group: { code: string; id: string }) => ({
    label: group.code,
    value: group.code,
  }))
  // gọi lại API show full class code
  useEffect(() => {
    if (queryParams.class_code) {
      getCodes({ is_get_all: true })
    }
  }, [queryParams.class_code])

  const [subjects, setSubjects] = useState<ISubjectList>()
  const requestOngoingRef = useRef(false)

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    getSubjects({
      params: {
        name: e,
        ...(watch('course_category_id') && { course_category_id: watch('course_category_id') }),
      },
    })
  }, 500)

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))
  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await SubjectAPI.getSubjects(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.subjects.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }
  const courseCategoryId = useWatch({ control, name: 'course_category_id' })
  const getSubjects = async ({ params }: { params?: Object }) => {
    const resMentor = await fetchSubjects(1, 20, params)
    setSubjects(resMentor?.data)
  }
  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('subject_id', '')

    getSubjects({
      params: {
        ...(watch('course_category_id') && { course_category_id: watch('course_category_id') }),
      },
    })
  }

  const disabledFromDate = (current: Dayjs) => {
    const toDate = watch('toDate')
    if (toDate) {
      return current > moment(toDate).endOf('day')
    }
    return false
  }

  const disabledToDate = (toDate: Dayjs) => {
    const fromDate = watch('fromDate')
    if (fromDate) {
      return toDate < moment(fromDate).startOf('day')
    }
    return false
  }

  useEffect(() => {
    if (courseCategoryId === '') {
      setSubjects(undefined)
    }
  }, [courseCategoryId])

  const {
    programs,
    refetch: refetchPrograms,
    hasNextPage: hasNextPageProgram,
    fetchNextPage: fetchNextPageProgram,
    debounceSearch,
  } = useInfinitePrograms()

  return (
    <div className='pt-10 px-10'>
      <ListFilterLayout>
        <HookFormTextField
          control={control}
          name='class_name'
          placeholder='Search by name and code'
          defaultValue={queryParams?.class_name}
          onSubmit={onSubmit}
          isListScreen
        />

        {/* end:: Search */}

        <HookFormSelectAntd
          classNameHeight='sapp-h-40'
          control={control}
          name='class_code'
          placeholder='Code'
          defaultValue={queryParams.class_code ?? ''}
          showSearch
          onFocus={async () => {
            if (!newCourseCodes) {
              await getCodes({})
              return
            }
          }}
          onSearch={(e: any) => {
            if (e === undefined) {
              return
            }
            debounceSearchCodes(e)
          }}
          handleNextPage={(e: any) => handlNextPageCodes({ params: { search_key: e } })}
          options={newCourseCodes ?? []}
        />

        <HookFormSelectAntd
          control={control}
          name='course_category_id'
          placeholder='Program'
          defaultValue={queryParams.course_category_id ?? ''}
          showSearch
          onSearch={(text) => {
            debounceSearch(text)
          }}
          classNameHeight='sapp-h-40'
          onChange={handleCourseCategoryChange}
          handleNextPage={hasNextPageProgram && fetchNextPageProgram}
          onDropdownVisibleChange={(open) => {
            if (open && !programs) {
              refetchPrograms()
              return
            }
          }}
          options={programs?.map((program) => ({ label: program.name, value: program.id })) ?? []}
        />

        <HookFormSelectAntd
          control={control}
          name='subject_id'
          placeholder='Subject'
          onSearch={(e: any) => {
            if (e === undefined) {
              return
            }
            debounceSearchSubject(e)
          }}
          handleNextPage={(e: any) =>
            handleNextPageSubject({
              name: e,
              ...(courseCategoryId !== '' && { course_category_id: courseCategoryId }),
            })
          }
          showSearch
          classNameHeight='sapp-h-40'
          defaultValue={queryParams?.subject_id ?? ''}
          onFocus={async () => {
            if (!subjectCourse) {
              await getSubjects({})
              return
            }
          }}
          options={subjectCourse ?? []}
        />

        <HookFormSelectAntd
          control={control}
          name='status'
          placeholder='Status'
          defaultValue={queryParams.status ?? ''}
          classNameHeight='sapp-h-40'
          options={OPTIONS_OF_STATUS_FILTER}
        />

        <FacilitySelect control={control} name='facility_id' placeholder='Facility' />

        <HookFormSelectAntd
          size='large'
          control={control}
          name='instruction_mode'
          placeholder='Construction Mode'
          defaultValue={queryParams.instruction_mode ?? ''}
          classNameHeight='sapp-h-40'
          options={OPTIONS_OF_CONTRUCTION_MODE}
        />

        <HookFormSelectAntd
          size='large'
          control={control}
          name='class_type'
          placeholder='Type'
          defaultValue={queryParams.class_type ?? ''}
          classNameHeight='sapp-h-40'
          options={OPTIONS_OF_TYPE_CLASS_LIST}
        />

        <HookFormSelectAntd
          name='sortType'
          placeholder='Sort by'
          control={control}
          size='large'
          defaultValue={queryParams?.sortType ?? ''}
          classNameHeight='sapp-h-40'
          options={FILTER_SELECTALL_SORTBY}
        />

        <HookFormDateTime
          control={control}
          name='fromDate'
          placeholder='From date'
          defaultValue={queryParams?.fromDate}
          disabledDate={disabledFromDate}
          isListScreen
        />

        <HookFormDateTime
          control={control}
          name='toDate'
          placeholder='To date'
          defaultValue={queryParams?.toDate}
          disabledDate={disabledToDate}
          isListScreen
        />
      </ListFilterLayout>
    </div>
  )
}

export default FilterClassList
