import { Dispatch, SetStateAction } from 'react'

export interface Metadata {
  page_index: number
  page_size: number
  total_pages: number
  total_records: number
}

export type Sort = 'ASC' | 'DESC'

export interface ICommonProp {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  checkedList: string[]
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, memorize?: boolean) => void
  isCheckedAll: boolean
  pageSize?: number
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number, params?: Object) => void
}
export interface ISelect {
  label: string
  value: string
}
export interface ICommonAddress {
  code: string
  name: string
  name_with_type: string
  slug: string
  type: string
}
export interface IProvinceList {
  metadata: Metadata
  provinces: ICommonAddress[]
}

export interface IDistrictList {
  metadata: Metadata
  districts: ICommonAddress[]
}

export interface IWardList {
  metadata: Metadata
  wards: ICommonAddress[]
}

export interface ICommonTable {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  filterParams: Object
  pageSize?: number
  isCheckedAll: boolean
}

export interface IUploadFile {
  resource_type: string
  name: string
  resource_id: string
  file_key: string
  suffix_type: string
  id: string
}

export type Program = 'ACCA' | 'CFA' | 'CMA' | 'Cert/Dip'
export enum FormType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}
