import React, { useState } from 'react'
import SappBaseTable from 'src/common/SappBaseTable'
import { IClassQuiz, IClassQuizes, QuizData } from 'src/type/classes'
import { convertQuizType, formatDateTime } from 'src/utils'
import ActionCell from 'src/components/base/action/ActionCell'
import ModalTestQuizDetail from './ModalTestQuizDetail'
import { isNull, isUndefined } from 'lodash'
import SappTooltip from 'src/common/SappTooltip'
import AccessTimeModal from './AccessTimeModal'
import { ClassesApi } from 'src/apis/classes'
import toast from 'react-hot-toast'
import { IAccessTime } from './TableTestQuizDetail'
import { useParams } from 'react-router-dom'
import { useConfirm } from 'src/hooks/use-confirm'
import ExcelJS, { Worksheet } from 'exceljs'
import { applyCellStyle, convertChar, getRangeOfCells } from 'src/utils/excel-helper'

const headers = [
  {
    label: '#',
  },
  {
    label: 'Test Name',
    className: 'min-w-350px',
  },
  {
    label: 'Type',
    className: 'min-w-150px',
  },
  {
    label: 'Mode',
    className: 'min-w-100px',
  },
  {
    label: 'Thời gian mở',
    className: 'min-w-150px',
  },
  {
    label: 'Thời hạn nộp',
    className: 'min-w-150px',
  },
  {
    label: 'Đã làm',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Manual Grading',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Đã chấm',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Thời hạn chấm',
    className: 'min-w-150px',
  },
  {
    label: '',
  },
]

interface IProps {
  quizDetail: IClassQuiz | undefined
  loading: boolean
  fetchTestQuiz: (page_index: number, page_size: number, params?: Object) => void
  filterTestQuiz: Object
  isDuration: boolean
  class_code: string | undefined
}

const TableTestQuiz = ({
  quizDetail,
  loading,
  fetchTestQuiz,
  filterTestQuiz,
  isDuration,
  class_code,
}: IProps) => {
  const [dataQuiz, setDataQuiz] = useState<IClassQuizes>()
  const { id } = useParams()

  const defaultAccessTime = {
    isOpen: false,
    id: '',
    defaultValue: {
      start_time: new Date(),
      end_time: new Date(),
    },
    showTestQuiz: true,
  }
  const [openResult, setOpenResult] = useState(false)
  const [loadingAccessTime, setLoadingLoadingAccessTime] = useState(false)
  const [openAccessTime, setOpenAccessTime] = useState<IAccessTime>(defaultAccessTime)
  const [quizId, setQuizId] = useState({
    id: '',
    name: '',
    grading_method: 'AUTO',
  })
  const handleCloseAccessTime = () => setOpenAccessTime(defaultAccessTime)

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchTestQuiz(page_index || 1, page_size || 10, filterTestQuiz)
  }

  const handleOpenQuizDetail = (quiz: IClassQuizes) => {
    setOpenResult(true)
    setQuizId({
      id: quiz?.quiz?.id,
      name: quiz?.quiz?.name,
      grading_method: quiz?.quiz?.grading_method,
    })
  }

  const { confirm, contextHolder } = useConfirm()

  const handleDelete = async (quiz_id: string, class_id: string | undefined) => {
    try {
      await ClassesApi.deleteAccessTimeTestQuiz({ data: { quiz_id: quiz_id, class_id: class_id } })
      await fetchTestQuiz(1, 10)
      handleCloseAccessTime()
      toast.success('Update Access Time successfully!')
    } catch (err) {}
  }

  const handleDeleteAccessTime = (quiz_id: string, class_id: string | undefined) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xoá không?'],
      onClick: () => handleDelete(quiz_id, class_id),
    })
  }

  const updateAccessTime = async (data: {
    start_time: Date
    end_time: Date
    quiz_id: string | undefined
    class_id?: string | undefined
  }) => {
    setLoadingLoadingAccessTime(true)
    try {
      await ClassesApi.updateAccessTimeTestQuiz({ data: data })
      await fetchTestQuiz(1, 10)
      toast.success('Update Access Time successfully!')
    } catch (err) {
    } finally {
      handleCloseAccessTime()
      setLoadingLoadingAccessTime(false)
    }
  }

  const convertMixQuestionData = (data?: QuizData | undefined) => {
    // Lấy các giá trị cần thiết từ mỗi phần tử trong section_stat
    return data?.attempts?.map((attempt: any, index) => [
      index + 1,
      attempt?.user?.full_name,
      attempt?.user?.email,
      attempt?.attempt?.score,
      attempt?.attempt?.multiple_choice_score,
      attempt?.attempt?.essay_score,
      ...attempt?.answers?.map((section: any) => {
        if (section?.is_essay) {
          return section?.score // Nếu là bài luận, trả về điểm
        } else if (section?.is_correct) {
          return 'T' // Nếu câu trả lời đúng, trả về 'T'
        } else {
          return 'F' // Nếu câu trả lời sai, trả về 'F'
        }
      }),
      attempt?.grading_link,
    ])
  }

  const exportMixQuestionExcelTemplate = async (quiz_id: string, worksheet: Worksheet) => {
    const res = await ClassesApi.getClassQuizExport(quiz_id, id)
    const excelData = res?.data
    const additionalHeader = res?.data?.attempt_header
    const tongSection1 = convertChar('F', additionalHeader?.length)
    if (!isUndefined(excelData)) {
      // Dữ liệu cho bảng
      const data = [
        [
          'STT',
          'Họ tên',
          'Email',
          'Điểm',
          '',
          '',
          ...additionalHeader.map((_: any, index: number) => `Q ${index + 1}`),
          'Link bài làm tự luận',
        ],
        ['', '', '', 'Tổng điểm', 'Trắc nghiệm', 'Tự luận', ...additionalHeader, ''],
        convertMixQuestionData(excelData),
      ]

      const convertedData = data?.reduce((acc: any[], item: any) => {
        if (Array.isArray(item?.[0])) {
          return acc.concat(item)
        }
        acc.push(item)
        return acc
      }, [])

      // Thêm dữ liệu vào worksheet
      convertedData?.forEach((row) => {
        worksheet.addRow(row)
      })
      getRangeOfCells('F', tongSection1, '1').forEach((cellAddress) => {
        const cell = worksheet.getCell(cellAddress)
        applyCellStyle(cell, 'D8B9A8') // Màu nền
      })
      getRangeOfCells('F', tongSection1, '2').forEach((cellAddress) => {
        const cell = worksheet.getCell(cellAddress)
        applyCellStyle(cell, '9fc5e8') // Màu nền
      })
      // Gộp ô
      worksheet.mergeCells('A1:A2') // Gộp ô STT
      worksheet.mergeCells('B1:B2') // Gộp ô Họ tên
      worksheet.mergeCells('C1:C2') // Gộp ô Email
      worksheet.mergeCells('D1:F1') // Gộp ô Tổng điểm
      worksheet.mergeCells(
        `${convertChar('G', additionalHeader?.length)}1:${convertChar(
          'G',
          additionalHeader?.length
        )}2`
      ) // Gộp ô Link bài làm tự luận

      for (let row = 1; row <= 2; row++) {
        getRangeOfCells('A', 'C', row.toString()).forEach((cellAddress) => {
          const cell = worksheet.getCell(cellAddress)
          applyCellStyle(cell, 'efefef') // Màu nền
        })

        getRangeOfCells('D', 'F', row.toString()).forEach((cellAddress) => {
          const cell = worksheet.getCell(cellAddress)
          applyCellStyle(cell, 'ffe599') // Màu nền
        })
        getRangeOfCells(
          'G',
          convertChar('G', additionalHeader?.length - 1),
          row.toString()
        ).forEach((cellAddress) => {
          const cell = worksheet.getCell(cellAddress)
          applyCellStyle(cell, row === 1 ? 'd6b7a8' : 'CFE2F3') // Màu nền
        })
        getRangeOfCells(
          convertChar('G', additionalHeader?.length),
          convertChar('G', additionalHeader?.length),
          row.toString()
        ).forEach((cellAddress) => {
          const cell = worksheet.getCell(cellAddress)
          applyCellStyle(cell, 'CFE2F3') // Màu nền
        })
      }
      // Thay đổi style cho cột H từ hàng 3 trở xuống
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber >= 3) {
          // Bắt đầu từ hàng 3
          const cell = row.getCell('H') // Lấy cột H
          cell.font = {
            color: { argb: 'FFFF0000' }, // Màu chữ đỏ
          }
        }
      })

      // Cài đặt độ rộng cho tất cả các cột
      worksheet.columns = worksheet.columns.map((col) => ({
        ...col,
        width: 20, // Đặt độ rộng cho tất cả các cột (có thể thay đổi giá trị này)
      }))
    }
  }

  // Lấy các giá trị cần thiết từ mỗi phần tử trong section_stat
  const convertMultiChoiceDataToRow = (data: QuizData | undefined) => {
    return data?.section_stat?.map((stat, index) => [
      index + 1,
      stat?.user?.full_name,
      stat?.user?.email,
      stat?.section_stat?.total?.toString(),
      ...(stat?.section_stat?.section?.map((section) => section.toString()) ?? []), // Chuyển từng giá trị trong section thành chuỗi
      stat?.correct_stat?.total?.toString() ?? '',
      ...(stat?.correct_stat?.section?.map((section) => section.toString()) ?? []),
      stat?.percent_stat?.total?.toString() ?? '',
      ...(stat?.percent_stat?.section?.map((section) => section.toString()) ?? []),
    ])
  }

  const exportMultiChoiceExcelTemplate = async (quiz_id: string, worksheet: Worksheet) => {
    const res = await ClassesApi.getClassQuizExport(quiz_id, id)
    const excelData = res?.data
    const additionalHeader = res?.data?.section_header.map((item: { name: string }) => item.name)
    if (!isUndefined(excelData)) {
      // Dữ liệu cho bảng
      const data = [
        // Tiêu đề chính
        [
          'STT',
          'Họ tên',
          'Email',
          'Tổng số câu theo section',
          ...additionalHeader.map(() => ''),
          'Số câu đúng theo section',
          ...additionalHeader.map(() => ''),
          'Tỷ lệ % câu đúng theo section',
          ...additionalHeader.map(() => ''),
        ],
        // Hàng "Tổng"
        [
          '',
          'Tổng',
          '', // Email không cần cho tổng
          'Tổng', // Tổng số câu
          ...additionalHeader, // Section 1
          'Tổng', // Số câu đúng
          ...additionalHeader, // Section 1
          'Tổng', // Tỷ lệ %
          ...additionalHeader, // Section 1
        ],
        convertMultiChoiceDataToRow(excelData),
      ]

      const convertedData = data?.reduce((acc: any[], item: any) => {
        if (Array.isArray(item?.[0])) {
          return acc.concat(item)
        }
        acc.push(item)
        return acc
      }, [])

      // Thêm dữ liệu vào worksheet
      convertedData.forEach((row) => {
        worksheet.addRow(row)
      })

      // Gộp các ô
      worksheet.mergeCells('A1:A2') // Gộp "STT"
      worksheet.mergeCells('B1:B2') // Gộp "Họ tên"
      worksheet.mergeCells('C1:C2') // Gộp "Email"

      const startColumn = 4 // "Tổng số câu theo section" bắt đầu ở cột 4
      const sectionLength = additionalHeader.length
      // Hợp nhất các ô liên quan đến "Tổng số câu theo section"
      worksheet.mergeCells(1, startColumn, 1, startColumn + sectionLength)
      // Hợp nhất các ô liên quan đến "Số câu đúng theo section"
      worksheet.mergeCells(
        1,
        startColumn + sectionLength + 1,
        1,
        startColumn + 2 * sectionLength + 1
      )
      // Hợp nhất các ô liên quan đến "Tỷ lệ % câu đúng theo section"
      worksheet.mergeCells(
        1,
        startColumn + 2 * sectionLength + 2,
        1,
        startColumn + 3 * sectionLength + 2
      )
      // Tự động điều chỉnh độ rộng cột
      worksheet.columns.forEach((column) => {
        column.width = 15 // Đặt độ rộng cột mặc định (có thể thay đổi tùy ý)
      })

      // Thêm style cho các ô
      for (let row = 1; row <= 2; row++) {
        getRangeOfCells('A', 'C', row.toString()).forEach((cellAddress) => {
          const cell = worksheet.getCell(cellAddress)
          applyCellStyle(cell, 'efefef') // Màu nền
        })

        getRangeOfCells('D', convertChar('D', additionalHeader?.length), row.toString()).forEach(
          (cellAddress) => {
            const cell = worksheet.getCell(cellAddress)
            applyCellStyle(cell, 'efefef') // Màu nền
          }
        )

        getRangeOfCells(
          convertChar('D', additionalHeader?.length + 1),
          convertChar('D', additionalHeader?.length * 2 + 1),
          row.toString()
        ).forEach((cellAddress) => {
          const cell = worksheet.getCell(cellAddress)
          applyCellStyle(cell, 'FCE5CD') // Màu nền
        })
        getRangeOfCells(
          convertChar('D', additionalHeader?.length * 2 + 2),
          convertChar('D', additionalHeader?.length * 3 + 2),
          row.toString()
        ).forEach((cellAddress) => {
          const cell = worksheet.getCell(cellAddress)
          applyCellStyle(cell, '9fc5e8') // Màu nền
        })
      }
    }
  }

  const handleExportExcel = async (quiz_name: string, quiz_id: string) => {
    const workbook = new ExcelJS.Workbook()
    const worksheet1 = workbook.addWorksheet('Sheet1')
    const worksheet2 = workbook.addWorksheet('Sheet2')
    await exportMixQuestionExcelTemplate(quiz_id, worksheet1)
    await exportMultiChoiceExcelTemplate(quiz_id, worksheet2)
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${class_code}_${quiz_name}.xlsx`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <React.Fragment>
      {contextHolder}
      <SappBaseTable
        hasCheck={false}
        headers={headers}
        data={quizDetail?.class_quizzes}
        loading={loading}
        // currentPage={quizDetail?.metadata?.page_index || 1}
        // pageSize={quizDetail?.metadata?.page_size || 10}
        totalItems={quizDetail?.metadata?.total_records || 10}
        handlePaginationChange={handlePaginationChange}
        dataResponse={quizDetail}
      >
        {quizDetail?.class_quizzes?.map((quiz, index) => {
          return (
            <tr className='border-0' key={index}>
              <td>{index + 1}</td>
              <td className='cursor-pointer' onClick={() => handleOpenQuizDetail(quiz)}>
                <SappTooltip title={quiz?.quiz?.name} link='#' />
              </td>
              <td>{convertQuizType(quiz?.quiz?.quiz_type)}</td>
              <td>{quiz?.mode ?? '--'}</td>
              <td>{quiz?.start_time ? formatDateTime(new Date(quiz?.start_time)) : '--'}</td>
              <td>{quiz?.end_time ? formatDateTime(new Date(quiz?.end_time)) : '--'}</td>
              <td className='text-center'>{quiz?.total_attempts}</td>
              <td className='text-center'>
                {quiz?.quiz?.grading_method === 'AUTO'
                  ? 'No'
                  : isNull(quiz?.quiz?.grading_method)
                  ? '--'
                  : 'Yes'}
              </td>
              <td className='text-center'>{`${
                quiz.quiz?.grading_method === 'MANUAL'
                  ? `${quiz?.total_grading_attempts}/${quiz?.total_attempts}`
                  : '--'
              }`}</td>
              <td>
                {quiz?.due_date_grade ? formatDateTime(new Date(quiz?.due_date_grade)) : '--'}
              </td>
              <td className='text-end sapp-absolute-column sapp-classes-column'>
                <ActionCell>
                  <div className='menu-item px-3' onClick={() => handleOpenQuizDetail(quiz)}>
                    <div className='menu-link px-3'>Detail</div>
                  </div>

                  <div
                    className='menu-item px-3'
                    onClick={() => handleExportExcel(quiz?.quiz?.name, quiz?.quiz?.id)}
                  >
                    <div className='menu-link px-3'>Export Result</div>
                  </div>
                  {isDuration && (
                    <>
                      <div
                        className='menu-item px-3'
                        onClick={() => {
                          setOpenAccessTime({
                            ...defaultAccessTime,
                            isOpen: true,
                            id: quiz?.quiz?.id,
                            defaultValue: {
                              start_time: quiz.start_time ? new Date(quiz.start_time) : new Date(),
                              end_time: quiz.end_time ? new Date(quiz.end_time) : new Date(),
                            },
                          })
                          setDataQuiz(quiz)
                        }}
                      >
                        <div className='menu-link px-3'>Edit Access Time</div>
                      </div>
                      {!isNull(quiz?.start_time) && (
                        <div
                          className={`menu-item px-3 ${
                            !quiz.start_time && quiz.end_time
                              ? 'user-select-none pe-none opacity-50'
                              : ''
                          }`}
                          onClick={() => handleDeleteAccessTime(quiz?.quiz?.id, id)}
                        >
                          <div className='menu-link px-3'>Delete Access Time</div>
                        </div>
                      )}
                    </>
                  )}
                </ActionCell>
              </td>
            </tr>
          )
        })}
        <ModalTestQuizDetail
          open={openResult}
          setOpen={setOpenResult}
          quiz_id={quizId}
          startDate={quizDetail?.class_instance?.started_at}
          endDate={quizDetail?.class_instance?.finished_at}
          isDuration={isDuration}
          classId={id ?? ''}
        />
        <AccessTimeModal
          open={openAccessTime}
          onClose={() => setOpenAccessTime(defaultAccessTime)}
          onSaveTime={updateAccessTime}
          startDate={quizDetail?.class_instance?.started_at}
          endDate={quizDetail?.class_instance?.finished_at}
          defaultAccessTime={defaultAccessTime}
          dataQuiz={dataQuiz}
          loading={loadingAccessTime}
        />
      </SappBaseTable>
    </React.Fragment>
  )
}

export default TableTestQuiz
