import clsx from 'clsx'
import { ExplanationPackage } from 'explanation-package'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EntranceApi } from 'src/apis/entrance-test'
import styles from './explanation.module.scss'

export enum QUESTION_TYPES {
  TRUE_FALSE = 'TRUE_FALSE',
  ONE_CHOICE = 'ONE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  MATCHING = 'MATCHING',
  SELECT_WORD = 'SELECT_WORD',
  FILL_WORD = 'FILL_WORD',
  DRAG_DROP = 'DRAG_DROP',
  ESSAY = 'ESSAY',
}

const Explanation = () => {
  const { id } = useParams()
  const [activeQuestion, setActiveQuestion] = useState<any>()
  function getCorrect(answers: any, questionType: any) {
    switch (questionType as QUESTION_TYPES) {
      case QUESTION_TYPES.ONE_CHOICE:
      case QUESTION_TYPES.TRUE_FALSE:
        const correctAnswers = answers
        const corrects = Object.fromEntries(
          correctAnswers.map((answer: any) => [answer.id, answer.is_correct])
        )
        return corrects
      case QUESTION_TYPES.MULTIPLE_CHOICE:
        return Object.fromEntries(
          (answers || []).map((originalAnswer: any) => [
            originalAnswer.id,
            originalAnswer.is_correct,
          ])
        )
      case QUESTION_TYPES.FILL_WORD:
      case QUESTION_TYPES.SELECT_WORD:
        return answers || []
      case QUESTION_TYPES.MATCHING:
        return answers || []
      case QUESTION_TYPES.DRAG_DROP:
        return answers || []
      default:
        return {}
    }
  }
  const getActiveQuestion = async (id: string) => {
    // const quizAttempts = axiosInstance.get('')
    // const selectedResponseAnswers = data.data.selectedResponseAnswers
    try {
      const resultResponse = (await EntranceApi.getDataExplanation({
        id: id,
      })) as any
      setActiveQuestion({
        ...resultResponse.data.answer.question,
        active: resultResponse.data.answer.active,
        answer_file: resultResponse.data.answer.answer_file,
        confirmed: true,
        corrects: getCorrect(
          resultResponse.data.answer.question.qType !== QUESTION_TYPES.MATCHING
            ? resultResponse.data.answer.question.answers
            : resultResponse.data.answer.answer_matching_mapping,
          resultResponse.data.answer.question.qType
        ),
        question_matchings: resultResponse.data.answer.answer_matching_mapping,
        answers: resultResponse.data?.answer?.question.answers || [],
        myAnswers: [
          {
            question_id: resultResponse.data.answer.question.id,
            question_answer_id: resultResponse.data.answer.question_answer_id,
            answer: resultResponse.data.answer.answer,
          },
        ],
        defaultValue: resultResponse.data.answer.answer,
        next: resultResponse.data.next,
        previous: resultResponse.data.previous,
        total_question: resultResponse.data.total_question,
        index: resultResponse.data.index,
        answer_position_mapping: resultResponse?.data?.answer?.answer_position_mapping,
        short_answer: resultResponse?.data?.answer?.short_answer,
        response_option_answer: resultResponse?.data?.answer?.response_option,
      })
    } catch (err) {}
  }

  useEffect(() => {
    const element = document.getElementById('root') as HTMLElement
    if (element) {
      element.style.backgroundColor = 'white'
      element.style.height = '100%'
    }
    if (id) {
      getActiveQuestion(id as string)
    }
  }, [id])
  const navigate = useNavigate()
  const handleClose = () => {
    const router = localStorage.getItem('historyUrl')
    if (router) {
      navigate(router)
    } else {
      navigate(-1)
    }
  }
  //todo: call api, make UI
  // return <></>

  return (
    <div>
      <div className={clsx('position-absolute end-0 top-0 p-3', styles.close)}>
        <i
          className='ki-outline ki-cross fs-1'
          onClick={handleClose}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <ExplanationPackage
        getActiveQuestion={getActiveQuestion}
        activeQuestion={activeQuestion}
        document_id={''}
        handleDownload={() => {}}
      />
    </div>
  )
}
export default Explanation
