import { Col, Collapse, ConfigProvider, Row, Select } from 'antd'
import clsx from 'clsx'
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import { TEACHER_PRIORITY_OPTIONS, TEACHER_PRIORITY_STATUS } from 'src/constants/users'
import { ICourseCategory, SubjectInstance } from 'src/type/courses'
import { FeatureSection, ITeacher, TeacherTeachableInstanceForm } from 'src/type/teacher'
import styles from './TeacherPostForm.module.scss'

interface ISectionCheckbox {
  category: ICourseCategory
  index: number
  subject: SubjectInstance
  setValue: UseFormSetValue<ITeacher>
  control: Control<ITeacher, any>
  watch: UseFormWatch<ITeacher>
}
const SubjectCollapse = ({
  index,
  subject,
  setValue,
  watch,
  control,
  category,
}: ISectionCheckbox) => {
  const teacherTeachableInstancesForm = watch(`teacher_teachable_instances`)
  const category_primary_id = watch('category_primary_id')

  const extractMergedData = (
    data: TeacherTeachableInstanceForm[] | undefined
  ):
    | {
        feature_sections: FeatureSection[]
        category_id: string
      }
    | undefined => {
    const mergedFeatureSections = data?.reduce<{
      feature_sections: FeatureSection[]
      category_id: string
    }>(
      (acc, current) => {
        return {
          feature_sections: [...acc.feature_sections, ...current.feature_sections],
          category_id: current.category_id, // Assuming all entries have the same category_id
        }
      },
      { feature_sections: [], category_id: '' }
    )

    return mergedFeatureSections
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter',
        },
        components: {
          Select: {
            optionSelectedColor: '#99A1B7',
          },
        },
      }}
    >
      <Collapse
        defaultActiveKey={subject.id}
        expandIconPosition={'end'}
        className={clsx(styles.collapse)}
        style={{ backgroundColor: 'white', border: '1px solid #F1F1F4' }}
        items={[
          {
            key: subject.id,
            label: <span className={styles.strong}>{subject.name}</span>,
            children: (
              <div>
                {subject.feature_section_instances.map((section, section_index) => {
                  const featureSectionId = watch(
                    `teacher_teachable_instances.${index}.feature_sections.${section_index}.feature_section_id`,
                    ''
                  )

                  const featureSections = watch(
                    `teacher_teachable_instances.${index}.feature_sections`
                  )

                  return (
                    <Row
                      align={'middle'}
                      justify={'space-between'}
                      key={section.id}
                      className='w-100'
                      gutter={12}
                    >
                      <Col span={featureSectionId ? 16 : 24}>
                        <label
                          className={clsx('d-flex gap-2 cursor-pointer', styles.sectionCheckbox)}
                          htmlFor={section.id}
                        >
                          <Controller
                            name={`teacher_teachable_instances.${index}.feature_sections.${section_index}.feature_section_id`}
                            control={control}
                            render={({ field }) => {
                              const isChecked = field.value === section.id
                              return (
                                <SAPPCheckbox
                                  checked={isChecked}
                                  onChange={(e) => {
                                    const updatedValue = e.target.checked
                                      ? section.id // Add section ID
                                      : ''

                                    const selectedInstances = teacherTeachableInstancesForm?.filter(
                                      (instance) =>
                                        instance.category_id === category?.id &&
                                        instance.feature_sections?.length > 0
                                    )

                                    const extract = extractMergedData(selectedInstances)

                                    if (extract) {
                                      const isLastChecked =
                                        e.target.checked === false &&
                                        extract.feature_sections.filter(
                                          (item) =>
                                            item.feature_section_id &&
                                            item.feature_section_id !== ''
                                        ).length === 1 &&
                                        extract?.category_id === category_primary_id

                                      if (isLastChecked) {
                                        toast.error(
                                          `You cannot uncheck the last selected item of this Primary Responsibility`,
                                          {
                                            duration: 6000,
                                          }
                                        )
                                        return
                                      }
                                    }

                                    field.onChange(updatedValue) // Update form state
                                    if (updatedValue) {
                                      setValue(
                                        `teacher_teachable_instances.${index}.category_id`,
                                        category.id
                                      )
                                      setValue(
                                        `teacher_teachable_instances.${index}.subject_id`,
                                        section.subject_id
                                      )
                                      setValue(
                                        `teacher_teachable_instances.${index}.feature_sections.${section_index}.priority_status`,
                                        TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN
                                      )
                                    } else {
                                      if (
                                        featureSections.filter(
                                          (item) =>
                                            item.feature_section_id &&
                                            item.feature_section_id !== ''
                                        ).length === 0
                                      ) {
                                        setValue(
                                          `teacher_teachable_instances.${index}.category_id`,
                                          ''
                                        )
                                        setValue(
                                          `teacher_teachable_instances.${index}.subject_id`,
                                          ''
                                        )
                                      }
                                      setValue(
                                        `teacher_teachable_instances.${index}.feature_sections.${section_index}.priority_status`,
                                        ''
                                      )
                                    }
                                  }}
                                  id={section.id}
                                />
                              )
                            }}
                          />
                          <span className={clsx('flex-grow-1', styles.strong)}>{section.name}</span>
                        </label>
                      </Col>
                      {featureSectionId && (
                        <Col span={8}>
                          <Controller
                            name={`teacher_teachable_instances.${index}.feature_sections.${section_index}.priority_status`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <Select
                                  options={TEACHER_PRIORITY_OPTIONS}
                                  style={{ width: '100%' }}
                                  defaultValue={TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN}
                                  size='small'
                                  className={styles.select}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  )
                })}
              </div>
            ),
          },
        ]}
      />
    </ConfigProvider>
  )
}

export default SubjectCollapse
