import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PageLink, PARTICIPANT_ENUM } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import {
  cleanParamsAPI,
  convertString,
  formatDate,
  formatDateTime,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import ModalProcessingParticipant from 'src/components/entrance-test/modalProcess'
import { IParticipants } from 'src/type/participants'
import ActionCell from '../base/action/ActionCell'
import SappBaseTable from 'src/common/SappBaseTable'
import { useUserContext } from 'src/context/UserProvider'
import { hasPermission } from 'src/utils/permission'
import { TITLE_EVENT_TEST_GR } from 'src/constants/permission'

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  EntranceTestList: any
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>> | any
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchParticipantList: (currentPage: number, pageSize: number, params?: Object) => void | any
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList?: any
  toggleCheck?: any
  toggleCheckAll?: any
  isCheckedAll?: boolean
}

const TableEventParticipants = ({
  EntranceTestList,
  loading,
  // setLoading,
  fetchParticipantList,
  getParams,
  queryParams,
  isCheckedAll,
  toggleCheckAll,
}: IProps) => {
  const { contextHolder, confirm } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const navigate = useNavigate()
  const [openEditCourseContent, setOpenEditCourseContent] = useState<{
    status: boolean
    type?: string
    data?: any
  }>({ status: false })
  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.text ?? '',
      queryParams.course_category_id ?? '',
      queryParams.subject ?? '',
      queryParams.quiz_id ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      queryParams.quizType ?? 'ENTRANCE_TEST',
      queryParams.dateField ?? 'updated_at'
    )
  )

  // Using validate for input
  const { setValue } = useForm<any>()

  useEffect(() => {
    EntranceTestList?.participants
      ?.map((e: any) => ({ label: e.status, value: e.status }))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, EntranceTestList?.participants])

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: queryParams.text ?? '',
      course_category_id: queryParams?.course_category_id ?? '',
      subject: queryParams.subject ?? '',
      quiz_id: queryParams.quiz_id ?? '',
      sortType: queryParams.sortType ?? '',
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
      dateField: 'updated_at',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchParticipantList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  // define headers
  const headers = [
    {
      label: '#',
      className: 'min-w-30px pe-5',
    },
    {
      label: 'Participant Name',
      className: 'min-w-200px pe-5',
    },
    {
      label: 'Email',
      className: 'min-w-50px pe-5',
    },
    {
      label: 'Phone',
      className: 'min-w-50px pe-5',
    },
    {
      label: 'Event Test',
      className: 'min-w-200px pe-5',
    },
    {
      label: 'Phase',
      className: 'min-w-150px pe-5',
    },
    {
      label: 'Result',
      className: 'min-w-100px pe-5',
    },
    {
      label: 'Source',
      className: 'min-w-150px pe-5',
    },
    {
      label: 'Submit Time',
      className: 'min-w-150px pe-5',
    },
  ]

  const { profileMe } = useUserContext()
  const allowRenderGetPariticipant = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.GET_EVENT_TEST_PARTICIPANTS
  )
  const allowRenderGetEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.GET_EVENT_TEST
  )
  const allowRenderEditEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.EDIT_EVENT_TEST
  )

  return (
    <>
      {contextHolder}
      <SappBaseTable
        headers={headers}
        loading={loading}
        data={EntranceTestList?.participants}
        isCheckedAll={isCheckedAll}
        onChange={() => toggleCheckAll(!isCheckedAll, true)}
        hasCheck={false}
        handlePaginationChange={handlePaginationChange}
      >
        {EntranceTestList?.participants?.map((e: IParticipants, index: number) => {
          const hubspot_lifecycles_id = e?.hubspot_lifecycles?.map(
            (lifecycle) => lifecycle?.course_category_id
          )
          const quiz_id = e?.quiz?.course_category?.id
          const showStage = hubspot_lifecycles_id?.indexOf(quiz_id)
          let level
          switch (e.quiz?.course_category?.name) {
            case 'ACCA':
              level = e?.user_detail?.acca_level ?? '--'
              break
            case 'CFA':
              level = e?.user_detail?.cfa ?? '--'
              break
            case 'CMA':
              level = e?.user_detail?.cma_level ?? '--'
              break
            default:
              level = '--'
          }
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <div
                  className='text-gray-800 cursor-pointer text-hover-primary'
                  onClick={() =>
                    allowRenderGetPariticipant ||
                    allowRenderGetEventTest ||
                    allowRenderEditEventTest
                      ? navigate(`${PageLink.EVENT_PARTICIPANT_LIST}/result/${e?.id}`)
                      : {}
                  }
                >
                  {e.user_detail?.full_name}
                </div>
              </td>
              <td>{e.user_contacts?.email}</td>
              <td>{e.user_contacts?.phone}</td>
              <td>{e.quiz?.name}</td>
              <td>{e.quiz?.name}</td>

              <td className='text-start'>
                {e?.ratio_score ? (
                  <Link
                    className='sapp-text-truncate-1 text-hover-primary cursor-pointer text-gray-600'
                    to={
                      allowRenderGetPariticipant ||
                      allowRenderGetEventTest ||
                      allowRenderEditEventTest
                        ? `${PageLink.EVENT_PARTICIPANT_LIST}/result/${e?.id}`
                        : '#'
                    }
                  >
                    {e.ratio_score}
                  </Link>
                ) : (
                  '--'
                )}
              </td>
              <td className='text-start'>{convertString(e?.quiz_user_instances?.[0]?.source)}</td>
              <td>{e.finished_at ? formatDateTime(new Date(e.finished_at)) : '--'}</td>
              {(allowRenderGetPariticipant ||
                allowRenderGetEventTest ||
                allowRenderEditEventTest) && (
                <td className='text-end bg-white sapp-absolute-column'>
                  <ActionCell>
                    <div
                      className={`menu-item px-3 `}
                      onClick={() => navigate(`${PageLink.EVENT_PARTICIPANT_LIST}/result/${e?.id}`)}
                    >
                      <div className='menu-link px-3'>Participant Detail</div>
                    </div>
                  </ActionCell>
                </td>
              )}
            </tr>
          )
        })}
      </SappBaseTable>
      <ModalProcessingParticipant
        open={openEditCourseContent.status}
        setOpen={setOpenEditCourseContent}
        type={openEditCourseContent.type}
        title={
          openEditCourseContent.type === 'process' ? 'Learning Process' : 'Participants Detail'
        }
        id={openEditCourseContent?.data?.course_id}
        exceptedSections={openEditCourseContent?.data?.excepted_course_section || []}
        classId={openEditCourseContent?.data?.id}
        refetch={() =>
          fetchParticipantList(page_index || 1, page_size || 10, filterParamsPagination)
        }
        dataModal={openEditCourseContent?.data}
      />
    </>
  )
}

export default TableEventParticipants
