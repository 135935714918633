import { fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { IAuthor } from 'src/type/shop/author'
import { IProductCategoryCreate } from 'src/type/shop/product'
import { ITag, ITagList, ITagUpdate } from 'src/type/shop/tag'

export class TagAPI {
  static getTags({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<ITagList>> {
    return fetcher(`/shop-blogs/tags?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }

  static getTag(id: string): Promise<IResponse<ITag>> {
    return fetcher(`/shop-blogs/tags/${id}`)
  }

  static createTag({ data }: { data: IProductCategoryCreate }): Promise<IResponse<IAuthor>> {
    return fetcher('/shop-blogs/tags', { method: 'POST', data: data })
  }

  static updateTag({ id, data }: { id: string; data: ITagUpdate }): Promise<IResponse<ITag>> {
    return fetcher(`/shop-blogs/tags/${id}`, { method: 'PUT', data: data })
  }

  static deleteTag(id: string): Promise<IResponse<ITag>> {
    return fetcher(`/shop-blogs/tags/${id}`, { method: 'DELETE' })
  }

  static deleteBulkTags(shopBlogTagIds: string[]): Promise<IResponse<ITag>> {
    return fetcher('/shop-blogs/tags/bulk-delete', {
      method: 'POST',
      data: { shopBlogTagIds },
    })
  }
}
