import { Dispatch, SetStateAction, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LoadingTable from 'src/common/LoadingTable'
import {
  CLASS_TYPE,
  CLASS_USER_TYPES,
  LEARNING_STATUS,
  MOCKUP_HEADER,
  PageLink,
} from 'src/constants'
import SAPPCheckbox from '../base/checkbox/SAPPCheckbox'
import PagiantionSAPP from '../base/pagination/PagiantionSAPP'
import { cleanParamsAPI, formatDateTime } from 'src/utils'
import SappTable from '../base/SappTable'
import { KTCardBody } from 'src/_metronic/helpers'
import { IClassCourseList } from 'src/type/class-user'

// define headers
const headers = [
  {
    label: 'Class Code',
    className: 'min-w-150px',
  },
  {
    label: 'Type',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Course',
    className: 'min-w-250px',
  },
  {
    label: 'Program',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Subject',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Exam',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Progress',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Pass Status',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Note',
    className: 'min-w-100px pe-5',
  },
]
export type FilterType = {
  class_code: string
  course_id: string
  course_category_id: string
  status: string
}

interface IProps {
  dataList: IClassCourseList | undefined
  setDataList: Dispatch<SetStateAction<IClassCourseList | undefined>>
  cleanedParams: Object
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  fetchData: (page_index: number, page_size: number, object?: Object) => void
  queryParam: {
    page_index?: number
    page_size?: number
    class_code: string | null
    course_id: string | null
    course_category_id: string | null
    status: string | null
  }
  getParams: (
    class_code: string,
    course_id: string,
    course_category_id?: string,
    status?: string
  ) => { class_code?: string; course_id?: string; course_category_id?: string; status?: string }
}

const TableList = ({
  dataList,
  setDataList,
  cleanedParams,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  fetchData,
  queryParam,
  getParams,
}: IProps) => {
  const navigate = useNavigate()

  //TODO: dùng để change params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParam.class_code ?? '',
      queryParam.course_id ?? '',
      queryParam.course_category_id ?? '',
      queryParam.status ?? ''
    )
  )

  // call API get List
  useEffect(() => {
    setLoading(true)
    fetchData(queryParam.page_index || 1, queryParam?.page_size || 10, cleanedParams)
  }, [])

  //TODO: đổi params trên router
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      class_code: queryParam.class_code ?? '',
      course_id: queryParam.course_id ?? '',
      course_category_id: queryParam.course_category_id ?? '',
      status: queryParam.status ?? '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchData(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  return (
    <KTCardBody>
      <SappTable
        headers={headers}
        loading={loading}
        data={dataList?.data}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((_header, i) => (
              <LoadingTable key={i} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {dataList?.data?.map((item, i) => {
              const isChecked = checkedList && checkedList.includes(item.id)
              const progress = Math.round(
                (item.learning_progress.total_course_sections_completed /
                  item.learning_progress.total_course_sections) *
                  100
              )
              const passStatus = item.class_user_score && item.class_user_score.is_passed
              const examDate =
                item.class.acca_examination?.season ??
                item.class.cfa_examination?.season ??
                item.class.cma_examination?.season
              return (
                <tr key={item?.id ?? i}>
                  <td>
                    <SAPPCheckbox
                      checked={isChecked}
                      onChange={() => {
                        toggleCheck(item.id)
                      }}
                    />
                  </td>
                  <td>
                    <Link
                      className='sapp-cursor-pointer text-gray-800 text-hover-primary sapp-text-truncate-2'
                      to={`${PageLink.CLASS_DETAIL}/${item.class.id}`}
                    >
                      {item.class.code}
                    </Link>
                  </td>
                  <td>
                    <div className='mw-500px sapp-text-truncate-2'>
                      {CLASS_TYPE?.[item.class.type as keyof typeof CLASS_TYPE]}
                    </div>
                  </td>
                  <td className='text-start'>{item.class?.course?.name}</td>
                  <td className='text-start'>{item.class?.course?.course_categories?.[0]?.name}</td>
                  <td className='text-start'>{item.class?.course?.subject?.name}</td>
                  <td className='text-start'>
                    {examDate ? formatDateTime(new Date(examDate), 'DD/MM/YYYY') : '--'}
                  </td>
                  <td className='text-start'>
                    {LEARNING_STATUS?.find((status) => status.value === item.status)?.label}
                  </td>
                  <td className='text-start'>{`${progress}%`}</td>
                  <td className={`text-start ${passStatus ? 'text-primary-green' : ''}`}>
                    {passStatus ? 'Passed' : '--'}
                  </td>
                  <td className='text-start'>
                    {CLASS_USER_TYPES?.[item.type as keyof typeof CLASS_USER_TYPES]}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      {/* start:: show pagination */}
      <PagiantionSAPP
        currentPage={dataList?.metadata?.page_index || 1}
        pageSize={dataList?.metadata?.page_size || 10}
        totalItems={dataList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableList
