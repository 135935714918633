import React from 'react'
import {Button, Divider, Select, Space} from 'antd'
import './sappselect.scss'
import {DownIcon} from 'src/common/icons'

interface IProps {
  onChange?: (val: any) => void
  className?: string
  size: 'large' | 'middle' | 'small'
  value?: string | string[] | null
  options: Array<{label: string; value: any}> | undefined
  placeholder?: string
  showSearch?: boolean
  filterOption?: boolean
  loadmore?: boolean
  handleNextPage?: any
  mode?: any
  disabled?: boolean
}

const SAPPSelectLoadMore = ({
  onChange,
  className,
  size,
  value,
  options,
  placeholder,
  showSearch = false,
  loadmore,
  handleNextPage,
  mode,
  disabled,
}: IProps) => {
  return (
    <Select
      onChange={onChange}
      className={`${className ?? ''} w-100 sapp-hook-form-select min-w-125px`}
      value={value}
      placeholder={placeholder}
      size={size}
      showSearch={showSearch}
      mode={mode}
      maxTagCount={'responsive'}
      disabled={disabled}
      dropdownRender={(menu) => (
        <>
          {menu}
          {loadmore && (
            <>
              <Divider style={{margin: '8px 0'}} />
              <Space style={{padding: '0 8px 4px', display: 'flex', justifyContent: 'center'}}>
                <Button type='text' onClick={handleNextPage}>
                  Load more
                </Button>
              </Space>
            </>
          )}
        </>
      )}
      dropdownStyle={{minWidth: '200px'}}
      options={options}
      suffixIcon={<DownIcon />}
    />
  )
}

export default SAPPSelectLoadMore
