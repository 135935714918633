import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import FieldNote from 'src/components/base/fieldNote/FieldNote'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import WarningText from 'src/components/base/WarningText'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import {
  CLASSROOM_TYPE,
  FACILITY_STATUS_ENUM,
  PageLink,
  VALIDATION_EMPTY_FIELD,
  VALIDATION_FIELD,
} from 'src/constants'
import { TITLE_OPTIONS_ROOM } from 'src/constants/classroom'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IErrorFormArea } from 'src/type'
import { IFacilities, IFacility } from 'src/type/area'
import { ROOM_MODE } from 'src/type/classroom'
import { z } from 'zod'

interface IForm {
  name: string
  code: string
  capacity: number
  deferred_student: number
  new_student: number
  type: string
  facility_id: string
  address?: string
  mode: ROOM_MODE
  virtual_metadata: string
}

const NewArea = () => {
  const { Option } = Select
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)
  const [areaList, setAreaList] = useState<IFacilities>()
  const location = useLocation()
  const [selectedMode, setSelectedMode] = useState(ROOM_MODE.PHYSICAL)
  const queryParams = new URLSearchParams(location.search)

  const createValidationSchema = (selectedMode: ROOM_MODE) => {
    const baseSchema = {
      name: z
        .string({ required_error: VALIDATION_FIELD })
        .min(1, { message: VALIDATION_EMPTY_FIELD }),
      mode: z.string({ required_error: VALIDATION_FIELD }),
    }

    if (selectedMode === ROOM_MODE.PHYSICAL) {
      Object.assign(baseSchema, {
        capacity: z.number(),
        deferred_student: z.number(),
        new_student: z.number(),
        type: z
          .string({ required_error: VALIDATION_FIELD })
          .min(1, { message: VALIDATION_EMPTY_FIELD }),
        facility_id: z
          .string({ required_error: VALIDATION_FIELD })
          .min(1, { message: VALIDATION_EMPTY_FIELD }),
        address: z.string().optional(),
      })
    }

    if (selectedMode === ROOM_MODE.VIRTUAL) {
      Object.assign(baseSchema, {
        virtual_metadata: z
          .string({ required_error: VALIDATION_FIELD })
          .min(1, { message: VALIDATION_EMPTY_FIELD }),
      })
    }

    return z.object(baseSchema)
  }

  const fetchArea = async (page_index: number, page_size: number, params?: Object) => {
    try {
      let query = { status: FACILITY_STATUS_ENUM.ACTIVE }
      Object.assign(query, params ?? {})
      const res = await ClassroomApi.getAreaClasses({ page_index, page_size, params: query })
      setAreaList(res.data)
    } catch (error) {}
  }
  const validationSchema = createValidationSchema(selectedMode)
  const { handleSubmit, clearErrors, control, setValue, setError } = useForm<IForm>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      capacity: 0,
      deferred_student: 0,
      new_student: 0,
      type: '',
      facility_id: '',
      address: '',
      mode: selectedMode,
      virtual_metadata: '',
    },
  })

  const onSubmit = async (data: IForm) => {
    if (
      selectedMode === ROOM_MODE.PHYSICAL &&
      data.deferred_student + data.new_student !== data.capacity
    ) {
      setError('deferred_student', {
        message: 'The total number of new students and deferred students must equal the capacity.',
      })
      setError('new_student', {
        message: 'The total number of new students and deferred students must equal the capacity.',
      })
      setError('capacity', {
        message: 'The total number of new students and deferred students must equal the capacity.',
      })
      return
    } else {
      clearErrors('deferred_student')
      clearErrors('new_student')
      clearErrors('capacity')
    }
    setLoading(true)
    try {
      await ClassroomApi.createRoom(data)
      if (queryParams.get('facility_id') && queryParams.get('facility_id') === data.facility_id) {
        navigate(`${PageLink.CLASSROOM_AREA_DETAIL}/${queryParams.get('facility_id')}/roomlist`)
      } else {
        navigate(PageLink.CLASSROOM_ROOM)
      }
      toast.success('Create successfully!')
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IErrorFormArea) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
    } finally {
      setLoading(false)
    }
  }

  const handleNextPage = async (
    totalPages?: number,
    pageIndex?: number,
    pageSize?: number,
    fetchData?: (page_index: number, page_size: number, params?: Object) => void,
    params?: Object
  ) => {
    if (totalPages && pageIndex && pageIndex < totalPages && fetchData && pageSize) {
      fetchData(pageIndex + 1, pageSize, params)
    }
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(PageLink.CLASSROOM_ROOM),
    })
  }

  useEffect(() => {
    if (queryParams.get('area_name')) {
      fetchArea(1, 10, { search: `name=${queryParams.get('area_name')}` })
      setValue('facility_id', queryParams.get('facility_id') ?? '')
    } else {
      fetchArea(1, 10)
    }
  }, [])

  const handleDropdownVisibleChange = (open: boolean) => {
    if (open) {
      fetchArea(1, 10)
    }
  }

  return (
    <CreateEditLayout
      customPrimaryButton={
        <ButtonPrimary
          onClick={handleSubmit(onSubmit)}
          type='submit'
          title='Save'
          loading={loading}
          size='small'
        />
      }
      onCancel={handleCancel}
      loading={loading}
      pageTitle={TITLE_OPTIONS_ROOM.createRoom}
    >
      <div>
        {contextHolder}
        <div className='collapse show'>
          <div className='card-body card-body px-10 pt-8 pb-4 row'>
            <div className='mb-8 col-6'>
              <HookFormTextField required control={control} name='name' label='Name' />
            </div>
            <div className='mb-8 col-3'>
              <HookFormSelectAntd
                control={control}
                name='mode'
                placeholder='Mode'
                label='Mode'
                required
                allowClear={false}
                onChange={(value: ROOM_MODE) => setSelectedMode(value)}
                options={Object.values(ROOM_MODE).map((mode) => ({
                  label: capitalize(mode),
                  value: mode,
                }))}
              />
            </div>

            {selectedMode === ROOM_MODE.PHYSICAL && (
              <>
                <div className='mb-8 col-6'>
                  <HookFormSelectAntd
                    control={control}
                    name='facility_id'
                    placeholder='Facility'
                    label='Facility'
                    required
                    onChange={(value) => {
                      if (!value) {
                        fetchArea(1, 10)
                      }
                    }}
                    onSearch={(value) => {
                      if (value) {
                        fetchArea(1, 10, { search: `name=${value},code=${value}` })
                      } else {
                        fetchArea(1, 10)
                      }
                    }}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    handleNextPage={handleNextPage(
                      areaList?.metadata.total_pages,
                      areaList?.metadata.page_index,
                      areaList?.metadata.page_size,
                      fetchArea
                    )}
                    showSearch
                    options={
                      areaList?.facilities?.map((area) => ({
                        label: area.name,
                        value: area.id,
                      })) ?? []
                    }
                  />
                </div>
                <div className='mb-8 col-6'>
                  <HookFormSelectAntd
                    control={control}
                    name='type'
                    placeholder='Type'
                    label='Type'
                    required
                    showSearch
                    options={CLASSROOM_TYPE}
                  />
                </div>
                <div className='col-12'>
                  <WarningText className='mb-8 mt-0' title='Địa chỉ phòng học'>
                    <ul>
                      <li className='sapp-content-alert mt-2'>
                        Người dùng chỉ cần nhập số tầng (Ví dụ: Tầng 5)
                      </li>
                    </ul>
                  </WarningText>
                </div>
                <div className='mb-8 col-6'>
                  <HookFormTextField control={control} name='address' label='Address' />
                </div>
                <div className='mb-8 col-6'>
                  <HookFormTextField
                    required
                    control={control}
                    name='capacity'
                    label='Capacity'
                    onChange={(e) => setValue('capacity', parseInt(e.target.value))}
                    type='number'
                  />
                </div>
                <div className='mb-8 col-6'>
                  <HookFormTextField
                    required
                    control={control}
                    name='deferred_student'
                    label='Deferred Students'
                    onChange={(e) => setValue('deferred_student', parseInt(e.target.value))}
                    type='number'
                  />
                </div>
                <div className='mb-8 col-6'>
                  <HookFormTextField
                    required
                    control={control}
                    name='new_student'
                    label='New Students'
                    onChange={(e) => setValue('new_student', parseInt(e.target.value))}
                    type='number'
                  />
                </div>
              </>
            )}
            {selectedMode === ROOM_MODE.VIRTUAL && (
              <div className='col-12'>
                <HookFormTextField
                  control={control}
                  name='virtual_metadata'
                  label='Additional Information'
                  required
                />
                <FieldNote className='ms-4'>
                  Please fill in additional information of this classroom, such as email/password of
                  zoom account,…
                </FieldNote>
              </div>
            )}
          </div>
        </div>
      </div>
    </CreateEditLayout>
  )
}

export default NewArea
