import { DebouncedFunc, debounce } from 'lodash'
import { useMemo, useState } from 'react'
import { AuthorAPI } from 'src/apis/short-course/author'
import { DEBOUNCED_NUMBER } from 'src/constants'
import { IAuthorListItem } from 'src/type/shop/author'
import { IShopFilter } from 'src/type/shop/common'

export interface IMetadata {
  total_pages: number
  total_records: number
  page_index: number
  page_size: number
}

export interface IAuthorFilter {
  loading: boolean
  data?: IShopFilter[]
  metadata?: IMetadata
  search?: string
}

const defaultValue = {
  loading: false,
  data: [],
  search: '',
}

export interface IUseShopFilterResult {
  authorList: IAuthorFilter
  setAuthorList: React.Dispatch<React.SetStateAction<IAuthorFilter>>
  getAllAuthors: (name?: string, page_index?: number, page_size?: number) => Promise<void>
  debounceGetCategories: DebouncedFunc<
    (name?: string, page_index?: number, page_size?: number) => Promise<void>
  >
  handleNextPage: DebouncedFunc<() => void>
}
/**
 * Hook dùng để chứa các function xử lý category
 */
const useAuthorList = () => {
  const [authorList, setAuthorList] = useState<IAuthorFilter>(defaultValue)

  /**
   * Hook dùng để chứa các function xử lý author
   * @param {name}: tên author
   * @param {page_index}: trang hiện tại
   * @param {page_size}: số lượng bản ghi 1 size
   */
  const getAllAuthors = async (name?: string, page_index: number = 1, page_size: number = 10) => {
    try {
      setAuthorList((e) => ({
        ...e,
        loading: true,
      }))
      const response = await AuthorAPI.getAuthors({
        page_index,
        page_size,
        params: { name },
      })
      const author = response.data.blogAuthors.map((author: IAuthorListItem) => ({
        id: author.id,
        name: author.name,
      }))
      setAuthorList((prev: IAuthorFilter) => {
        return {
          ...prev,
          metadata: response.data.metadata,
          loading: false,
          data:
            name !== prev.search
              ? author
              : [...(prev?.data || []), ...author].filter(
                  (obj, index, self) => self.findIndex((o) => o.id === obj.id) === index
                ),
        }
      })
    } catch (error) {
      setAuthorList((e) => ({
        ...e,
        loading: false,
      }))
    }
  }
  /**
   * function dùng để debounce callback sau 1 khoảng timeout
   * @param {callback}: tên author
   * @param {Time}: debounced time
   */
  const debounceGetAuthors = debounce(getAllAuthors, DEBOUNCED_NUMBER)

  /**
   * Dùng để fetch data mỗi khi scroll down hết
   */
  const handleNextAuthorPage = () => {
    const total_pages = authorList.metadata?.total_pages
    const page_index = authorList.metadata?.page_index
    const page_size = authorList.metadata?.page_size
    if (total_pages) {
      if (page_index && page_index < total_pages) {
        getAllAuthors(authorList.search, page_index + 1, page_size)
      }
    }
  }

  const debouncedHandleNextPage = debounce(handleNextAuthorPage, DEBOUNCED_NUMBER)

  const useAuthorFilterResult = useMemo(
    () => ({
      authorList,
      setAuthorList,
      getAllAuthors,
      debounceGetAuthors,
      handleNextAuthorPage: debouncedHandleNextPage,
    }),
    [authorList]
  )
  return useAuthorFilterResult
}
export default useAuthorList
