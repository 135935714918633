import { Skeleton } from 'antd'
import ClassStatusCell from './ClassStatusCell'
import { formatDateTime } from 'src/utils'

interface IProps {
  status?: 'DRAFT' | 'PUBLIC' | 'BLOCKED' | 'ENDED' | string | undefined
  area: string
  flexible: number | undefined
  startedAt: string
  finishedAt: string
  durationType: string
  loading?: boolean
}

const MoreInfoClass = ({
  status,
  area,
  flexible,
  startedAt,
  finishedAt,
  durationType,
  loading,
}: IProps) => {
  const formatDate = (getDate: any) => {
    const date = new Date(getDate)
    const formattedDate = formatDateTime(date, 'DD/MM/YYYY')
    return formattedDate
  }

  return (
    <div>
      <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
        <div className='d-flex align-items-center me-5 mb-2'>
          {loading ? (
            <Skeleton.Button active block size='small' />
          ) : (
            <ClassStatusCell status={status} />
          )}
        </div>
        <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
          {loading ? (
            <Skeleton.Button active block size='small' />
          ) : (
            <>
              <i className='ki-duotone ki-geolocation fs-4 me-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              {area}
            </>
          )}
        </div>

        {durationType === 'FLEXIBLE' ? (
          <>
            {flexible !== null && (
              <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                {loading ? (
                  <Skeleton.Button active block size='small' />
                ) : (
                  <>
                    {' '}
                    <i className='ki-duotone ki-calendar fs-4 me-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <span>
                      {flexible} {flexible === 1 ? 'day' : 'days'}
                    </span>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {startedAt !== null && finishedAt !== null && (
              <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                {loading ? (
                  <Skeleton.Button active block size='small' />
                ) : (
                  <>
                    <i className='ki-duotone ki-calendar fs-4 me-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <span className='d-flex align-items-center'>
                      {formatDate(startedAt)} - {formatDate(finishedAt)}
                    </span>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default MoreInfoClass
