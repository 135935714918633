import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import choosefourlevel from 'src/_metronic/assets/icons/choosefourlevel.svg'
import choosetwolevel from 'src/_metronic/assets/icons/choosetwolevel.svg'
import choosethreelevel from 'src/_metronic/assets/icons/choosethreelevel.svg'
import choosetwolevelHover from 'src/_metronic/assets/icons/chooseTwoLevelHover.svg'
import choosefourlevelHover from 'src/_metronic/assets/icons/chooseFourLevelHover.svg'
import choosethreeLevelHover from 'src/_metronic/assets/icons/chooseThreeLevelHover.svg'
import { Link, useParams } from 'react-router-dom'
import { PageLink } from 'src/constants'
import SappModal from '../base/SappModal'
import './ModalChooseStructure.scss'
import { setCourseLevel } from 'src/utils'
import toast from 'react-hot-toast'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const DEFAULT_WIDTH = 128
const DEFAULT_HEIGHT = 93

const ModalChooseStructure = ({ open, setOpen }: IProps) => {
  const [isHoveredFourLevel, setIsHoveredFourLevel] = useState(false)
  const [isHoveredTwoLevel, setIsHoveredTwoLevel] = useState(false)

  const { id } = useParams()

  const handlePartialLevel = () => {
    setCourseLevel('false')
    setOpen(false)
    toast.error('Chức năng đang phát triển')
  }

  const handleFullLevel = () => {
    setCourseLevel('true')
  }

  useEffect(() => {
    setIsHoveredTwoLevel(false)
  }, [open])

  return (
    <SappModal
      centered
      dialogClassName='sapp-mw-520px'
      open={open}
      title='Choose Course Structure'
      handleClose={() => setOpen(false)}
      showFooter={false}
      classBody='sapp-px-84px py-12 modal-body'
    >
      <div className='d-md-flex justify-content-xl-between justify-content-md-evenly'>
        <Link
          to={`${PageLink.COURSE_FULL}/${id}`}
          className='text-center text-gray-800'
          onClick={handleFullLevel}
        >
          <div
            onMouseEnter={() => setIsHoveredFourLevel(true)}
            onMouseLeave={() => setIsHoveredFourLevel(false)}
          >
            {isHoveredFourLevel ? (
              <img
                width={DEFAULT_WIDTH}
                height={DEFAULT_HEIGHT}
                src={choosefourlevelHover}
                alt='icon'
                className='cursor-pointer'
              />
            ) : (
              <img
                width={DEFAULT_WIDTH}
                height={DEFAULT_HEIGHT}
                src={choosefourlevel}
                alt='icon'
                className='cursor-pointer'
              />
            )}
            <p className={`mt-4 mb-0 level-name ${isHoveredFourLevel ? 'text-primary' : ''}`}>
              4 Level
            </p>
          </div>
        </Link>
        <Link to={''} className='text-center text-gray-800' onClick={handlePartialLevel}>
          <div
            onMouseEnter={() => setIsHoveredTwoLevel(true)}
            onMouseLeave={() => setIsHoveredTwoLevel(false)}
          >
            {isHoveredTwoLevel ? (
              <img
                width={DEFAULT_WIDTH}
                height={DEFAULT_HEIGHT}
                src={choosetwolevelHover}
                alt='icon'
                className='cursor-pointer'
              />
            ) : (
              <img
                width={DEFAULT_WIDTH}
                height={DEFAULT_HEIGHT}
                src={choosetwolevel}
                alt='icon'
                className='cursor-pointer'
              />
            )}
            <p className={`mt-4 mb-0 level-name ${isHoveredTwoLevel ? 'text-primary' : ''}`}>
              2 Level
            </p>
          </div>
        </Link>
      </div>
    </SappModal>
  )
}

export default ModalChooseStructure
