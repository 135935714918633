import { useState } from 'react'
import toast from 'react-hot-toast'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import DeactivateAccount from 'src/components/my-profile/setting-profile/DeactivateAccount'
import { userKeys } from 'src/constants/queryKeyFactory'
import EditStudentProfile from './EditStudentProfile'

const SettingStudent = () => {
  const { id } = useParams()
  const [confirmDeactive, setConfirmDeactive] = useState(false)
  const queryClient = useQueryClient()

  const blockedStudent = async () => {
    try {
      await UsersAPI.blocked([id as string]).then(() => toast.success('Blocked Successfully!'))
      queryClient.invalidateQueries({ queryKey: userKeys.detail(id!, 'student') })
    } catch {}
  }

  return (
    <>
      <EditStudentProfile />

      <DeactivateAccount
        confirmDeactive={confirmDeactive}
        setConfirmDeactive={setConfirmDeactive}
        blockedUser={blockedStudent}
      />
    </>
  )
}

export default SettingStudent
