import dayjs from 'dayjs'
import { FORMAT_DATE, FORMAT_DATETIME } from 'src/constants'

export function truncateString(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str
  } else {
    return str.slice(0, maxLength) + ' ...' // Add ellipsis to indicate truncation
  }
}

export const replaceValueAll = (value: string) => {
  return value === 'all' ? '' : value
}
//format time
export const convertTime = (time: number) => {
  let totalSeconds = Math.floor(time)

  // Calculate hours, minutes and seconds
  let hours = Math.floor(totalSeconds / 3600)
  totalSeconds %= 3600
  let minutes = Math.floor(totalSeconds / 60)
  let seconds = totalSeconds % 60
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`
}
export const qType = {
  TRUE_FALSE: 'True false',
  ONE_CHOICE: 'One choice',
  MULTIPLE_CHOICE: 'Multiple choice',
  MATCHING: 'Matching',
  SELECT_WORD: 'Select word',
  FILL_WORD: 'Fill word',
  DRAG_DROP: 'Drag drop',
  ESSAY: 'Essay',
}
export const LayoutQues = {
  HORIZONTAL: '1 Col',
  VERTICAL: '2 Col',
}

/**
 * @description Chuẩn hóa chuỗi search với toán tử like
 * @static
 * @param {normalizeTexting} text
 * @return {*}
 * @memberof QueryHelper
 */
export const normalizeLikeParam = (text: string): string => {
  if (!text) {
    return ''
  }
  let normalizeText = text.toLowerCase().trim()
  normalizeText = normalizeText.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  normalizeText = normalizeText.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  normalizeText = normalizeText.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  normalizeText = normalizeText.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  normalizeText = normalizeText.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  normalizeText = normalizeText.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  normalizeText = normalizeText.replace(/đ/g, 'd')
  normalizeText = normalizeText.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  normalizeText = normalizeText.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  normalizeText = normalizeText.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  normalizeText = normalizeText.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  normalizeText = normalizeText.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  normalizeText = normalizeText.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  normalizeText = normalizeText.replace(/Đ/g, 'D')

  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  normalizeText = normalizeText.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  normalizeText = normalizeText.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư

  return normalizeText
}

/**
 * @description Thay thế các biến trong 1 message bằng các giá trị tương ứng
 * @param {string} str
 * @param {Record<string, any>} replacements
 * @return {*}
 * @memberof QueryHelper
 */
export const replaceMessage = (str: string, replacements: Record<string, any>): string => {
  return str.replace(/{(\w+)}/g, (_, key) => {
    if (key === 'DATE_MESSAGE' && replacements['TYPE'] === 'DATE') {
      const value = String(replacements['DATE_MESSAGE']).split(',')
      let dates: string[] = []
      value.forEach((item: string) => {
        dates.push(dayjs(item).format(FORMAT_DATETIME))
      })
      return dates.join(', ')
    }
    return replacements[key] || `{${key}}`
  })
}
