/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ModalChooseStructure from 'src/components/courses/ModalChooseStructure'
import TableList from 'src/components/courses/TableList'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { COURSE_STATUS, COURSE_TYPES, FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { LANG_COURSES, LANG_SIDEBAR } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_COURSE_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useChecked from 'src/hooks/use-checked'
import { useInfinitePrograms } from 'src/hooks/useInfiniteQueryHooks'
import { ITabs, Role } from 'src/type'
import { ICourseList, ICourseParams } from 'src/type/courses'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { navigateToCourseList } from '../../utils/course'

const breadcrumbs: ITabs[] = [
  {
    link: '',
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_COURSES.courseList,
  },
]

const fieldNames = [
  'category',
  'name',
  'status',
  'course_types',
  'sortType',
  'fromDate',
  'toDate',
  'course_category_ids',
]
const initialValues: any = {
  category: '',
  name: '',
  status: '',
  course_types: '',
  sortType: '',
  fromDate: '',
  toDate: '',
  course_category_ids: '',
}

const Courses = () => {
  const [courseList, setCourseList] = useState<ICourseList>()
  const { profileMe } = useUserContext()
  const allowRenderCreateCourse = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_COURSE_GR.CREATE_COURSE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const [openChooseStructure, setOpenChooseStructure] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { search } = useLocation()
  const location = useLocation()
  const template = location.pathname.includes('/courses3') ? 3 : 4
  const searchParams = new URLSearchParams(search)
  const queryParams = {
    name: searchParams.get('name') ?? '',
    course_category_ids: searchParams.get('course_category_ids'),
    status: searchParams.get('status'),
    course_types: searchParams.get('course_types'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    courseList?.courses
  )

  const getParams = (
    name: string,
    course_category_ids: string,
    status: string,
    course_types: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string
  ) => ({
    name,
    course_category_ids,
    status,
    course_types,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })
  const id = undefined
  const { control, getValues, reset, setValue } = useForm<any>({ mode: 'onSubmit' })

  const fetchCourseList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await CoursesAPI.get({
        page_index: currentPage,
        page_size: pageSize,
        params: { ...params, template: template } as Partial<ICourseParams>,
      })
      setCourseList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setLoading(true)
    navigateToCourseList(template, navigate)

    fetchCourseList(1, 10)
  }
  useEffect(() => {
    setLoading(true)
    // Gọi lại API khi pathname thay đổi
    fetchCourseList(1, queryParams.page_size || 10, filterParams)
  }, [location.pathname])
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: getValues('name'),
      course_category_ids: replaceValueAll(getValues('course_category_ids')),
      status: replaceValueAll(getValues('status')),
      course_types: replaceValueAll(getValues('course_types')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const checkFromDateToDate =
    queryParams.name ||
    queryParams.course_category_ids ||
    queryParams.status ||
    queryParams.course_types ||
    queryParams.sortType ||
    queryParams.fromDate ||
    queryParams.toDate

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))

    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('course_category_ids')),
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('course_types')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )

    setLoading(true)

    fetchCourseList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  const filterParams = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.course_category_ids ?? '',
      queryParams.status ?? '',
      queryParams.course_types ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      checkFromDateToDate ? 'updated_at' : 'created_at'
    )
  )

  const {
    programs,
    refetch: refetchPrograms,
    hasNextPage: hasNextPageProgram,
    fetchNextPage: fetchNextPageProgram,
    debounceSearch,
  } = useInfinitePrograms()

  useEffect(() => {
    if (queryParams.course_category_ids) {
      refetchPrograms()
    }
  }, [])

  const handleAddCourseClick = () => {
    if (template === 3) {
      navigate(`${PageLink.COURSE_MEDIUM}/${id}`)
    } else {
      setOpenChooseStructure(true)
    }
  }

  return (
    <PageLayouts pageTitle={LANG_COURSES.courseList} breadcrumbs={breadcrumbs}>
      <div className='card'>
        {/* start:: filter */}
        <div className='pt-10 px-10'>
          <ListFilterLayout>
            {/* begin:: Search */}
            <HookFormTextField
              control={control}
              name='name'
              placeholder='Search course name'
              defaultValue={queryParams?.name}
              onSubmit={onSubmit}
              isListScreen
            />

            {/* end:: Search */}
            <HookFormSelectAntd
              control={control}
              name='course_category_ids'
              placeholder='Program'
              defaultValue={queryParams.course_category_ids ?? ''}
              onSearch={(text) => {
                debounceSearch(text)
              }}
              showSearch
              classNameHeight='sapp-h-40'
              handleNextPage={hasNextPageProgram && fetchNextPageProgram}
              onDropdownVisibleChange={(open) => {
                if (open && !programs) {
                  refetchPrograms()
                  return
                }
              }}
              options={
                programs?.map((program) => ({ label: program.name, value: program.id })) ?? []
              }
            />

            <HookFormSelectAntd
              control={control}
              name='status'
              placeholder='Status'
              defaultValue={queryParams.status ?? ''}
              classNameHeight='sapp-h-40'
              options={COURSE_STATUS}
            />

            <HookFormSelectAntd
              control={control}
              name='course_types'
              placeholder='Type'
              defaultValue={queryParams.course_types ?? ''}
              classNameHeight='sapp-h-40'
              options={COURSE_TYPES}
            />

            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort by'
              control={control}
              defaultValue={queryParams?.sortType ?? ''}
              options={FILTER_SELECTALL_SORTBY}
              classNameHeight='sapp-h-40'
            />

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />

            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </ListFilterLayout>
        </div>
        <div className='card-header card-header__course border-0 pt-5 '>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>

          {/* start:: button open modal */}
          {allowRenderCreateCourse && (
            <ButtonIconPrimary
              title={LANG_COURSES.addCourses}
              iconName='plus'
              onClick={handleAddCourseClick}
              size='small'
            />
          )}
          {/* end:: button open modal */}
        </div>

        {/* end:: filter */}

        {/* start:: table */}
        <TableList
          courseList={courseList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchCourseList={fetchCourseList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
        {/* end:: table */}
      </div>
      <ModalChooseStructure open={openChooseStructure} setOpen={setOpenChooseStructure} />
    </PageLayouts>
  )
}

export default withAuthComponents(Courses)
