import { useRef } from 'react'
import { ResizableComponent } from 'src/components/certificate/ResizableComponent'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler } from 'react-hook-form'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import { Dispatch, SetStateAction } from 'react'
import './Recommendation.scss'
import { COMMENTS } from 'src/constants/grade'
import { GradeAPI } from 'src/apis/grade'
import { toast } from 'react-hot-toast'
import { GradingStatus, IQuizAttemptInfo } from 'src/type/grade'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'

interface IProps {
  gradeId: string
  openRecomendation: boolean
  setOpenRecomendation: Dispatch<SetStateAction<boolean>>
  componentPosition: object
  setComponentPosition: Dispatch<SetStateAction<object>>
  quizInfo: IQuizAttemptInfo
  gradeStatus: string
  getQuizAtemptInfo: () => void
}

interface FormData {
  comment?: string
  recommendation?: string
}

const Recommendation = ({
  gradeId,
  openRecomendation,
  setOpenRecomendation,
  componentPosition,
  setComponentPosition,
  quizInfo,
  gradeStatus,
  getQuizAtemptInfo,
}: IProps) => {
  const certificateContentRef = useRef<HTMLDivElement>(null)

  const validationSchema = z.object({
    comment: z.string().optional(),
    recommendation: z.string().optional(),
  })

  const { handleSubmit, control } = useForm<FormData>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const handleResize = (
    data: { top: number; left: number; width: number; height: number },
    index: number
  ) => {
    setComponentPosition(data)
  }

  const updateQuizAttemptFeedback = async (id: string, comment: string, recommendation: string) => {
    try {
      const data = {
        comment,
        recommendation,
      }
      const response = await GradeAPI.updateQuizAttemptFeedback(id, data)
      if (response.success) {
        getQuizAtemptInfo()
        toast.success('Feedback updated successfully!')
      }
    } catch (error) {}
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setOpenRecomendation(false)
    await updateQuizAttemptFeedback(gradeId, data?.comment || '', data?.recommendation || '')
  }

  return (
    <>
      {openRecomendation && (
        <div className='grade-recomendation' ref={certificateContentRef}>
          <ResizableComponent
            key={'resizable-recommendation'}
            index={1}
            getResize={handleResize}
            parentContentRef={certificateContentRef}
            position={componentPosition}
          >
            <div className='position-absolute top-0 start-0 w-100 h-100 bg-white'>
              <form
                className='overflow-auto h-100'
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit(onSubmit)()
                }}
              >
                <div className='p-10'>
                  <div className='row mb-6'>
                    <div className='fv-row cursor-default not-resizer'>
                      <HookFormEditor
                        control={control}
                        name='comment'
                        defaultValue={quizInfo?.comment || ''}
                        label={COMMENTS?.Examiner_Comment}
                        disabled={gradeStatus === GradingStatus.FINISHED}
                        resourceLocation={RESOURCE_LOCATION.COURSE}
                        object_id={undefined}
                        height={400}
                        math
                      />
                    </div>
                  </div>
                  <div className='fv-row cursor-default not-resizer'>
                    <HookFormEditor
                      control={control}
                      name='recommendation'
                      defaultValue={quizInfo?.recommendation || ''}
                      label={COMMENTS?.Recommendation}
                      disabled={gradeStatus === GradingStatus.FINISHED}
                      resourceLocation={RESOURCE_LOCATION.COURSE}
                      object_id={undefined}
                      height={400}
                      math
                    />
                  </div>
                </div>
                <div className='recomendation-footer justify-content-between d-flex px-10'>
                  <div
                    className='btn-cancel me-5 sapp-h-40px cursor-pointer sapp-text-gray-800'
                    onClick={() => {
                      setOpenRecomendation(false)
                    }}
                  >
                    {COMMENTS?.Cancel}
                  </div>
                  <ButtonPrimary
                    type='submit'
                    title={COMMENTS?.Save}
                    isListScreen={true}
                    className='btn-bordered'
                    disabled={gradeStatus === GradingStatus.FINISHED}
                  />
                </div>
              </form>
            </div>
          </ResizableComponent>
        </div>
      )}
    </>
  )
}

export default Recommendation
