import { zodResolver } from '@hookform/resolvers/zod'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import { useParams, useLocation } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import SAPPSelectLoadMore from 'src/components/base/select/SAPPSelectLoadmore'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import { TYPE_DOCUMENT, VALIDATE_NUMBER, VALIDATION_FIELD } from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import { htmlToRaw } from 'src/utils'
import { z } from 'zod'
import SelectedLearningOutCome from '../selected-learning-outcome-item'
import { changeNodeAtPath } from '../tree-course'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import CreateTabs from 'src/components/courses/course-medium/modals/CreateTabs'
import { CourseCondition, ICourseType } from 'src/type/courses'
import { IOpenCreateTab } from 'src/type/course-medium/section'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'

interface IProps {
  open: boolean
  setOpen: any
  data?: any
  id?: string
  lo?: any
  selfId?: string
  treeData?: any
  setData?: any
  path?: any
  position?: number
  onlyEditName?: boolean
}

const CreateActivity = ({
  open,
  setOpen,
  data,
  id,
  lo,
  selfId,
  treeData,
  setData,
  path,
  position,
  onlyEditName,
}: IProps) => {
  // const [uploadFiles, setUploadFiles] = useState<any[]>([])
  // const [loading, setLoading] = useState(false)
  const params = useParams()
  const defaultValues = {
    name: '',
    time: {
      hours: '',
      mins: '',
    },
    code: '',
    is_graded: 'true',
    display_icon: 'TEXT',
    files: [],
  }

  const [courseCondition, setCourseState] = useState<CourseCondition>(new CourseCondition())
  const templateUrl = useLocation()
  const template = templateUrl.pathname.includes('/course-medium/') ? 3 : 4
  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  // const [fileEditor, setFileEditor] = useState<Array<{id: string}>>([])

  // /**
  // * @description state này để lấy id của file sau khi update từ editor
  // */
  // const textEditorFiles = fileEditor?.map((data) => { return { id: data?.id } }) || []
  const validationSchema = z.object({
    name: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    code: z.string().trim().optional(),
    display_icon: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    is_graded: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    time: z.object({
      hours: z
        .string({ required_error: VALIDATION_FIELD })
        .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
        .refine(
          (val) => {
            if (
              (!Number(val) && !Number(getValues('time.mins'))) ||
              (Number(val) === 0 && Number(getValues('time.mins')) === 0)
            ) {
              return false
            }
            return true
          },
          { message: VALIDATION_FIELD }
        )
        .optional(),
      mins: z
        .string({ required_error: VALIDATION_FIELD })
        .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
        .refine((val) => {
          if (
            (!Number(val) && !Number(getValues('time.hours'))) ||
            (Number(val) === 0 && Number(getValues('time.hours')) === 0)
          ) {
            return false
          }
          return true
        }, VALIDATION_FIELD)
        .optional(),
    }),
    files: z.array(z.any().optional()).default([]),
  })
  const [openCreateTab, setOpenCreateTab] = useState<IOpenCreateTab>({
    status: false,
    course_section_id: '',
  })
  useEffect(() => {
    async function fetchlistCourseOutComes() {
      try {
        const res = await CoursesAPI.getCourseOutcomes(lo?.id)
        setOutComeList(res.data.course_outcomes)
      } catch (err) {
        console.error(err)
      }
    }

    if (lo?.id) {
      fetchlistCourseOutComes()
    }
  }, [lo])

  const useFormProp = useForm<{
    name: string
    time: {
      hours: string
      mins: string
    }
    code: string
    is_graded: string
    display_icon: string
    files: any[]
  }>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    trigger,
    setError,
    watch,
    formState: { errors, isSubmitted },
  } = useFormProp
  const filesFormAddNew = watch('files')

  const [activityDetail, setActivityDetail] = useState<any>()
  const { getCourseDetail, idCourse, setIdChild } = useCourseContext()
  const [selectedLearningOutCome, setSelectedLearningOutcome] = useState<string[]>([])
  const [outcomesList, setOutComeList] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [showTab, setShowTab] = useState<boolean>(false)
  const [idCreate, setIdCreate] = useState<string>('')
  const [showFinish, setShowFinish] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [showListDoc, setShowListDoc] = useState<boolean>(false)
  const [showActivityInformation, setShowActivityInformation] = useState<boolean>(false)
  const [showButtonEdit, setShowButtonEdit] = useState<boolean>(true)

  const handleClose = () => {
    reset()
    setOpen({ status: false })
    setSelectedLearningOutcome([])
    setOutComeList([])
    setShowTab(false)
    setShowFinish(false)
    setShowActivityInformation(false)
    setShowListDoc(false)
    setIdCreate('')
    setIsDisabled(false)
  }

  useEffect(() => {
    async function getOldAcitivity(id: string) {
      if (id) {
        setShowTab(true)
        setIdCreate(id)
        setShowButtonEdit(true)
        try {
          const res = await CoursesAPI.getActivityDetail(id)
          const oldData = res.data
          Object.entries(oldData || {}).forEach(([key, value]: [any, any]) => {
            setValue(key, value)
          })
          setValue('is_graded', oldData.is_graded.toString())
          setValue('time.hours', Math.floor(oldData.duration / 60).toString())
          setValue('time.mins', Math.floor(oldData.duration % 60).toString())

          const course_outcome_ids = []
          for (let e of oldData.course_outcomes) {
            course_outcome_ids.push(e.id)
          }
          setSelectedLearningOutcome(course_outcome_ids)
          const files = []
          for (let e of oldData.files) {
            files.push(e.resource)
          }
          setValue('files', files)
          setActivityDetail(oldData)
        } catch (err) {
          console.error(err)
        }
      } else {
        setShowTab(false)
        setShowFinish(false)
        setShowActivityInformation(false)
        setShowListDoc(false)
        setIdCreate('')
        setShowButtonEdit(true)
      }
    }
    getOldAcitivity(selfId ?? idCreate)
  }, [selfId, idCreate])

  // const {setLoadingDelete, getCourseDetail, idCourse, loading, loadingDelete} = useCourseContext()
  const onSubmit = async (dataValue: any) => {
    if (showFinish) {
      handleClose()
      setShowTab(false)
      setShowFinish(false)
      setShowActivityInformation(false)
      setShowListDoc(false)
      return
    }
    setLoading(true)
    const resource_ids = []
    if (filesFormAddNew) {
      for (let e of filesFormAddNew) {
        resource_ids.push({ resource_id: e.resource_id || e.id, type: 'attached' })
      }
    }

    const payload = {
      // ...dataValue,
      name: dataValue.name?.trim(),
      parent_id: !selfId ? id : dataValue.parent_id,
      is_graded: dataValue.is_graded === 'true' ? true : false,
      duration: Number(dataValue.time.hours) * 60 + Number(dataValue.time.mins || 0),
      display_icon: dataValue.display_icon,
      files: resource_ids,
      code: dataValue.code,
      course_id: idCourse,
      // text_editor_files: textEditorFiles,
      position: selfId ? activityDetail?.position : (position || 0) + 1,
    } as any
    try {
      if (lo?.id) {
        payload.course_learning_outcome_id = lo?.id
        payload.course_outcome_ids = []
        for (let e of selectedLearningOutCome) {
          payload.course_outcome_ids.push(e)
        }
      }
      if (selfId || idCreate) {
        const res = await CoursesAPI.editActivity({ id: selfId ?? idCreate, data: payload })
        res.success && toast.success('Edit Activity successfully')

        const getNodeKey = ({ treeIndex }: any) => treeIndex
        // getCourseDetail(idCourse)

        const newNode = {
          ...res.data,
          children: [],
          total_tab: data.total_tab,
        }
        if (treeData) {
          setData(changeNodeAtPath({ treeData: treeData, path, getNodeKey, newNode }))
        }
        getCourseDetail(idCourse)
        setIdChild(selfId ?? idCreate)
      } else {
        const res =
          template === 3
            ? await CoursesAPI.createCourseActivity3level(payload)
            : await CoursesAPI.createCourseAcitivy(payload)

        res.success && toast.success('Create Activity successfully')
        setIdChild(res?.data?.id)
        getCourseDetail(idCourse)
        setIdCreate(res?.data?.id)
      }
      if (template === 3) {
        setShowTab(true)
        setShowFinish(true)
        setShowActivityInformation(true)
        setShowListDoc(true)
      } else {
        handleClose()
        setShowTab(false)
        setShowFinish(false)
        setShowActivityInformation(false)
        setShowListDoc(false)
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.code === '422|000405') {
        setError('code', {
          type: 'custom',
          message: 'Code does exist!',
        })
      }
    } finally {
      setLoading(false)
    }
  }
  const convertValue = useCallback(() => {
    if (outcomesList.length > 0) {
      const arr = []
      for (let e of outcomesList) {
        arr.push({ label: htmlToRaw(e.description), value: e.id })
      }
      return arr
    }
  }, [outcomesList])
  const handleDeleteSelected = (value: string) => {
    setSelectedLearningOutcome((prev: any) => {
      const arr = [...prev]
      const newArr = arr.filter((e) => {
        return e !== value
      })
      return newArr
    })
  }

  const handleOnchangeFile = (files: any) => {
    setValue(
      'files',
      files.map((e: { id: string; name: string; resource_id: string }) => ({
        resource_id: e.resource_id || e.id,
        id: e.resource_id || e.id,
        name: e.name,
        type: 'attached',
      }))
    )
    // setFileEditor(files)
  }

  /**
   * @description Bật/tắt chế độ chỉnh sửa Activity Information, mặc định khi bấm edit sẽ show button edit. Nếu đang ở trạng thái "finish", thì ẩn button finish
   * và ẩn section Activity Information, enable các trường của section Activity Information, ẩn section List Documents
   * Ngược lại thì hiển thị button finish và hiển thị section Activity Information
   * @returns {void}
   */
  const toggleEdit = () => {
    setShowButtonEdit(true)
    if (showFinish) {
      setShowFinish(false)
      setShowActivityInformation(false)
      setIsDisabled(false)
      setShowListDoc(false)
    } else {
      setShowFinish(true)
      setShowActivityInformation(true)
    }
  }

  /**
   * @description Bật/tắt chế độ chỉnh sửa Activity Information, mặc định khi bấm view sẽ ẩn button edit. Nếu đang ở trạng thái "finish", thì ẩn button finish
   * và ẩn section Activity Information, disable các trường của section Activity Information
   * Ngược lại thì hiển thị button finish và hiển thị section Activity Information để view
   * @returns {void}
   */
  const toggleView = () => {
    setShowButtonEdit(false)
    if (showFinish) {
      setShowFinish(false)
      setShowActivityInformation(false)
      setIsDisabled(true)
    } else {
      setShowFinish(true)
      setShowActivityInformation(true)
    }
  }

  const toggleViewListDoc = () => {
    setShowListDoc(!showListDoc)
  }

  return (
    <Modal
      dialogClassName={`modal-dialog modal-dialog-scrollable modal-fullscreen modal-fullscreen-form`}
      centered={true}
      show={open}
      autoFocus
      restoreFocus={false}
    >
      <Toaster toastOptions={{ className: 'custom-toast', style: { zIndex: 1000 } }} />
      <CreateEditLayout
        onSave={handleSubmit(onSubmit)}
        onCancel={handleClose}
        confirmOnCancel
        pageTitle={selfId ? 'Edit Activity' : 'Create Activity'}
        loading={loading}
        showFinish={showFinish}
        template={template}
      >
        <>
          {template === 3 && (
            <div
              className={`card card-body border-gray-300 d-flex justify-content-between flex-row align-items-center 
              ${
                !showActivityInformation
                  ? 'sapp-add-activity-3-level-active'
                  : 'sapp-add-activity-3-level-inactive'
              }`}
            >
              <div className='sapp-created-content-name'>Activity Information</div>
              <div className='d-flex gap-3 justify-content-center align-items-center'>
                {idCreate && (
                  <ButtonIconOnly
                    iconName='notepad-edit'
                    iconType='outline'
                    bg={'sapp-custom-bg-transparent'}
                    iconGrayColor='500'
                    onClick={toggleEdit}
                  />
                )}
                {idCreate && (
                  <ButtonIconOnly
                    iconName='down'
                    iconType='outline'
                    bg={'sapp-custom-bg-transparent'}
                    activeColor='primary'
                    iconGrayColor='500'
                    onClick={toggleView}
                  />
                )}
              </div>
            </div>
          )}

          {!showActivityInformation && template === 3 && (
            <div className='sapp-px-24px sapp-pt-24px sapp-pb-24px border'>
              <div className='mb-10 fv-row'>
                <div className='mb-10'>
                  <HookFormTextField
                    control={control}
                    name='name'
                    label='Activity Name'
                    required
                    disabled={isDisabled}
                  />
                </div>
                {(data?.id || idCreate) && (
                  <HookFormTextField
                    control={control}
                    name='code'
                    disabled
                    label='Activity Code'
                    required
                  />
                )}
              </div>

              <div className='mb-10'>
                <SappLabel label='Duration' required />
                <Row className='align-items-start'>
                  <Col>
                    <HookFormTextField
                      disabled={onlyEditName || isDisabled}
                      control={control}
                      name='time.hours'
                      type='number'
                      onChange={() => {
                        if (isSubmitted) {
                          trigger('time.mins')
                        }
                      }}
                      postFix={<div className='sapp-post-fix-text-field '>h</div>}
                    />
                  </Col>
                  <Col>
                    <HookFormTextField
                      disabled={onlyEditName || isDisabled}
                      control={control}
                      name='time.mins'
                      type='number'
                      onChange={() => {
                        if (isSubmitted) {
                          trigger('time.hours')
                        }
                      }}
                      postFix={<div className='sapp-post-fix-text-field '>m</div>}
                    />
                  </Col>
                </Row>
              </div>

              <div className='mb-10 fv-row'>
                <div className='d-flex flex-column sapp-w-49'>
                  <HookFormSelectAntd
                    disabled={onlyEditName || isDisabled}
                    control={control}
                    name='display_icon'
                    options={[
                      { label: 'Text', value: TYPE_DOCUMENT.TEXT },
                      { label: 'Video', value: TYPE_DOCUMENT.VIDEO },
                      { label: 'Quiz', value: TYPE_DOCUMENT.QUIZ },
                      { label: 'Past Exam Analysis', value: TYPE_DOCUMENT.PAST_EXAM_ANALYSIS },
                    ]}
                    label='Type of Activity (Only display in student site)'
                    required
                    className='w-50 sapp-h-45px sapp-select-custom'
                    defaultValue={TYPE_DOCUMENT.TEXT}
                  />
                </div>
              </div>

              <div className='mb-10 fv-row'>
                <div className='d-flex flex-column'>
                  <SappLabel label='Grade Activity' required />
                  <HookFormRadioGroup
                    disabled={onlyEditName || isDisabled}
                    control={control}
                    name='is_graded'
                    options={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ]}
                    direction='horizontal'
                    justify='start'
                    separator={false}
                    gap={20}
                    defaultValue={'true'}
                  />
                </div>
              </div>

              <div className={'mb-4'}>
                <SappLabel label='Choose Learning Outcome' />
                <SAPPSelectLoadMore
                  disabled={onlyEditName || isDisabled}
                  onChange={(e) => {
                    setSelectedLearningOutcome(e)
                  }}
                  options={convertValue()}
                  size={'large'}
                  loadmore={false}
                  handleNextPage={() => {}}
                  placeholder='Choose Learning Outcome'
                  mode='multiple'
                  value={selectedLearningOutCome}
                  className='sapp-h-45px sapp-select-custom'
                />
              </div>

              {selectedLearningOutCome?.length > 0 && (
                <div className=' mb-8 fv-row'>
                  {selectedLearningOutCome?.map((e: string, i: number) => {
                    return (
                      <SelectedLearningOutCome
                        disabled={isDisabled}
                        id={e}
                        key={e}
                        handleDelete={handleDeleteSelected}
                        index={i}
                      />
                    )
                  })}
                </div>
              )}

              <div className='d-flex flex-column'>
                <UploadMulti
                  fileList={filesFormAddNew}
                  setFileList={handleOnchangeFile}
                  isScreenList={false}
                  error={errors.files}
                  resourceLocation={RESOURCE_LOCATION.COURSE_SECTION}
                  label='Resources'
                  disabled={onlyEditName || isDisabled}
                  guideline={[
                    'Định dạng cho phép pdf, docx, doc, xls, xlsx, csv, txt, ppt, pptx, zip. Kích thước tối đa 500MB.',
                  ]}
                  object_id={params?.id}
                />
              </div>
              <div className='d-flex flex-column gap-4 mt-3'>
                <div className='d-flex justify-content-end sapp-px-24px'>
                  {showButtonEdit ? (
                    <>
                      {(selfId || idCreate) && (
                        <ButtonSecondary title='Cancel' className='me-5' onClick={toggleView} />
                      )}
                      <ButtonPrimary
                        // className='btn btn-active-primary'
                        title='Save Information'
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
        {template !== 3 && (
          <>
            <div className='mb-10 fv-row'>
              <div className='mb-10 fv-row'>
                <HookFormTextField control={control} name='name' label='Activity Name' required />
              </div>
              {data?.id && (
                <HookFormTextField
                  control={control}
                  name='code'
                  disabled
                  label='Activity Code'
                  required
                />
              )}
            </div>

            <div className='mb-10'>
              <SappLabel label='Duration' required />
              <Row className='align-items-start'>
                <Col>
                  <HookFormTextField
                    disabled={onlyEditName}
                    control={control}
                    name='time.hours'
                    // placeholder='Hours'
                    type='number'
                    // disabled={!editAble}
                    onChange={() => {
                      if (isSubmitted) {
                        trigger('time.mins')
                      }
                    }}
                    // skeleton={skeleton}
                    postFix={<div className='sapp-post-fix-text-field '>h</div>}
                    // groupText='hours'
                  ></HookFormTextField>
                </Col>
                <Col>
                  <HookFormTextField
                    disabled={onlyEditName}
                    control={control}
                    name='time.mins'
                    // placeholder='Mins'
                    type='number'
                    // disabled={!editAble}
                    onChange={() => {
                      if (isSubmitted) {
                        trigger('time.hours')
                      }
                    }}
                    // skeleton={skeleton}
                    postFix={<div className='sapp-post-fix-text-field '>m</div>}
                  ></HookFormTextField>
                </Col>
              </Row>
            </div>

            <div className='mb-10 fv-row'>
              <div className='d-flex flex-column sapp-w-49'>
                {/* <div className='sapp-title-checkbox-activity'>
            Type of Activity (Only display in student site)
          </div> */}
                <HookFormSelectAntd
                  disabled={onlyEditName}
                  control={control}
                  name='display_icon'
                  options={[
                    { label: 'Text', value: TYPE_DOCUMENT.TEXT },
                    { label: 'Video', value: TYPE_DOCUMENT.VIDEO },
                    { label: 'Quiz', value: TYPE_DOCUMENT.QUIZ },
                    { label: 'Past Exam Analysis', value: TYPE_DOCUMENT.PAST_EXAM_ANALYSIS },
                  ]}
                  label='Type of Activity (Only display in student site)'
                  required
                  className='w-50 sapp-h-45px sapp-select-custom'
                  defaultValue={TYPE_DOCUMENT.TEXT}
                />
              </div>
            </div>

            <div className='mb-10 fv-row'>
              <div className='d-flex flex-column'>
                <SappLabel label='Grade Activity' required />
                <HookFormRadioGroup
                  disabled={onlyEditName}
                  control={control}
                  name='is_graded'
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  direction='horizontal'
                  justify='start'
                  separator={false}
                  gap={20}
                  defaultValue={'true'}
                />
              </div>
            </div>

            <div className={'mb-4'}>
              <SappLabel label='Choose Learning Outcome' />
              <SAPPSelectLoadMore
                disabled={onlyEditName}
                onChange={(e) => {
                  setSelectedLearningOutcome(e)
                }}
                options={convertValue()}
                size={'large'}
                loadmore={false}
                handleNextPage={() => {}}
                placeholder='Choose Learning Outcome'
                mode='multiple'
                value={selectedLearningOutCome}
                className='sapp-h-45px sapp-select-custom'
              />
            </div>
            {selectedLearningOutCome?.length > 0 && (
              <div className=' mb-8 fv-row'>
                {selectedLearningOutCome?.map((e: any, i: number) => {
                  return (
                    <SelectedLearningOutCome
                      id={e}
                      key={e}
                      handleDelete={handleDeleteSelected}
                      index={i}
                    />
                  )
                })}
              </div>
            )}
            <div className='d-flex flex-column'>
              <UploadMulti
                fileList={filesFormAddNew}
                setFileList={handleOnchangeFile}
                isScreenList={false}
                error={errors.files}
                resourceLocation={RESOURCE_LOCATION.COURSE_SECTION}
                label='Resources'
                disabled={onlyEditName}
                guideline={[
                  'Định dạng cho phép pdf, docx, doc, xls, xlsx, csv, txt, ppt, pptx, zip. Kích thước tối đa 500MB.',
                ]}
                object_id={params?.id}
              />
            </div>
          </>
        )}

        <>
          {template === 3 && showTab && (
            <>
              <div
                className={`card card-body border-gray-300 d-flex justify-content-between flex-row align-items-center mt-5
              ${
                showListDoc
                  ? 'sapp-add-activity-3-level-active'
                  : 'sapp-add-activity-3-level-inactive'
              }`}
              >
                <div className='sapp-created-content-name'>List Documents</div>
                <div className='d-flex gap-3 justify-content-center align-items-center'>
                  <ButtonIconOnly
                    iconName='down'
                    iconType='outline'
                    bg={'sapp-custom-bg-transparent'}
                    activeColor='primary'
                    iconGrayColor='500'
                    onClick={toggleViewListDoc}
                  />
                </div>
              </div>
              {showListDoc && (
                <div className='sapp-px-24px sapp-pt-24px sapp-pb-24px border'>
                  <CreateTabs
                    open={true}
                    courseCondition={courseCondition}
                    setOpen={setOpenCreateTab}
                    course_section_id={selfId ?? (idCreate as string)}
                    setTreeData={treeData}
                    path={openCreateTab.path}
                    treeData={treeData}
                    nodeData={openCreateTab.nodeData}
                    updateFinishButton={setShowFinish}
                    showActivityInformation={showActivityInformation}
                  />
                </div>
              )}
            </>
          )}
        </>
      </CreateEditLayout>
    </Modal>
  )
}
export default CreateActivity
