import React, { useEffect } from 'react'
import './Tab.scss'
import TabContent from './tab/tabContent'
import { CourseCondition } from 'src/type/courses'
import TabContentMedium from 'src/components/courses/course-medium/create-tabs/tab/tabContent'
import { useLocation } from 'react-router-dom'
import { ITabContent } from 'src/type/course-medium/section'

interface IProps {
  data: ITabContent[]
  setTabActive: React.Dispatch<React.SetStateAction<string>>
  tabActive: string
  setData: any
  handleAddTab: any
  closeModal: any
  course_section_id: string
  edit?: boolean
  isFistLoad?: boolean
  courseCondition: CourseCondition
  updateFinishButton?: (newValue: boolean) => void
  showActivityInformation?: boolean
}
const Tab = ({
  data,
  setTabActive,
  tabActive,
  setData,
  handleAddTab,
  closeModal,
  course_section_id,
  isFistLoad,
  courseCondition,
  updateFinishButton,
  showActivityInformation,
}: IProps) => {
  const templateUrl = useLocation()
  const template = templateUrl.pathname.includes('/course-medium/') ? 3 : 4
  useEffect(() => {
    if (isFistLoad) {
      handleAddTab()
    } else {
      setTabActive(data[0]?.tabId || '')
    }
  }, [isFistLoad])
  return (
    <>
      <div className='tab-content' id='myTabContent'>
        {data.map((e, i) =>
          template === 3 ? (
            <TabContentMedium
              closeModal={closeModal}
              course_section_id={course_section_id}
              data={data}
              tabIndex={i}
              tabData={e}
              setData={setData}
              setTabActive={setTabActive}
              tabActive={tabActive}
              courseCondition={courseCondition}
              key={i}
              updateFinishButton={updateFinishButton}
              showActivityInformation={showActivityInformation}
            />
          ) : (
            <TabContent
              closeModal={closeModal}
              course_section_id={course_section_id}
              data={data}
              tabIndex={i}
              tabData={e}
              setData={setData}
              setTabActive={setTabActive}
              tabActive={tabActive}
              courseCondition={courseCondition}
              key={i}
            />
          )
        )}
      </div>
    </>
  )
}
export default Tab
