import React, { PropsWithChildren } from 'react'
import './DocumentContainer.scss'
interface IProps {
  title: string
}
const DocumentContainer = (props: PropsWithChildren<IProps>) => {
  const { title, children } = props
  return (
    <div className='border-solid rounded border border-gray-300 sapp-py-24px mb-5'>
      <div className='d-flex justify-content-between align-items-start sapp-px-24px border-bottom'>
        <div className='d-flex'>
          <div
            className={`bg-blue-500 text-gray-800 pb-7 focus:outline-none sapp-fs-14 fw-semibold sapp-lh-16px sapp-border-title`}
          >
            {title}
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default DocumentContainer
