import { Dispatch, SetStateAction, useState } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import LoadingTable from 'src/common/LoadingTable'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import { HEADER_CACHE_LIST } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { ICache } from 'src/type/cache'
import { Metadata } from 'src/type/common'
import { INotificationRes } from 'src/type/notification'
import CacheModal from '../modals/CacheModal'
import { CacheButton } from './CacheButton'
import './CacheTable.scss'
import { CacheAPI } from 'src/apis/cache'

interface IProps {
  data: ICache[]
  setData: any
  fetchList: any
  metadata: Metadata
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  notiList?: {
    notifications: INotificationRes[]
    meta: {
      total_pages: number
      total_records: number
      page_index: number
      page_size: number
    }
  }
  loading?: boolean
  setLoading?: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchNotiList?: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams?: Object
  pageSize?: number
  getParams?: any
  queryParams?: any
  checkedList?: any
  toggleCheck?: any
  toggleCheckAll?: any
  isCheckedAll?: boolean
}
const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Cache name',
    className: 'min-w-350px pe-5 ',
  },
  {
    label: 'Endpoint',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Action',
    className: 'text-end min-w-30px pe-5',
  },
]

const CacheTable = ({
  data,
  setData,
  isCheckedAll,
  fetchList,
  metadata,
  loading = false,
  notiList,
  queryParams,
  setLoading,
  toggleCheck,
  toggleCheckAll,
  handleChangeParams,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const [resetLoading, setResetLoading] = useState(false)
  const [openCacheModal, setOpenCacheModal] = useState<{
    is_open: boolean
    data?: ICache
  }>({
    is_open: false,
  })

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchList(page_index, page_size)
  }

  const resetAllCache = async () => {
    setResetLoading(true)
    try {
      await CacheAPI.deleteAllCaching()
    } catch (error) {
    } finally {
      setResetLoading(false)
    }
  }

  return (
    <div className='card-body py-10 px-0'>
      {contextHolder}
      <div className='px-10 pb-8 d-flex justify-content-between align-items-center'>
        <h2 className='sapp-fs-20px'>List of cache</h2>
        <ButtonIcon title={'Clear All'} isListScreen onClick={resetAllCache} loading={resetLoading}>
          <KTIcon iconName='arrows-circle' className='fs-3 pe-3' iconType='solid' />
        </ButtonIcon>
      </div>

      <SappTable
        headers={headers}
        loading={loading}
        data={data}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
        classResponsive={'px-10'}
      >
        {loading ? (
          <>
            {HEADER_CACHE_LIST.map((header, i) => (
              <LoadingTable key={header} headers={headers} />
            ))}
          </>
        ) : (
          <>
            {data.map((e: ICache, index: number) => {
              return (
                <tr className='position-relative' key={e.id}>
                  <td>{index + 1}</td>
                  <td className='text-start'>
                    <span
                      className='text-gray-800 cursor-pointer text-hover-primary'
                      onClick={() => {
                        setOpenCacheModal({
                          is_open: true,
                          data: e,
                        })
                      }}
                    >
                      {e.name}
                    </span>
                  </td>
                  <td className='text-start sapp-text-light-inverse'>{e.endpoint}</td>
                  <td className='text-end sapp-text-light-inverse pe-5 min-width-250px'>
                    <CacheButton cache={e} confirm={confirm} key={e.id} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>

      <div className='px-10'>
        <PagiantionSAPP
          currentPage={metadata.page_index}
          pageSize={metadata.page_size || 10}
          totalItems={metadata.total_records}
          handlePaginationChange={handlePaginationChange}
        />
      </div>
      <div className='cache-document mx-10 d-flex align-items-center'>
        <KTIcon iconName='abstract-41' className='cache-document-icon' iconType='duotone' />
        <p className='cache-document-text'>
          Xem tài liệu caching cho hệ thống LMS và OPS,
          <a className='cache-document-link' href='/'>
            {' '}
            tại đây.
          </a>
        </p>
      </div>

      <CacheModal
        open={openCacheModal?.is_open}
        setOpen={setOpenCacheModal}
        data={openCacheModal?.data}
        trigger={() => handlePaginationChange(metadata.page_index, metadata.page_size || 10)}
      />
    </div>
  )
}

export { CacheTable }
