import { useLocation, useParams } from 'react-router-dom'
import { EXAM_DETAIL_URL } from 'src/constants/exam'
import ExamOverview from './ExamOverview'
import ExamSetting from './ExamSetting'
import ExamStudents from './ExamStudents'

const ExamTabs = () => {
  const location = useLocation()
  const { id } = useParams()

  const examComponents = {
    [EXAM_DETAIL_URL.OVERVIEW]: <ExamOverview />,
    [EXAM_DETAIL_URL.SETTING]: <ExamSetting />,
    [EXAM_DETAIL_URL.STUDENTS]: <ExamStudents />,
  }
  const pathSegment = location.pathname.split(`/${id}/`)[1]
  const tab = examComponents[pathSegment] || <ExamOverview />

  return <div className='card mb-5 mb-xl-10'>{tab}</div>
}

export default ExamTabs
