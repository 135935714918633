import { Select } from 'antd'
import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import { BUTTON_TEXT } from 'src/constants/lang'
import HookFormSelectAntd from '../../select/HookFormSelectAntd'
import { useParams } from 'react-router-dom'
import ModalImport from '../ModalImport'
import { beforeUpload } from 'src/utils'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

type Props = {
  form: UseFormReturn<any>
  getImportLogs: ({
    text,
    status,
    object_type,
    page_index,
    page_size,
  }: {
    text?: string | undefined
    status?: string | undefined
    object_type?: string
    page_index: number
    page_size: number
  }) => Promise<void>
  meta?: {
    total_pages: number
    total_records: number
    page_index: number
    page_size: number
  }
  textSearch: string
  status: string
  object_type: string
}

export enum JOB_STATUS {
  'PROCESSING' = 'PROCESSING',
  'FAILED' = 'FAILED',
  'SUCCESSFUL' = 'SUCCESSFUL',
  'PENDING' = 'PENDING',
}

const jobStatus = [
  {
    label: 'Processing',
    value: 'PROCESSING',
  },
  {
    label: 'Failed',
    value: 'FAILED',
  },
  {
    label: 'Successful',
    value: 'SUCCESSFUL',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
]

const programs = [
  {
    label: 'CFA',
    value: 'CFA_EXAMINATION',
  },
  {
    label: 'ACCA',
    value: 'ACCA_EXAMINATION',
  },
  {
    label: 'CMA',
    value: 'CMA_EXAMINATION',
  },
]

const FilterHeader = ({ form, meta, getImportLogs, status, textSearch, object_type }: Props) => {
  const { control, getValues, reset, setValue } = form
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const [open, setOpen] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    setTimeout(() => {
      getImportLogs({
        page_index: 1,
        page_size: meta?.page_size ?? 10,
        text: getValues('text'),
        status: getValues('status'),
        ...(getValues('object_type') &&
          params.type === 'exam' && { object_type: getValues('object_type') }),
      }).finally(() => setLoading(false))
    })
  }

  const onReset = () => {
    setLoading(true)
    setTimeout(() => {
      reset()
      setValue('text', '')
      setValue('status', '')

      getImportLogs({
        page_index: 1,
        page_size: 10,
        text: '',
        status: '',
        ...(params.type === 'exam' && { object_type: undefined }),
      }).finally(() => setLoading(false))
    })
  }

  return (
    <div className='px-9'>
      <ListFilterLayout className='pt-9'>
        <HookFormTextField
          name='text'
          control={control}
          defaultValue={textSearch ?? ''}
          placeholder='Search'
          isListScreen
          onSubmit={onSubmit}
        />
        <HookFormSelectAntd
          name={'status'}
          control={control}
          dropdownStyle={{ minWidth: 'fit-content' }}
          placeholder={'Status'}
          showSearch
          defaultValue={status ?? ''}
          classNameHeight='sapp-h-40'
          options={jobStatus}
        />
        {params.type === 'exam' && (
          <HookFormSelectAntd
            name={'object_type'}
            control={control}
            dropdownStyle={{ minWidth: 'fit-content' }}
            placeholder={'Program'}
            showSearch
            defaultValue={object_type ?? undefined}
            classNameHeight='sapp-h-40'
            allowClear
            options={programs}
          />
        )}
      </ListFilterLayout>
      <div className='border-0 pt-9'>
        <div className='d-flex justify-content-between'>
          <SAPPFIlterButton
            titleReset='Reset'
            titleSubmit={BUTTON_TEXT.SEARCH}
            okClick={onSubmit}
            resetClick={onReset}
            disabled={loading}
            loading={loading}
          />
          {params.type === 'SCHEDULE_HOLIDAY' && (
            <ButtonIconPrimary
              onClick={() => setOpen(true)}
              iconName='file-up'
              title='Import'
              size='small'
            />
          )}
        </div>
        <ModalImport
          open={open}
          setOpen={setOpen}
          title='Import Holiday'
          templateFile='/IMPORT_HOLIDAY_SCHEDULE.xlsx'
          templateFileName='filemau.xlsx'
          uploadUrl='holiday-schedules/import'
          beforeUpload={(file) => beforeUpload(file)}
          refetch={() => {}}
          type='SCHEDULE_HOLIDAY'
        ></ModalImport>
      </div>
    </div>
  )
}

export default FilterHeader
