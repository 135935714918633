import { Col, Row, Select, Skeleton } from 'antd'
import clsx from 'clsx'
import {
  Control,
  Controller,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form'
import toast from 'react-hot-toast'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import { ICourseCategories, ICourseCategory, PROGRAM } from 'src/type/courses'
import styles from './TeacherPostForm.module.scss'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import { courseCategoryQueryKeys, Pagination } from 'src/constants'
import { TEACHER_PRIORITY_OPTIONS, TEACHER_PRIORITY_STATUS } from 'src/constants/users'
import { ITeacher } from 'src/type/teacher'
import SubjectCollapse from './SubjectCollapse'
import SubjectTemplate from './SubjectTemplate'

interface Iprops {
  control: Control<ITeacher, any>
  setValue: UseFormSetValue<ITeacher>
  setError: UseFormSetError<ITeacher>
  watch: UseFormWatch<ITeacher>
}

const Subjects = ({ control, setError, watch, setValue }: Iprops) => {
  const category_primary_id = watch('category_primary_id')
  const { id } = useParams()
  const { data, isLoading } = useQuery<ICourseCategories, unknown, (ICourseCategory | undefined)[]>(
    courseCategoryQueryKeys.list({
      page_index: Pagination.INDEX,
      page_size: Pagination.SIZE,
      params: { with_children: true },
    }),
    async () => {
      const res = await CoursesAPI.getCategory({
        page_index: Pagination.INDEX,
        page_size: Pagination.SIZE,
        params: {
          with_children: true,
          names: [PROGRAM.CFA, PROGRAM.CMA, PROGRAM.ACCA, PROGRAM.CERT_DIP],
        },
      })
      return res?.data
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      select: (data) => {
        const [cfa, cma, acca, certDip] = [
          PROGRAM.CFA,
          PROGRAM.CMA,
          PROGRAM.ACCA,
          PROGRAM.CERT_DIP,
        ].map((program) => data?.course_categories.find((item) => item.name === program))
        return [cfa, cma, acca, certDip]
      },
    }
  )
  const [cfa, cma, acca, certDip] = data || []

  const handleRadioChange = (value: string) => {
    if (value === cfa?.id || value === cma?.id) {
      const selectedItems = teacherTeachableInstancesForm?.filter(
        (instance) => instance.category_id === value
      )

      const validateSelection = () => {
        const isValid = selectedItems && selectedItems.length > 0
        if (!isValid) {
          setError(`teacher_teachable_instances.${1}.feature_sections`, {
            message: 'One subject must be selected',
          })
        }
        return isValid
      }

      // Validate the selection based on the value
      const isValid = validateSelection()

      if (isValid) {
        setValue('category_primary_id', value)
      }
    }

    // ACCA specific validation
    if (value === acca?.id) {
      const selectedItems = teacherTeachableInstancesForm?.filter(
        (instance) => instance.category_id === acca.id && instance.subject_id !== ''
      )

      const validateSelection = () => {
        const isValid = selectedItems && selectedItems.length > 0
        if (!isValid) {
          setError(`teacher_teachable_instances.${1}.feature_sections`, {
            message: 'One subject must be selected',
          })
        }
        return isValid
      }

      // Validate the selection based on the value
      const isValid = validateSelection()

      if (isValid) {
        setValue('category_primary_id', value)
      }
    }
    // Cert/Dip specific validation
    if (value === certDip?.id) {
      const selectedItems = teacherTeachableInstancesForm?.filter(
        (instance) => instance.category_id === certDip.id && instance.subject_id !== ''
      )

      const validateSelection = () => {
        const isValid = selectedItems && selectedItems.length > 0
        if (!isValid) {
          setError(`teacher_teachable_instances.${1}.feature_sections`, {
            message: 'One subject must be selected',
          })
        }
        return isValid
      }

      // Validate the selection based on the value
      const isValid = validateSelection()

      if (isValid) {
        setValue('category_primary_id', value)
      }
    }
  }

  const cfaLength =
    cfa?.subject_instances?.filter((subject) => subject.feature_section_instances.length > 0)
      .length || 0
  const cmaLength =
    cma?.subject_instances?.filter((subject) => subject.feature_section_instances.length > 0)
      .length || 0
  const accaLength =
    acca?.subject_instances?.filter((subject) => subject.feature_section_instances.length > 0)
      .length || 0

  const teacherTeachableInstancesForm = useWatch({
    control,
    name: 'teacher_teachable_instances', // Name of the field you want to watch
  })

  if (isLoading) return <Skeleton />

  return (
    <>
      {/* CFA */}
      <SubjectTemplate
        title='CFA'
        control={control}
        id={id}
        category={cfa}
        handleRadioChange={() => handleRadioChange(cfa?.id || '')}
      >
        {cfa?.subject_instances
          ?.filter((subject) => subject.feature_section_instances.length > 0)
          ?.map((subject, index) => {
            return (
              <SubjectCollapse
                category={cfa}
                control={control}
                index={index}
                setValue={setValue}
                subject={subject}
                watch={watch}
                key={subject.id}
              />
            )
          })}
      </SubjectTemplate>

      {/* CMA */}
      <SubjectTemplate
        title='CMA'
        control={control}
        id={id}
        category={cma}
        handleRadioChange={() => handleRadioChange(cma?.id || '')}
      >
        {cma?.subject_instances
          ?.filter((subject) => subject.feature_section_instances.length > 0)
          ?.map((subject, index) => {
            const offset = index + cfaLength
            return (
              <SubjectCollapse
                category={cma}
                index={offset}
                control={control}
                setValue={setValue}
                subject={subject}
                watch={watch}
                key={subject.id}
              />
            )
          })}
      </SubjectTemplate>

      {/* ACCA */}
      <SubjectTemplate
        title='ACCA'
        control={control}
        id={id}
        category={acca}
        handleRadioChange={() => handleRadioChange(acca?.id || '')}
      >
        {acca?.subject_instances?.map((subject, index) => {
          const offset = cfa && cma ? index + cfaLength + cmaLength : index
          const featureSectionId = watch(
            `teacher_teachable_instances.${offset}.feature_sections.${0}.feature_section_id`
          )

          return (
            subject.feature_section_instances.length > 0 && (
              <Row
                align={'middle'}
                justify={'space-between'}
                key={subject.id}
                className='w-100'
                gutter={12}
              >
                <Col span={featureSectionId ? 16 : 24}>
                  <Controller
                    name={`teacher_teachable_instances.${offset}.subject_id`}
                    control={control}
                    key={subject.id}
                    render={({ field }) => {
                      return (
                        <label
                          className={clsx('d-flex gap-2 cursor-pointer', styles.sectionCheckbox)}
                          key={subject.id}
                          htmlFor={subject.id}
                        >
                          <SAPPCheckbox
                            checked={field.value === subject.id || false}
                            onChange={(e) => {
                              const updatedValue = e.target.checked ? subject.id : '' //* Assign section.id or clear the value
                              const selectedInstances = teacherTeachableInstancesForm
                                ?.filter(
                                  (instance) =>
                                    (instance.category_id === acca?.id &&
                                      instance.subject_id !== '') ||
                                    undefined
                                )
                                ?.reduce(
                                  (acc, current) => {
                                    return {
                                      subject_ids: [...acc.subject_ids, current.subject_id],
                                      category_id: current.category_id, //* Assuming all entries have the same category_id
                                    }
                                  },
                                  { subject_ids: [] as string[], category_id: '' }
                                )

                              const isLastChecked =
                                e.target.checked === false &&
                                selectedInstances?.subject_ids.length === 1 &&
                                selectedInstances.category_id === category_primary_id

                              if (isLastChecked) {
                                toast.error(
                                  `You cannot uncheck the last selected item of this Primary Responsibility`,
                                  {
                                    duration: 6000,
                                  }
                                )
                                return
                              }

                              if (updatedValue) {
                                setValue(
                                  `teacher_teachable_instances.${offset}.category_id`,
                                  acca.id
                                )
                                setValue(
                                  `teacher_teachable_instances.${offset}.feature_sections.${0}.feature_section_id`,
                                  subject.feature_section_instances[0].id
                                )
                                setValue(
                                  `teacher_teachable_instances.${offset}.feature_sections.${0}.priority_status`,
                                  TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN
                                )
                              } else {
                                setValue(`teacher_teachable_instances.${offset}.category_id`, '')
                                setValue(
                                  `teacher_teachable_instances.${offset}.feature_sections.${0}.feature_section_id`,
                                  ''
                                )
                                setValue(
                                  `teacher_teachable_instances.${offset}.feature_sections.${0}.priority_status`,
                                  ''
                                )
                              }

                              field.onChange(updatedValue) // Update form state with a single value
                            }}
                            id={subject.id}
                          />
                          <span className={clsx('flex-grow-1', styles.strong)}>{subject.name}</span>
                        </label>
                      )
                    }}
                  />
                </Col>
                {featureSectionId && (
                  <Col span={8}>
                    <Controller
                      name={`teacher_teachable_instances.${index}.feature_sections.${0}.priority_status`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            options={TEACHER_PRIORITY_OPTIONS}
                            style={{ width: '100%' }}
                            defaultValue={TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN}
                            size='small'
                            className={styles.select}
                            onChange={(value) => field.onChange(value)}
                          />
                        )
                      }}
                    />
                  </Col>
                )}
              </Row>
            )
          )
        })}
      </SubjectTemplate>

      <SubjectTemplate
        title='Cert/Dip'
        control={control}
        id={id}
        category={certDip}
        handleRadioChange={() => handleRadioChange(certDip?.id || '')}
      >
        {certDip?.subject_instances?.map((subject, index) => {
          const offset = cfa && cma && acca ? index + cfaLength + cmaLength + accaLength : index
          const featureSectionId = watch(
            `teacher_teachable_instances.${offset}.feature_sections.${0}.feature_section_id`
          )

          return (
            subject.feature_section_instances.length > 0 && (
              <Row
                align={'middle'}
                justify={'space-between'}
                key={subject.id}
                className='w-100'
                gutter={12}
              >
                <Col span={featureSectionId ? 16 : 24}>
                  <Controller
                    name={`teacher_teachable_instances.${offset}.subject_id`}
                    control={control}
                    key={subject.id}
                    render={({ field }) => {
                      return (
                        <label
                          className={clsx('d-flex gap-2 cursor-pointer', styles.sectionCheckbox)}
                          key={subject.id}
                          htmlFor={subject.id}
                        >
                          <SAPPCheckbox
                            checked={field.value === subject.id || false}
                            onChange={(e) => {
                              const updatedValue = e.target.checked ? subject.id : '' //* Assign section.id or clear the value
                              const selectedInstances = teacherTeachableInstancesForm
                                ?.filter(
                                  (instance) =>
                                    (instance.category_id === acca?.id &&
                                      instance.subject_id !== '') ||
                                    undefined
                                )
                                ?.reduce(
                                  (acc, current) => {
                                    return {
                                      subject_ids: [...acc.subject_ids, current.subject_id],
                                      category_id: current.category_id, //* Assuming all entries have the same category_id
                                    }
                                  },
                                  { subject_ids: [] as string[], category_id: '' }
                                )

                              const isLastChecked =
                                e.target.checked === false &&
                                selectedInstances?.subject_ids.length === 1 &&
                                selectedInstances.category_id === category_primary_id

                              if (isLastChecked) {
                                toast.error(
                                  `You cannot uncheck the last selected item of this Primary Responsibility.`,
                                  {
                                    duration: 6000,
                                  }
                                )
                                return
                              }

                              if (updatedValue) {
                                setValue(
                                  `teacher_teachable_instances.${offset}.category_id`,
                                  certDip.id
                                )
                                setValue(
                                  `teacher_teachable_instances.${offset}.feature_sections.${0}.feature_section_id`,
                                  subject.feature_section_instances[0].id
                                )
                                setValue(
                                  `teacher_teachable_instances.${offset}.feature_sections.${0}.priority_status`,
                                  TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN
                                )
                              } else {
                                setValue(`teacher_teachable_instances.${offset}.category_id`, '')
                                setValue(
                                  `teacher_teachable_instances.${offset}.feature_sections.${0}.feature_section_id`,
                                  ''
                                )
                                setValue(
                                  `teacher_teachable_instances.${offset}.feature_sections.${0}.priority_status`,
                                  ''
                                )
                              }

                              field.onChange(updatedValue) // Update form state with a single value
                            }}
                            id={subject.id}
                          />
                          <span className={clsx('flex-grow-1', styles.strong)}>{subject.name}</span>
                        </label>
                      )
                    }}
                  />
                </Col>
                {featureSectionId && (
                  <Col span={8}>
                    <Controller
                      name={`teacher_teachable_instances.${index}.feature_sections.${0}.priority_status`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            options={TEACHER_PRIORITY_OPTIONS}
                            style={{ width: '100%' }}
                            defaultValue={TEACHER_PRIORITY_STATUS.NO_LESSON_PLAN}
                            size='small'
                            className={styles.select}
                            onChange={(value) => field.onChange(value)}
                          />
                        )
                      }}
                    />
                  </Col>
                )}
              </Row>
            )
          )
        })}
      </SubjectTemplate>
    </>
  )
}

export default Subjects
