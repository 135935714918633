import { useEffect } from 'react'
import StudentProfileDetail from './StudentProfileDetail'

const OverviewStudentProfile = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <StudentProfileDetail />
}

export default OverviewStudentProfile
