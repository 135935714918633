import { Select, TablePaginationConfig } from 'antd'
import { isEmpty } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClassesApi } from 'src/apis/classes'
import SappTooltip, { SappTooltipArray } from 'src/common/SappTooltip'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import ClassesAction from 'src/components/classes/ClassAction'
import ModalProcessing from 'src/components/classes/modalProcessing'
import {
  CLASS_PROFILE,
  CLASS_PROFILE_URL,
  OPTIONS_OF_DURATION,
  OPTIONS_OF_STATUS,
  OPTIONS_OF_STATUS_FILTER,
} from 'src/constants/classes'
import { TITLE_CLASS_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusCourse } from 'src/type/courses'
import { formatDate, formatDateTime } from 'src/utils'
import BlockClass from './blockClass/blockClass'
import UpdateDuration from './updateDuration/updateDuration'
import SappTable from 'src/components/ui/table/SappTable'
import { hasPermission } from 'src/utils/permission'
import { IClassList } from 'src/type/classes'
import { EDateTime } from 'src/type'

const { Option } = Select

interface IProps {
  classList: IClassList[]
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchClassList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  queryParams: Record<string, any>
  pagination: TablePaginationConfig
  setPagination: Dispatch<SetStateAction<TablePaginationConfig>>
  cleanedParams?: Record<string, any>
}

const TableClasses = ({
  classList,
  loading,
  setLoading,
  filterParams,
  fetchClassList,
  queryParams,
  pagination,
  setPagination,
  cleanedParams,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const navigate = useNavigate()
  const [openBlock, setOpenBlock] = useState<{ status: boolean; data?: any }>({ status: false })
  const [openUpdateDuration, setOpenUpdateDuration] = useState<{ status: boolean; data?: any }>({
    status: false,
  })
  const [openEditCourseContent, setOpenEditCourseContent] = useState<{
    status: boolean
    type?: string
    data?: any
  }>({ status: false })

  // Using validate for input
  const { control, watch, setValue } = useForm<any>()

  const hanleChangeStatus = (value: string, data: any) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: async () => {
        try {
          const payload = {
            id: data.id,
            status: value,
          }
          await ClassesApi.updateClass(payload)
          toast.success('update classes status succeded')
          fetchClassList(page_index || 1, page_size || 10, filterParams)
        } catch (err: any) {
          fetchClassList(page_index || 1, page_size || 10, filterParams)
        }
      },

      onClose: () => {
        setLoading(true)
        fetchClassList(page_index || 1, page_size || 10, filterParams)
      },
    })
  }

  const handleChange = (value: string, id: string, data: object) => {
    if (value === 'BLOCKED') {
      setOpenBlock({ status: true, data: id })
      return
    }
    hanleChangeStatus(value, data)
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      class_code: queryParams?.class_code ?? '',
      class_name: queryParams.class_name ?? '',
      course_category_id: queryParams?.course_category_id ?? '',
      status: queryParams.status ?? '',
      facility_id: queryParams.facility_id ?? '',
      sortType: queryParams.sortType ?? '',
      instruction_mode: queryParams.instruction_mode ?? '',
      class_type: queryParams.class_type ?? '',
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
      subject: queryParams.subject,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const Action = (type: string, data: any) => {
    if (type === 'edit') {
      navigate(`${CLASS_PROFILE}/${data.id}/${CLASS_PROFILE_URL.SETTING}`)
    }
    if (type === 'duration') {
      setOpenUpdateDuration({ status: true, data: data })
      return
    }
    if (type === 'content') {
      setOpenEditCourseContent({ status: true, type: 'course-content', data: data })
    }
  }

  const { profileMe } = useUserContext()

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_CLASS_GR.EDIT_CLASS)
  const allowRenderEditCourseContent = hasPermission(
    profileMe?.roles,
    TITLE_CLASS_GR.EDIT_COURSE_CONTENT_OF_STUDENT_IN_CLASS
  )

  const columns = [
    {
      title: '#',
      render: (_: IClassList, record: IClassList, index: number) =>
        index + 1 + ((pagination?.current || 1) - 1) * (pagination?.pageSize || 10),
    },
    {
      title: 'Class',
      render: (_: IClassList, record: IClassList) => (
        <SappTooltip
          title={record?.name}
          link={`${CLASS_PROFILE}/${record?.id}/${CLASS_PROFILE_URL.OVERVIEW}`}
        />
      ),
    },
    {
      title: 'Code',
      render: (_: IClassList, record: IClassList) => record?.code,
    },
    {
      title: 'Class Foundation',
      render: (_: IClassList, record: IClassList) => (
        <>
          {!isEmpty(record?.normal_class_connections)
            ? record?.normal_class_connections?.map((el) => {
                return (
                  <SappTooltipArray
                    showManyTooltip
                    node={record?.normal_class_connections?.map((item) => (
                      <div>{item?.foundation_class?.name}</div>
                    ))}
                    title={el?.foundation_class?.name}
                    key={el.foundation_class?.id}
                    link={`/classes/detail/${el?.foundation_class?.id}/overview`}
                  />
                )
              })
            : '--'}
        </>
      ),
    },
    {
      title: 'Number Of Students',
      render: (_: IClassList, record: IClassList) => record?.total_students,
    },
    {
      title: 'Status',
      render: (_: IClassList, record: IClassList, index: number) => (
        <>
          {watch(`status${index}`) === 'ENDED' ? (
            <HookFormSelectAntd
              allowClear={false}
              size='large'
              name={`status${index}`}
              control={control}
              dropdownStyle={{ maxWidth: 100 }}
              placeholder='Status'
              filterOption={true}
              onChange={(selectedValue: unknown) =>
                handleChange(selectedValue as string, record.id, record)
              }
              disabled={watch(`status${index}`) === 'ENDED'}
              classNameHeight='sapp-h-35px'
              isLocked={watch(`status${index}`) === 'ENDED'}
              options={OPTIONS_OF_STATUS_FILTER}
            />
          ) : (
            <HookFormSelectAntd
              allowClear={false}
              size='large'
              name={`status${index}`}
              control={control}
              dropdownStyle={{ maxWidth: 100 }}
              placeholder='Status'
              filterOption={true}
              onChange={(selectedValue: unknown) =>
                handleChange(selectedValue as string, record.id, record)
              }
              disabled={
                watch(`status${index}`) === EStatusCourse.LOCK ||
                (record?.is_student_studying_normal_course && record?.status === 'PUBLIC') ||
                !allowRenderEdit
              }
              classNameHeight='sapp-h-35px'
              isLocked={
                watch(`status${index}`) === EStatusCourse.LOCK ||
                (record?.is_student_studying_normal_course && record?.status === 'PUBLIC') ||
                !allowRenderEdit
              }
              options={OPTIONS_OF_STATUS}
            />
          )}
        </>
      ),
    },
    {
      title: 'Course',
      render: (_: IClassList, record: IClassList) => record?.course?.name,
    },
    {
      title: 'Subjects',
      render: (_: IClassList, record: IClassList) => record?.course?.course_categories[0]?.name,
    },
    {
      title: 'Exam',
      render: (_: any, record: any) => record?.examination_subject?.examination?.name ?? '--',
    },
    {
      title: 'Duration',
      render: (_: IClassList, record: IClassList) => (
        <>
          {record?.duration_type !== OPTIONS_OF_DURATION.valueFlexible ? (
            <div>
              {(() => {
                if (record?.started_at && record?.finished_at) {
                  const fromDate = new Date(record?.started_at)
                  const toDate = new Date(record?.finished_at)
                  return `${formatDateTime(fromDate, EDateTime.dateFormat)} - ${formatDateTime(
                    toDate,
                    EDateTime.dateFormat
                  )}`
                } else {
                  return '--'
                }
              })()}
            </div>
          ) : (
            <div>{record?.flexible_days ? `${record?.flexible_days} days` : '--'}</div>
          )}
        </>
      ),
    },
    {
      title: '',
      fixed: 'right',
      render: (_: IClassList, record: IClassList, index: number) => (
        <>
          {(allowRenderEdit || allowRenderEditCourseContent) &&
            (watch(`status${index}`) === 'ENDED' ? (
              <></>
            ) : (
              <ClassesAction Action={Action} data={record} />
            ))}
        </>
      ),
    },
  ]

  useEffect(() => {
    classList
      ?.map((e) => ({ label: e.status, value: e.status }))
      ?.forEach((option, index: number) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, classList])

  // State lưu trữ dữ liệu section của khóa học
  const [courseSectionData, setCourseSectionData] = useState<
    | {
        id: string
        course_section_data: Array<any>
      }
    | undefined
  >(undefined)

  /**
   * Hàm lấy dữ liệu section của khóa học
   * @returns {Promise<void>} Promise không trả về giá trị
   */
  async function fetchCourseSectionData() {
    const response = await ClassesApi.getCourseSectionData(openEditCourseContent?.data?.id)
    setCourseSectionData(response?.data)
  }

  /**
   * Effect hook để lấy dữ liệu section khi mở modal chỉnh sửa nội dung khóa học
   * Chỉ thực hiện khi modal được mở và có id của khóa học
   */
  useEffect(() => {
    if (!openEditCourseContent.status) return
    fetchCourseSectionData()
  }, [openEditCourseContent?.data?.id])

  return (
    <>
      {contextHolder}
      <SappTable
        handleChangeParams={handleChangeParams}
        filterParams={filterParams}
        columns={columns}
        fetchData={fetchClassList}
        data={classList}
        pagination={pagination}
        setPagination={setPagination}
        fetchTableData={fetchClassList}
        loading={loading}
      />

      <BlockClass
        open={openBlock.status}
        setOpen={setOpenBlock}
        data={openBlock.data}
        fetchClasses={() => fetchClassList(page_index || 1, page_size || 10, filterParams)}
      />
      <UpdateDuration
        open={openUpdateDuration.status}
        setOpen={setOpenUpdateDuration}
        data={openUpdateDuration.data}
        fetchClasses={() => fetchClassList(page_index || 1, page_size || 10, filterParams)}
      />
      <ModalProcessing
        open={openEditCourseContent.status}
        setOpen={setOpenEditCourseContent}
        type={openEditCourseContent.type}
        title={
          openEditCourseContent.type === 'process' ? 'Learning Process' : 'Edit Course Content'
        }
        id={openEditCourseContent?.data?.course_id}
        exceptedSections={openEditCourseContent?.data?.excepted_course_section || []}
        classId={openEditCourseContent?.data?.id}
        refetch={() => fetchClassList(page_index || 1, page_size || 10, cleanedParams)}
        dataClass={courseSectionData}
      />
    </>
  )
}

export default TableClasses
