import { Popover } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { CaseStudyAPI } from 'src/apis/case-study'
import { CoursesAPI } from 'src/apis/courses'
import { PageLink, TYPE_COURSE } from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { TEST_TYPE } from '../../shared/Interfaces'
import ChooseTypeOfTestModal, { COURSE_TEST_TYPES } from '../../test/ChooseTypeOfTestModal'
import '../Tree.scss'
import LinkActivity from '../course-link/LinkActivity'
import LinkSubsection from '../course-link/LinkSubsection'
import LinkUnit from '../course-link/LinkUnit'
import SortableTree, { getNodeAtPath, removeNodeAtPath, toggleExpandedForAll } from '../tree-course'
import '../tree-course/node-renderer-default.css'
import '../tree-course/placeholder-renderer-default.css'
import '../tree-course/react-sortable-tree.css'
import '../tree-course/tree-node.css'
import { ParentsNode, Role } from 'src/type'
import { CourseCondition } from 'src/type/courses'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_COURSE_GR } from 'src/constants/permission'

// interface INode {
//   treeData: treeData,
//   path: parentPath,
//   getNodeKey: getNodeKey,
// }
interface IProps {
  data: any
  id: string
  openCreateSection: {
    status: boolean
    data?: any
    type?: string
    id?: string
  }
  setOpenCreateSection: any
  openCreateLearning: {
    status: boolean
    data?: any
    type?: string
    id?: string
  }
  setOpenCreateLearning: any
  setOpenCreateTab: any
  treeData: any
  setTreeData: any
  setOpenCreateActivity: any
  setOpenPreviewActivity: React.Dispatch<
    React.SetStateAction<
      | {
          id: string
          open: boolean
        }
      | undefined
    >
  >
  template: number
  statusLock?: boolean
  clickedNode: any
  setClickedNode: Dispatch<SetStateAction<any>>
  rawCourseData: any
  typePractice: boolean
  courseCondition?: CourseCondition
  enableDrag: boolean
  typeTrial?: boolean | undefined
}

const TreeCourse = ({
  data,
  setOpenCreateLearning,
  setOpenCreateSection,
  setOpenCreateTab,
  treeData,
  setTreeData,
  setOpenCreateActivity,
  setOpenPreviewActivity,
  clickedNode,
  setClickedNode,
  rawCourseData,
  typePractice,
  courseCondition,
  enableDrag,
  typeTrial,
  template,
}: IProps) => {
  //TODO: state cho drawer cho link chapter
  const [openDrawerLinkChapter, setOpenDrawerLinkChapter] = useState<{
    status: boolean
    parentId?: string
    position?: number
  }>({
    status: false,
  })
  //TODO: state cho drawer cho link unit
  const [openDrawerLinkUnit, setOpenDrawerLinkUnit] = useState<{
    status: boolean
    parentId?: string
    position?: number
  }>({
    status: false,
  })
  //TODO: state cho drawer cho link activity
  const [openDrawerLinkActivity, setOpenDrawerLinkActivity] = useState<{
    status: boolean
    parentId?: string
    position?: number
  }>({
    status: false,
  })

  function findLastParent(array: any, child: any) {
    // Initialize a variable to store the current object
    let current = array.find((obj: any) => obj.id === child.id)
    // Initialize a variable to store the last parent
    let lastParent = {} as any
    // Loop until the current object has no parent
    while (current.parent_id !== null) {
      // Update the last parent to the current object's parent
      lastParent = current
      // Find the object that has the same id as the current object's parent
      current = array.find((obj: any) => obj.id === current.parent_id)
    }
    // Return the last parent
    return array.find((obj: any) => obj.id === lastParent.parent_id).id
  }
  const { setLoadingDelete, getCourseDetail, idCourse, idChild, setIdChild } = useCourseContext()
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()
  const [openChooseTypeOfTestModal, setOpenChooseTypeOfTestModal] = useState<{
    type: string
    open: boolean
    id?: string
  }>({
    type: COURSE_TEST_TYPES.PART,
    open: false,
  })

  /**
   * Xử lý xóa một node (phần nội dung) trong cây dữ liệu khóa học.
   *
   * @param id - ID của node cần xóa.
   * @param path - Mảng vị trí của node trong cây.
   * @param treeIndex - Chỉ mục của node trong cây.
   *
   * Chức năng này hiển thị hộp thoại xác nhận trước khi xóa node.
   * Nếu người dùng đồng ý, hệ thống sẽ thực hiện xóa node khỏi dữ liệu khóa học,
   * cập nhật lại cây dữ liệu và tải lại chi tiết khóa học để phản ánh thay đổi.
   */

  const handleDeleteNode = async (id: string, path: Array<number>, treeIndex: number) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        setLoadingDelete(true)
        try {
          await CoursesAPI.deleteSection(id)
          const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex
          setTreeData(removeNodeAtPath({ treeData: treeData, path, getNodeKey }))
          getCourseDetail(idCourse)
        } catch (err) {
        } finally {
          setLoadingDelete(false)
        }
      },
    })
  }

  /**
   * Xử lý xóa Learning Outcome trong cây dữ liệu khóa học.
   *
   * @param id - ID của Learning Outcome cần xóa.
   * @param path - Mảng vị trí của node trong cây.
   * @param treeIndex - Chỉ mục của node trong cây.
   *
   * Chức năng này xác nhận trước khi xóa Learning Outcome. Nếu có lỗi `400|5203` (tồn tại liên kết với Activity),
   * sẽ hiển thị một hộp thoại xác nhận khác để xác nhận xóa cưỡng bức. Sau khi xóa thành công, dữ liệu cây sẽ được cập nhật.
   */

  const handleDeleteLearnOutcome = (id: string, path: Array<number>, treeIndex: number) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        setLoadingDelete(true)
        try {
          await CoursesAPI.deleteLearnOutcome(id)
          const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex
          setTreeData(removeNodeAtPath({ treeData: treeData, path, getNodeKey }))
          getCourseDetail(idCourse)
        } catch (error: any) {
          if (error?.response?.data?.error?.code === '400|5203') {
            confirm({
              okButtonCaption: 'Yes',
              cancelButtonCaption: 'No',
              body: [
                'Khóa học vẫn còn Learning Outcome liên kết với Activity',
                'Bạn có chắc chắn xóa không?',
              ],
              onClick: async () => {
                setLoadingDelete(true)
                try {
                  await CoursesAPI.deleteForceLearnOutcome(id)
                  // const getNodeKey = ({treeIndex}: any) => treeIndex
                  getCourseDetail(idCourse)
                  // setTreeData(removeNodeAtPath({treeData: treeData, path, getNodeKey}))
                } finally {
                  setLoadingDelete(false)
                }
              },
            })
          }
        } finally {
          setLoadingDelete(false)
        }
      },
    })
  }

  const handleDeleteQuiz = async (
    id: string | null | undefined,
    path: Array<number>,
    treeIndex: number
  ) => {
    try {
      await CoursesAPI.getQuizById(id)
    } catch (error) {
      return
    }

    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        setLoadingDelete(true)
        try {
          if (id) {
            await CoursesAPI.deleteQuiz(id)
          }
          const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex
          setTreeData(removeNodeAtPath({ treeData: treeData, path, getNodeKey }))
          getCourseDetail(idCourse)
        } catch (err) {
        } finally {
          setLoadingDelete(false)
        }
      },
    })
  }

  /**
   * Xử lý xóa Case Study trong cây dữ liệu khóa học.
   *
   * @param id - ID của Case Study cần xóa.
   * @param path - Mảng vị trí của node trong cây.
   * @param treeIndex - Chỉ mục của node trong cây.
   *
   * Chức năng này hiển thị hộp thoại xác nhận trước khi xóa Case Study. Nếu người dùng đồng ý,
   * hệ thống sẽ thực hiện xóa, cập nhật lại dữ liệu cây và tải lại chi tiết khóa học.
   */

  const handleDeleteCaseStudy = async (id: string, path: Array<number>, treeIndex: number) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        setLoadingDelete(true)
        try {
          await CaseStudyAPI.delete(id)
          const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex
          setTreeData(removeNodeAtPath({ treeData: treeData, path, getNodeKey }))
          getCourseDetail(idCourse)
        } catch (err) {
        } finally {
          setLoadingDelete(false)
        }
      },
    })
  }

  const { id } = useParams()

  /**
   * Xử lý hủy liên kết một section con khỏi section cha trong khóa học.
   *
   * @param child_id - ID của section con cần hủy liên kết (tùy chọn).
   * @param course_section_parent_id - ID của section cha chứa section con (tùy chọn).
   *
   * Chức năng này gửi yêu cầu hủy liên kết section con khỏi section cha. Nếu thành công,
   * hệ thống sẽ tải lại chi tiết khóa học để cập nhật dữ liệu.
   */

  const onClickUnlinkSection = async (child_id?: string, course_section_parent_id?: string) => {
    const res = await CoursesAPI.unlinkCourse({
      course_id: id,
      child_id: child_id,
      course_section_parent_id: course_section_parent_id,
    })
    if (res?.success && id) {
      getCourseDetail(id)
    }
  }

  /**
   * Xử lý hủy liên kết một subsection khỏi section cha trong khóa học.
   *
   * @param child_id - ID của subsection cần hủy liên kết (tùy chọn).
   * @param course_section_parent_id - ID của section cha chứa subsection (tùy chọn).
   *
   * Chức năng này gửi yêu cầu hủy liên kết subsection khỏi section cha. Nếu thành công,
   * hệ thống sẽ tải lại chi tiết khóa học để cập nhật dữ liệu.
   */

  const onClickUnlinkSubsection = async (child_id?: string, course_section_parent_id?: string) => {
    const res = await CoursesAPI.unlinkCourse({
      child_id: child_id,
      course_section_parent_id: course_section_parent_id,
    })
    if (res?.success && id) {
      getCourseDetail(id)
    }
  }

  /**
   * Xác nhận hủy liên kết một section khỏi khóa học.
   *
   * @param child_id - ID của section cần hủy liên kết.
   *
   * Chức năng này hiển thị hộp thoại xác nhận trước khi thực hiện hủy liên kết section khỏi khóa học.
   * Nếu người dùng đồng ý, hệ thống sẽ gọi `onClickUnlinkSection` để xử lý việc hủy liên kết.
   */

  const handleConfirmUnlinkSection = (child_id: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn gỡ học phần này khỏi khóa học không?'],
      onClick: () => onClickUnlinkSection(child_id),
      isMoreDescription: true,
    })
  }

  /**
   * Xác nhận hủy liên kết một subsection khỏi section cha trong khóa học.
   *
   * @param child_id - ID của subsection cần hủy liên kết.
   * @param course_section_parent_id - ID của section cha chứa subsection.
   *
   * Chức năng này hiển thị hộp thoại xác nhận trước khi thực hiện hủy liên kết subsection khỏi section cha.
   * Nếu người dùng đồng ý, hệ thống sẽ gọi `onClickUnlinkSubsection` để xử lý việc hủy liên kết.
   */

  const handleConfirmUnlinkSubsection = (child_id: string, course_section_parent_id: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có các thay đổi chưa lưu.', 'Bạn có muốn quay lại trang Course Content?'],
      onClick: () => onClickUnlinkSubsection(child_id, course_section_parent_id),
      isMoreDescription: true,
    })
  }

  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  /**
   * Kiểm tra quyền hiển thị chức năng chỉnh sửa hoặc tạo khóa học.
   *
   * Biến này xác định xem người dùng có quyền tạo hoặc chỉnh sửa khóa học hay không
   * dựa trên vai trò của họ. Người dùng sẽ được phép nếu họ có quyền `CREATE_COURSE`,
   * `EDIT_COURSE` hoặc có vai trò `SUPER_ADMIN`.
   *
   * @returns `true` nếu người dùng có quyền, ngược lại `false`.
   */

  const allowRenderEditCreateCourse = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_COURSE_GR.CREATE_COURSE) ||
      hasPermission(role, TITLE_COURSE_GR.EDIT_COURSE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const canDragItem = (node: ParentsNode) => {
    if (node && node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME) {
      return false
    } else {
      return true
    }
  }

  const [showToast, setShowToast] = useState(false)
  const [isDraggingNode, setisDraggingNode] = useState(false)

  /**
   * Hiển thị thông báo lỗi khi di chuyển Course Content không hợp lệ.
   *
   * Effect này lắng nghe `showToast` và `isDraggingNode`. Nếu `showToast` được bật và không có thao tác kéo thả (`isDraggingNode === false`),
   * hệ thống sẽ hiển thị thông báo lỗi rằng Course Content chỉ có thể được di chuyển trong cùng cấp với parent của nó.
   *
   * Sau khi hiển thị, `showToast` sẽ tự động tắt sau 100ms để tránh lặp lại thông báo.
   */

  useEffect(() => {
    if (showToast && isDraggingNode === false) {
      toast.error(
        `Course Content can only be moved to a same-level position within its parent course content.`
      )
      const timeoutId = setTimeout(() => {
        setShowToast(false)
      }, 100)

      return () => clearTimeout(timeoutId)
    }
  }, [showToast])

  /**
   * Xử lý logic di chuyển node trong cây dữ liệu khóa học.
   *
   * @param nextParentNode - Node cha mới mà node con sẽ được di chuyển vào.
   *
   * Chức năng này thực hiện các bước sau:
   * 1. Kiểm tra nếu `LearningOutcome` không nằm ở vị trí đầu tiên trong danh sách con,
   *    hiển thị thông báo lỗi và không cho phép sắp xếp lại.
   * 2. Nếu tồn tại node `LearningOutcome`, tự động đưa node này lên đầu danh sách con của node cha.
   * 3. Cập nhật lại vị trí (`position`) của tất cả node con để đảm bảo thứ tự sắp xếp đồng nhất với danh sách con.
   */

  const onMoveNode = (nextParentNode: ParentsNode) => {
    if (nextParentNode && nextParentNode?.nextParentNode?.children) {
      const learningOutcomeNodeIndex = nextParentNode?.nextParentNode?.children?.findIndex(
        (child: ParentsNode) => child?.course_section_type === TYPE_COURSE.LEARNINGOUTCOME
      )
      // nếu node type LearningOutcome và vị trí của node đó không đứng đầu
      if (learningOutcomeNodeIndex !== -1 && learningOutcomeNodeIndex !== 0) {
        toast.error(
          `Subcontents (which belong to a linked Course Content) or Learning Outcomes cannot be rearranged.`
        )
      }

      // nếu node type LearningOutcome => auto đưa lên đầu mảng
      if (learningOutcomeNodeIndex !== -1) {
        const learningOutcomeNode = nextParentNode?.nextParentNode?.children?.splice(
          learningOutcomeNodeIndex,
          1
        )[0]
        if (learningOutcomeNode) {
          nextParentNode?.nextParentNode?.children?.unshift(learningOutcomeNode)
        }
      }

      // thiết lập lại toàn bộ position của node trùng với index trong mảng nodeChildren
      for (const childNode of nextParentNode?.nextParentNode?.children) {
        childNode.position = nextParentNode?.nextParentNode?.children?.findIndex(
          (child: ParentsNode) => child?.id === childNode.id
        )
      }
    }
  }

  const [nextParentNodeId, setNextParentNodeId] = useState<string>()
  const [draggingNode, setDraggingNode] = useState<ParentsNode>()
  const [draggingNumber, setDraggingNumber] = useState(0)

  /**
   * Hiển thị thông báo lỗi khi di chuyển Course Content không hợp lệ.
   *
   * Effect này theo dõi `draggingNode`, `nextParentNodeId` và `draggingNumber` để kiểm tra điều kiện di chuyển:
   * - Nếu node không có `parent_id`, số lần kéo (`draggingNumber`) là số chẵn và có `nextParentNodeId`, hiển thị lỗi.
   * - Nếu node có `parent_id`, số lần kéo là số chẵn nhưng `parent_id` khác với `nextParentNodeId`, hiển thị lỗi.
   *
   * Khi vi phạm điều kiện, hệ thống sẽ hiển thị thông báo rằng Course Content chỉ có thể di chuyển trong cùng cấp với parent của nó.
   */

  useEffect(() => {
    if (
      (!draggingNode?.parent_id && draggingNumber % 2 === 0 && nextParentNodeId) ||
      (draggingNode?.parent_id &&
        draggingNumber % 2 === 0 &&
        draggingNode?.parent_id !== nextParentNodeId)
    )
      toast.error(
        `Course Content can only be moved to a same - level position within its parent course content.`
      )
  }, [draggingNode, nextParentNodeId, draggingNumber])

  /**
   * Mở rộng các node trong cấu trúc cây dựa trên các điều kiện sau:
   * 1. Node là section được chỉ định (sectionId)
   * 2. Node chứa idChild trong cây con của nó
   * 3. Node thuộc danh sách subsectionIds hoặc unitIds và node cha đã được mở rộng
   *
   * @param treeData - Mảng chứa dữ liệu cây cần xử lý
   * @param sectionId - ID của section cần mở rộng
   * @param subsectionIds - Mảng ID của các subsection cần mở rộng
   * @param unitIds - Mảng ID của các unit cần mở rộng
   * @returns Cây mới với các node được cập nhật trạng thái mở rộng (expanded)
   */
  function expandNodesByIds(
    treeData: Array<any>,
    sectionId: string,
    subsectionIds: Array<string>,
    unitIds: Array<string>
  ) {
    /**
     * Hàm đệ quy để duyệt và cập nhật trạng thái mở rộng cho từng node trong cây
     *
     * @param nodes - Mảng các node cần xử lý
     * @param parentExpanded - Trạng thái mở rộng của node cha
     * @returns Mảng các node đã được cập nhật trạng thái
     */
    const expandNodes = (nodes: any[], parentExpanded: boolean = true): any[] => {
      return nodes.map((node) => {
        // Kiểm tra node hiện tại có phải là section được chỉ định
        const isTargetSection = node.id === sectionId

        /**
         * Kiểm tra đệ quy xem node hiện tại hoặc bất kỳ node con nào chứa idChild
         *
         * @param n - Node cần kiểm tra
         * @returns true nếu node hoặc node con chứa idChild, ngược lại false
         */
        const containsIdChild = (function checkForIdChild(n): boolean {
          if (n?.id === idChild) return true
          if (n?.children) {
            return n?.children?.some((child: any) => checkForIdChild(child))
          }
          return false
        })(node)

        // Node sẽ được mở rộng nếu thỏa mãn một trong các điều kiện:
        // 1. Là section được chỉ định (isTargetSection) HOẶC
        // 2. Chứa idChild trong cây con (containsIdChild) HOẶC
        // 3. Thuộc danh sách subsectionIds/unitIds VÀ node cha đã được mở rộng
        const isExpanded =
          isTargetSection ||
          containsIdChild ||
          (parentExpanded && (subsectionIds.includes(node.id) || unitIds.includes(node.id)))

        // Trả về node đã cập nhật với trạng thái mở rộng mới
        return {
          ...node,
          expanded: isExpanded,
          children: expandNodes(node.children || [], isExpanded),
        }
      })
    }

    return expandNodes(treeData)
  }

  /**
   * Tìm ID của node cha cao nhất chứa `targetId` trong một cấu trúc cây.
   *
   * @param treeData - Dữ liệu cây cần tìm kiếm.
   * @param targetId - ID của node cần tìm cha cao nhất.
   * @returns ID của node cha cao nhất hoặc chính nó nếu không có cha.
   */
  function findTopmostParentId(treeData: Array<any>, targetId: string) {
    let topmostParentId: string | null = null
    if (treeData) {
      const traverse = (nodes: any[], parent: { id: string } | null = null) => {
        for (const node of nodes) {
          if (node.id === targetId) {
            topmostParentId = parent ? parent.id : node.id
            return true
          }
          if (node.children && node.children.length > 0) {
            if (traverse(node.children, node)) {
              topmostParentId = parent ? parent.id : node.id
              return true
            }
          }
        }
        return false
      }

      traverse(treeData)
      return topmostParentId
    }
  }

  const topmostParentId = findTopmostParentId(treeData, idChild)

  /**
   * Lấy danh sách ID của các node trong cây theo loại `course_section_type` nếu chúng được mở rộng (`expanded`).
   *
   * @param treeData - Dữ liệu cây cần duyệt.
   * @returns Một object chứa danh sách ID của các node thuộc loại 'CHAPTER' và 'UNIT' (bỏ qua 'PART').
   */
  function getIdsByCourseSectionType(treeData: Array<any>) {
    const partIds: string[] = []
    const chapterIds: string[] = []
    const unitIds: string[] = []

    if (treeData) {
      const traverse = (nodes: Array<any>) => {
        nodes.forEach((node) => {
          if (node.course_section_type === 'PART' && node.expanded) {
            partIds.push(node.id)
          } else if (node.course_section_type === 'CHAPTER' && node.expanded) {
            chapterIds.push(node.id)
          } else if (node.course_section_type === 'UNIT' && node.expanded) {
            unitIds.push(node.id)
          }

          if (node.children && node.children.length > 0) {
            traverse(node.children)
          }
        })
      }

      traverse(treeData)
      return { chapterIds, unitIds }
    }
  }

  const ids = getIdsByCourseSectionType(treeData) || { partIds: [], chapterIds: [], unitIds: [] }
  const { chapterIds, unitIds } = ids

  /**
   * Cập nhật trạng thái mở rộng của cây dữ liệu dựa trên điều kiện ID con (`idChild`).
   *
   * Effect này theo dõi `topmostParentId` và `data` để cập nhật trạng thái của cây:
   * - Nếu `idChild` tồn tại, hệ thống sẽ mở rộng các node được xác định bởi `chapterIds` và `unitIds`.
   * - Nếu `idChild` không tồn tại, tất cả các node trong cây sẽ được thu gọn (`expanded: false`).
   *
   * Điều này đảm bảo rằng khi có một node con được chọn, hệ thống sẽ mở rộng các node liên quan để hiển thị nội dung phù hợp.
   */

  useEffect(() => {
    if (idChild) {
      setTreeData(() => expandNodesByIds(data, topmostParentId || '', chapterIds, unitIds))
    } else {
      setTreeData(() => toggleExpandedForAll({ treeData: data, expanded: false }))
    }
  }, [topmostParentId, data])

  /**
   * Xử lý khóa hoặc mở khóa một phần nội dung trong khóa học.
   *
   * @param id - ID của phần nội dung cần khóa/mở khóa.
   * @param is_preview_locked - Trạng thái hiện tại của nội dung (đang khóa hay không).
   * @param course_section_type - Loại của phần nội dung (ACTIVITY, CHAPTER, UNIT, v.v.).
   *
   * Chức năng này hiển thị hộp thoại xác nhận trước khi thực hiện hành động:
   * - Nếu loại nội dung là `ACTIVITY`, `CHAPTER` hoặc `UNIT`, xác nhận khóa các cấu phần bên trong.
   * - Nếu nội dung chưa bị khóa (`is_preview_locked === false`), xác nhận mở khóa.
   * - Nếu nội dung đã bị khóa (`is_preview_locked === true`), xác nhận khóa lại.
   *
   * Khi người dùng đồng ý, hệ thống sẽ gửi yêu cầu khóa/mở khóa đến API và cập nhật dữ liệu khóa học nếu thành công.
   */
  const handleLock = async (
    id: string,
    is_preview_locked: boolean,
    course_section_type: string
  ) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [
        ['ACTIVITY', 'CHAPTER', 'UNIT'].includes(course_section_type)
          ? 'Bạn có chắc chắn muốn khóa các cấu phần bên trong không?'
          : !is_preview_locked
          ? 'Bạn có chắc chắn muốn mở khóa không?'
          : 'Bạn có chắc chắn muốn khóa nội dung này không?',
      ],
      onClick: async () => {
        setLoadingDelete(true)
        try {
          const res = await CoursesAPI.lockSection(id, is_preview_locked)
          if (res?.success) {
            setIdChild(res?.data?.id)
            toast.success('Your changes have been successfully saved!')
            getCourseDetail(idCourse)
          }
        } catch (err) {
        } finally {
          setLoadingDelete(false)
        }
      },
    })
  }

  /**
   * Thay đổi trạng thái nút CTA (Call To Action) trong khóa học.
   *
   * @param id - ID của khóa học cần thay đổi trạng thái CTA.
   * @param cta_status - Trạng thái hiện tại của nút CTA (`PREVIEW` hoặc `BEGIN`).
   *
   * Chức năng này hiển thị hộp thoại xác nhận trước khi thay đổi trạng thái nút CTA:
   * - Nếu trạng thái hiện tại là `PREVIEW`, xác nhận chuyển thành `BEGIN`.
   * - Nếu trạng thái hiện tại là `BEGIN`, xác nhận chuyển thành `PREVIEW`.
   *
   * Khi người dùng đồng ý, hệ thống gửi yêu cầu cập nhật trạng thái CTA đến API và tải lại chi tiết khóa học nếu thành công.
   */
  const changeCtaStatus = async (id: string, cta_status: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [
        `Bạn có chắc chắn muốn chuyển nút ${cta_status === 'PREVIEW' ? 'Begin' : 'Preview'} thành ${
          cta_status === 'PREVIEW' ? 'Preview' : 'Begin'
        } không?`,
      ],
      onClick: async () => {
        try {
          const res = await CoursesAPI.changeCtaStatus(id, cta_status)
          if (res?.success) {
            setIdChild(res?.data?.id)
            toast.success('Your changes have been successfully saved!')
            getCourseDetail(idCourse)
          }
        } catch (err) {}
      },
    })
  }

  /**
   * Thay đổi trạng thái hiển thị khóa của nội dung trong khóa học.
   *
   * @param id - ID của nội dung cần thay đổi trạng thái hiển thị khóa.
   * @param is_showing_locked - Trạng thái hiện tại của khóa (đang hiển thị hay không).
   *
   * Chức năng này hiển thị hộp thoại xác nhận trước khi thay đổi trạng thái hiển thị khóa:
   * - Nếu `is_showing_locked === true`, xác nhận ẩn khóa.
   * - Nếu `is_showing_locked === false`, xác nhận hiển thị khóa.
   *
   * Khi người dùng đồng ý, hệ thống gửi yêu cầu cập nhật trạng thái hiển thị khóa đến API và tải lại chi tiết khóa học nếu thành công.
   */

  const handleChangeShowLock = async (id: string, is_showing_locked: boolean) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [
        `${
          is_showing_locked
            ? 'Bạn có chắc chắn muốn ẩn khóa không?'
            : 'Bạn có chắc chắn muốn hiển thị khóa không?'
        }`,
      ],
      onClick: async () => {
        try {
          const res = await CoursesAPI.changeShowLock(id, is_showing_locked)
          if (res?.success) {
            setIdChild(res?.data?.id)
            toast.success('Your changes have been successfully saved')
            getCourseDetail(idCourse)
          }
        } catch (err) {}
      },
    })
  }

  const handleClickNewActivityCourse3Level = (
    nodeId: string,
    path: number[],
    treeData: ParentsNode[],
    setOpenCreateActivity: React.Dispatch<
      React.SetStateAction<{
        status: boolean
        id: string
        lo?: {
          description: string
          id: string
          name: string
        }
      }>
    >
  ) => {
    const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex

    const parentNode = getNodeAtPath({
      treeData,
      path,
      getNodeKey,
    }) as ParentsNode

    setOpenCreateActivity({
      status: true,
      id: nodeId,
      lo: parentNode?.node?.course_learning_outcome,
    })
  }

  return (
    <div style={{ marginLeft: '-60px' }}>
      {contextHolder}
      {treeData && (
        <SortableTree
          style={{ top: 0, right: 0, bottom: 0, left: 0, Zindex: -1 }}
          treeData={treeData}
          onDragStateChanged={({
            draggedNode,
            isDragging,
          }: {
            draggedNode: ParentsNode
            isDragging: boolean
          }) => {
            setisDraggingNode(isDragging)
            setDraggingNumber((prev) => prev + 1)
            if (draggedNode) setDraggingNode(draggedNode)
          }}
          onChange={(e: { e: ParentsNode }) => {
            setTreeData(e)
          }}
          isVirtualized={false}
          canDrop={({
            nextPath,
            prevPath,
            nextParent,
          }: {
            nextParent: ParentsNode
            prevPath: Array<number>
            nextPath: Array<number>
          }) => {
            if (nextParentNodeId !== nextParent?.id) {
              setNextParentNodeId(nextParent?.id)
            }

            const nextPathSlice = nextPath.slice(0, nextPath.length - 1)
            const prevPathSlice = prevPath.slice(0, prevPath.length - 1)
            const isSameLevel = JSON.stringify(nextPathSlice) === JSON.stringify(prevPathSlice)
            if (isDraggingNode === true && !isSameLevel) {
              setShowToast(true)
            }
            return isSameLevel
          }}
          canDrag={({ node }: { node: ParentsNode }) =>
            canDragItem(node) &&
            enableDrag &&
            courseCondition?.can_edit_course_content.can_drag_drop_course_section &&
            !node.is_linked_section_child
          }
          onMoveNode={onMoveNode}
          // nodeContentRenderer={NodeRendererDefault as any}
          generateNodeProps={({
            node,
            path,
            treeIndex,
            parentNode,
          }: {
            parentNode: ParentsNode
            treeIndex: number
            path: Array<number>
            node: ParentsNode
          }) => ({
            clickedNode: clickedNode,
            onClick: () => {
              setClickedNode(node)
            },
            buttons: [
              <>
                {node.course_section_type === TYPE_COURSE.PART ? (
                  <>
                    <Popover
                      trigger={'click'}
                      placement='leftTop'
                      arrow={false}
                      content={
                        <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                          {!node?.is_linked_section ? (
                            <>
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() => {
                                    setOpenCreateSection({
                                      status: true,
                                      data: undefined,
                                      type: TYPE_COURSE.SUBSECTION,
                                      id: node.id,
                                    })
                                  }}
                                >
                                  New Subsection
                                </div>
                              </div>
                              {typePractice &&
                                template !== 3 &&
                                courseCondition?.can_edit_course_content
                                  ?.can_link_course_section && (
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        setOpenDrawerLinkChapter({
                                          status: true,
                                          parentId: node.id,
                                          position: clickedNode?.children?.length,
                                        })
                                      }}
                                    >
                                      Link SubSection
                                    </div>
                                  </div>
                                )}
                              {node?.quiz?.id ? null : (
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() =>
                                      navigate(
                                        `${
                                          PageLink.TEST
                                        }/${TEST_TYPE.TOPIC_TEST.toLocaleLowerCase()}/part/${idCourse}/${
                                          node.id
                                        }?type=${TEST_TYPE.TOPIC_TEST}`
                                      )
                                    }
                                  >
                                    Add Part/ Topic Test
                                  </div>
                                </div>
                              )}

                              <ItemTree
                                title={`${node?.is_preview_locked ? 'Unlock' : 'Lock'} Section`}
                                onClick={() =>
                                  handleLock(
                                    node?.id,
                                    !node?.is_preview_locked,
                                    node?.course_section_type
                                  )
                                }
                                typeTrial={typeTrial}
                              />

                              <ItemTree
                                title={`${node?.is_showing_locked ? 'Hide' : 'Show'} "Lock" Icon`}
                                onClick={() =>
                                  handleChangeShowLock(node?.id, !node?.is_showing_locked)
                                }
                                typeTrial={typeTrial}
                              />

                              {typeTrial && (
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() =>
                                      changeCtaStatus(
                                        node?.id,
                                        node?.cta_status === 'PREVIEW' ? 'BEGIN' : 'PREVIEW'
                                      )
                                    }
                                  >
                                    Change CTA to "
                                    {node?.cta_status === 'PREVIEW' ? 'Begin' : 'Preview'}"
                                  </div>
                                </div>
                              )}

                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() =>
                                    setOpenCreateSection({
                                      status: true,
                                      data: node,
                                      type: TYPE_COURSE.SECTION,
                                      id: node.id,
                                      path: path,
                                      treeIndex: node.treeIndex,
                                    })
                                  }
                                >
                                  Edit
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {template !== 3 && (
                            <div
                              onClick={() =>
                                window.open(`${PageLink.PREVIEW_PART}/${node.id}`, '_blank')
                              }
                              className='menu-item px-3'
                            >
                              <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                            </div>
                          )}
                          {node?.is_linked_section && (
                            <div
                              className='menu-item px-3'
                              onClick={() => handleConfirmUnlinkSection(node.id)}
                            >
                              <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                            </div>
                          )}
                          {!node?.is_linked_section && (
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3 sapp-item-tree'
                                onClick={() => {
                                  handleDeleteNode(node.id, path, treeIndex)
                                }}
                              >
                                Delete
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    >
                      <div className='sapp-tree-popup'>
                        <KTIcon
                          iconName='dots-vertical'
                          className={`fs-2x text-gray-900 sapp-tree-popup-btn`}
                          iconType='solid'
                        />
                        {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                      </div>
                    </Popover>
                    <LinkSubsection
                      open={openDrawerLinkChapter?.status}
                      setOpen={setOpenDrawerLinkChapter}
                      title='Link Subsection'
                      parentId={openDrawerLinkChapter?.parentId}
                      position={openDrawerLinkChapter?.position || 0}
                      body='Create a new subsection by linking subsection from other course. '
                    />
                  </>
                ) : node.course_section_type === TYPE_COURSE.CHAPTER ? (
                  <>
                    {!node?.is_linked_section && !node?.is_linked_section_child && (
                      <Popover
                        trigger={'click'}
                        placement='leftTop'
                        arrow={false}
                        content={
                          <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                            {node?.course_learning_outcome?.id ? null : (
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() => {
                                    if (node?.course_learning_outcome?.id) {
                                      toast.error('Each chapter can only have one learning outcome')
                                      return
                                    }
                                    setOpenCreateLearning({
                                      status: true,
                                      id: node.id,
                                    })
                                  }}
                                >
                                  Add Learning Outcome
                                </div>
                              </div>
                            )}
                            {
                              <>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      navigate(
                                        `${
                                          PageLink.TEST
                                        }/${TEST_TYPE.CHAPTER_TEST.toLocaleLowerCase()}/chapter/${idCourse}/${
                                          node.id
                                        }?type=${TEST_TYPE.CHAPTER_TEST}&template=${template}`
                                      )
                                    }}
                                  >
                                    Add Chapter/ Module Test
                                  </div>
                                </div>
                              </>
                            }
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3 sapp-item-tree'
                                onClick={() => {
                                  navigate(
                                    `${PageLink.CASE_STUDY}/${idCourse}/${node.id}?template=${template}`
                                  )
                                }}
                              >
                                Add Case Study
                              </div>
                            </div>

                            <ItemTree
                              title={`${node?.is_preview_locked ? 'Unlock' : 'Lock'} Subsection`}
                              onClick={() =>
                                handleLock(
                                  node?.id,
                                  !node?.is_preview_locked,
                                  node?.course_section_type
                                )
                              }
                              typeTrial={typeTrial}
                            />

                            <ItemTree
                              title={`${node?.is_showing_locked ? 'Hide' : 'Show'} "Lock" Icon`}
                              onClick={() =>
                                handleChangeShowLock(node?.id, !node?.is_showing_locked)
                              }
                              typeTrial={typeTrial}
                            />

                            {typePractice && template !== 3 && (
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() => {
                                    setOpenDrawerLinkUnit({
                                      status: true,
                                      parentId: node.id,
                                      position: clickedNode?.children?.length,
                                    })
                                  }}
                                >
                                  Link Unit
                                </div>
                              </div>
                            )}
                            {template !== 3 && (
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() =>
                                    setOpenCreateSection({
                                      status: true,
                                      data: undefined,
                                      type: TYPE_COURSE.UNIT,
                                      id: node.id,
                                    })
                                  }
                                >
                                  Add Unit
                                </div>
                              </div>
                            )}
                            {template === 3 && (
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() =>
                                    handleClickNewActivityCourse3Level(
                                      node.id,
                                      path,
                                      treeData,
                                      setOpenCreateActivity
                                    )
                                  }
                                >
                                  New Activity
                                </div>
                              </div>
                            )}
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3 sapp-item-tree'
                                onClick={() =>
                                  setOpenCreateSection({
                                    status: true,
                                    data: node,
                                    type: TYPE_COURSE.SUBSECTION,
                                    id: node.id,
                                    path: path,
                                    treeIndex: node.treeIndex,
                                  })
                                }
                              >
                                Edit
                              </div>
                            </div>
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3 sapp-item-tree'
                                onClick={() => {
                                  handleDeleteNode(node.id, path, treeIndex)
                                  // removeItem(node.id)
                                  // deleteNode2()
                                }}
                              >
                                Delete
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div className='sapp-tree-popup'>
                          <KTIcon
                            iconName='dots-vertical'
                            className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                            iconType='solid'
                          />
                          {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                        </div>
                      </Popover>
                    )}
                    {node?.is_linked_section && !node?.is_linked_section_child && (
                      <Popover
                        trigger={'click'}
                        placement='leftTop'
                        arrow={false}
                        content={
                          <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                            {allowRenderEditCreateCourse && (
                              <div
                                className='menu-item px-3'
                                onClick={() =>
                                  handleConfirmUnlinkSubsection(node.id, node.parent_id)
                                }
                              >
                                <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                              </div>
                            )}
                          </div>
                        }
                      >
                        <div className='sapp-tree-popup'>
                          <KTIcon
                            iconName='dots-vertical'
                            className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                            iconType='solid'
                          />
                          {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                        </div>
                      </Popover>
                    )}

                    <LinkUnit
                      open={openDrawerLinkUnit?.status}
                      setOpen={setOpenDrawerLinkUnit}
                      title='Link Unit'
                      parentId={openDrawerLinkUnit?.parentId}
                      position={openDrawerLinkUnit?.position || 0}
                      body='Create a new unit by linking unit from other course. '
                    />
                  </>
                ) : node.course_section_type === TYPE_COURSE.ACTIVITY ? (
                  <>
                    <Popover
                      trigger={'click'}
                      placement='leftTop'
                      arrow={false}
                      content={
                        <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                          {courseCondition?.can_edit_course_content.can_add_activity ? (
                            <>
                              {!node?.is_linked_section && !node?.is_linked_section_child && (
                                <>
                                  {template !== 3 && (
                                    <div className='menu-item px-3'>
                                      <div
                                        className='menu-link px-3 sapp-item-tree'
                                        onClick={() =>
                                          node?.total_tab && node.total_tab > 0
                                            ? setOpenCreateTab({
                                                status: true,
                                                course_section_id: node.id,
                                                edit: true,
                                                path: path,
                                                nodeData: node,
                                              })
                                            : setOpenCreateTab({
                                                status: true,
                                                course_section_id: node.id,
                                                path: path,
                                                nodeData: node,
                                              })
                                        }
                                      >
                                        {node?.total_tab && node.total_tab > 0
                                          ? `Edit Tab (${node.total_tab})`
                                          : 'Add Tab'}
                                      </div>
                                    </div>
                                  )}
                                  {typeTrial && (
                                    <div className='menu-item px-3'>
                                      <div
                                        className='menu-link px-3 sapp-item-tree'
                                        onClick={() =>
                                          handleLock(
                                            node.id,
                                            !node?.is_preview_locked,
                                            node?.course_section_type
                                          )
                                        }
                                      >
                                        {node?.is_preview_locked ? 'Unlock' : 'Lock'} Activity
                                      </div>
                                    </div>
                                  )}

                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        const parentPath =
                                          template === 3 ? path.slice(0, -1) : path.slice(0, -2)
                                        const getNodeKey = ({ treeIndex }: { treeIndex: number }) =>
                                          treeIndex
                                        const parentNode = getNodeAtPath({
                                          treeData: treeData,
                                          path: parentPath,
                                          getNodeKey: getNodeKey,
                                        }) as any
                                        setOpenCreateActivity({
                                          status: true,
                                          selfId: node.id,
                                          lo: parentNode?.node?.course_learning_outcome,
                                          path: path,
                                          data: node,
                                        })
                                      }}
                                    >
                                      Edit Activity
                                    </div>
                                  </div>
                                </>
                              )}

                              {node?.is_linked_section &&
                                !node?.is_linked_section_child &&
                                allowRenderEditCreateCourse && (
                                  <div
                                    className='menu-item px-3'
                                    onClick={() =>
                                      handleConfirmUnlinkSubsection(node.id, node.parent_id)
                                    }
                                  >
                                    <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                                  </div>
                                )}

                              {!node?.is_linked_section &&
                                !node?.is_linked_section_child &&
                                allowRenderEditCreateCourse && (
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => handleDeleteNode(node.id, path, treeIndex)}
                                    >
                                      Delete
                                    </div>
                                  </div>
                                )}
                            </>
                          ) : (
                            <>
                              {!node?.is_linked_section &&
                                !node?.is_linked_section_child &&
                                allowRenderEditCreateCourse && (
                                  <>
                                    <div className='menu-item px-3'>
                                      <div
                                        className='menu-link px-3 sapp-item-tree'
                                        onClick={() => {
                                          const parentPath =
                                            template === 3 ? path.slice(0, -1) : path.slice(0, -2)
                                          const getNodeKey = ({ treeIndex }: any) => treeIndex
                                          const parentNode = getNodeAtPath({
                                            treeData: treeData,
                                            path: parentPath,
                                            getNodeKey: getNodeKey,
                                          }) as any
                                          setOpenCreateActivity({
                                            status: true,
                                            selfId: node.id,
                                            lo: parentNode?.node?.course_learning_outcome,
                                            path: path,
                                            data: node,
                                            onlyEditName: true,
                                          })
                                        }}
                                      >
                                        Edit Activity
                                      </div>
                                    </div>
                                    <div className='menu-item px-3'>
                                      <div
                                        className='menu-link px-3 sapp-item-tree'
                                        onClick={() =>
                                          setOpenCreateTab({
                                            status: true,
                                            course_section_id: node.id,
                                            edit: true,
                                            path: path,
                                            nodeData: node,
                                            onlyEdit: true,
                                          })
                                        }
                                      >
                                        {`Edit Tab (${node.total_tab})`}
                                      </div>
                                    </div>

                                    {typeTrial && (
                                      <div className='menu-item px-3'>
                                        <div
                                          className='menu-link px-3 sapp-item-tree'
                                          onClick={() =>
                                            handleLock(
                                              node.id,
                                              !node?.is_preview_locked,
                                              node?.course_section_type
                                            )
                                          }
                                        >
                                          {node?.is_preview_locked ? 'Unlock' : 'Lock'} Activity
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                          {template !== 3 && (
                            <div
                              className='menu-item px-3'
                              onClick={() => {
                                setOpenPreviewActivity({
                                  id: node.id,
                                  open: true,
                                })
                              }}
                            >
                              <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                            </div>
                          )}
                        </div>
                      }
                    >
                      <div className='sapp-tree-popup'>
                        <KTIcon
                          iconName='dots-vertical'
                          className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                          iconType='solid'
                        />
                        {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                      </div>
                    </Popover>
                  </>
                ) : node.course_section_type === TYPE_COURSE.UNIT ? (
                  <>
                    <Popover
                      trigger={'click'}
                      placement='leftTop'
                      arrow={false}
                      content={
                        <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                          {courseCondition?.can_edit_course_content.can_add_unit ? (
                            <>
                              {!node?.is_linked_section &&
                              !node?.is_linked_section_child &&
                              allowRenderEditCreateCourse &&
                              template !== 3 ? (
                                <>
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        // setClickedNode(node)
                                        const parentPath = path.slice(0, -1)
                                        const getNodeKey = ({ treeIndex }: { treeIndex: number }) =>
                                          treeIndex
                                        const parentNode = getNodeAtPath({
                                          treeData: treeData,
                                          path: parentPath,
                                          getNodeKey: getNodeKey,
                                        }) as any
                                        // setParentLO(parentNode?.node?.course_learning_outcome)
                                        setOpenCreateActivity({
                                          status: true,
                                          id: node.id,
                                          lo: parentNode?.node?.course_learning_outcome,
                                        })
                                        // };
                                      }}
                                    >
                                      New Activity
                                    </div>
                                  </div>
                                  {typePractice && (
                                    <div className='menu-item px-3'>
                                      <div
                                        className='menu-link px-3 sapp-item-tree'
                                        onClick={() => {
                                          setOpenDrawerLinkActivity({
                                            status: true,
                                            parentId: node.id,
                                            position: clickedNode?.children?.length,
                                          })
                                        }}
                                      >
                                        Link Activity
                                      </div>
                                    </div>
                                  )}

                                  <ItemTree
                                    title={`${node?.is_preview_locked ? 'Unlock' : 'Lock'} Unit`}
                                    onClick={() =>
                                      handleLock(
                                        node?.id,
                                        !node?.is_preview_locked,
                                        node?.course_section_type
                                      )
                                    }
                                    typeTrial={typeTrial}
                                  />

                                  <ItemTree
                                    title={`${
                                      node?.is_showing_locked ? 'Hide' : 'Show'
                                    } "Lock" Icon`}
                                    onClick={() =>
                                      handleChangeShowLock(node?.id, !node?.is_showing_locked)
                                    }
                                    typeTrial={typeTrial}
                                  />

                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        setOpenCreateSection({
                                          status: true,
                                          data: node,
                                          type: TYPE_COURSE.UNIT,
                                          id: node.id,
                                          path: path,
                                          treeIndex: node.treeIndex,
                                        })
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                  {/* <div
                                    onClick={() =>{
                                      const rootId = findLastParent(rawCourseData, node)
                                      window.open(
                                        `${PageLink.PREVIEW_UNIT}/${rootId}/unit/${node.parent_id}`,
                                        '_blank'
                                      )
                                    }}
                                    className='menu-item px-3'
                                  >
                                    <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                                  </div> */}
                                </>
                              ) : (
                                <>
                                  {/* <div
                                    className='menu-item px-3'
                                    onClick={() =>
                                      handleConfirmUnlinkSubsection(node.id, node.parent_id)
                                    }
                                  >
                                    <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                                  </div> */}
                                  {/* <div
                                    onClick={() => {
                                      const rootId = findLastParent(rawCourseData, node)
                                      window.open(
                                        `${PageLink.PREVIEW_UNIT}/${rootId}/unit/${node.parent_id}`,
                                        '_blank'
                                      )
                                    }}
                                    className='menu-item px-3'
                                  >
                                    <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                                  </div> */}
                                </>
                              )}

                              {node?.is_linked_section &&
                                !node?.is_linked_section_child &&
                                allowRenderEditCreateCourse && (
                                  <>
                                    <div
                                      className='menu-item px-3'
                                      onClick={() =>
                                        handleConfirmUnlinkSubsection(node.id, node.parent_id)
                                      }
                                    >
                                      <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                                    </div>
                                  </>
                                )}
                            </>
                          ) : (
                            !node?.is_linked_section &&
                            !node?.is_linked_section_child &&
                            allowRenderEditCreateCourse && (
                              <>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      setOpenCreateSection({
                                        status: true,
                                        data: node,
                                        type: TYPE_COURSE.UNIT,
                                        id: node.id,
                                        path: path,
                                        treeIndex: node.treeIndex,
                                      })
                                    }}
                                  >
                                    Edit
                                  </div>
                                </div>
                                {/* <div
                                  onClick={() => {
                                    const rootId = findLastParent(rawCourseData, node)
                                    window.open(
                                      `${PageLink.PREVIEW_UNIT}/${rootId}/unit/${node.parent_id}`,
                                      '_blank'
                                    )
                                  }}
                                  className='menu-item px-3'
                                >
                                  <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                                </div> */}
                              </>
                            )
                          )}
                          {template !== 3 && (
                            <div
                              onClick={() => {
                                const rootId = findLastParent(rawCourseData, node)
                                window.open(
                                  `${PageLink.PREVIEW_UNIT}/${rootId}/unit/${node.parent_id}`,
                                  '_blank'
                                )
                              }}
                              className='menu-item px-3'
                            >
                              <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                            </div>
                          )}
                          {courseCondition?.can_edit_course_content.can_delete_section &&
                            !node?.is_linked_section &&
                            !node?.is_linked_section_child && (
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() => {
                                    handleDeleteNode(node.id, path, treeIndex)
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            )}
                        </div>
                      }
                    >
                      <div className='sapp-tree-popup'>
                        <KTIcon
                          iconName='dots-vertical'
                          className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                          iconType='solid'
                        />
                        {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                      </div>
                    </Popover>
                    <LinkActivity
                      open={openDrawerLinkActivity?.status}
                      setOpen={setOpenDrawerLinkActivity}
                      title='Link Activity'
                      parentId={openDrawerLinkActivity?.parentId}
                      position={openDrawerLinkActivity?.position || 0}
                      body='Create a new activity by linking activity from other course. '
                    />
                  </>
                ) : node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME ? (
                  <>
                    {!parentNode?.is_linked_section_child && !parentNode?.is_linked_section && (
                      <Popover
                        trigger={'click'}
                        placement='leftTop'
                        arrow={false}
                        content={
                          <div className='sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                            {courseCondition?.can_edit_course_content.can_add_learning_outcome ? (
                              <>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      if (
                                        node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME
                                      ) {
                                        setOpenCreateLearning({
                                          status: true,
                                          id: node.id,
                                          type: node.course_section_type,
                                        })
                                      }
                                    }}
                                  >
                                    Edit
                                  </div>
                                </div>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      if (
                                        node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME
                                      ) {
                                        handleDeleteLearnOutcome(node.id, path, treeIndex)
                                      }
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {allowRenderEditCreateCourse && (
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        if (
                                          node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME
                                        ) {
                                          setOpenCreateLearning({
                                            status: true,
                                            id: node.id,
                                            type: node.course_section_type,
                                            onlyEditName: true,
                                          })
                                        }
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        }
                      >
                        <div className='sapp-tree-popup'>
                          <KTIcon
                            iconName='dots-vertical'
                            className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                            iconType='solid'
                          />
                          {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                        </div>
                      </Popover>
                    )}
                  </>
                ) : (
                  <>
                    {!node?.is_linked_section && !node?.is_linked_section_child && (
                      <Popover
                        trigger={'click'}
                        placement='leftTop'
                        arrow={false}
                        content={
                          <div className='sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                            {courseCondition?.can_edit_course_content.can_link_course_section ? (
                              <>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      if (
                                        Object.keys(TEST_TYPE).includes(node.course_section_type)
                                      ) {
                                        navigate(
                                          `${PageLink.TEST_EDIT}/${node.course_section_type}/${idCourse}/${node?.quiz?.id}?type=${node.course_section_type}&template=${template}`
                                        )
                                      }
                                      // Case study
                                      if (node?.quiz?.quiz_type === TYPE_COURSE.STORY) {
                                        navigate(
                                          `${PageLink.CASE_STUDY}/${idCourse}/${node.id}/${node?.quiz?.id}?template=${template}`
                                        )
                                      }
                                    }}
                                  >
                                    Edit
                                  </div>
                                </div>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      // Quiz
                                      if (
                                        Object.keys(TEST_TYPE).includes(node.course_section_type)
                                      ) {
                                        try {
                                          handleDeleteQuiz(node?.quiz?.id, path, treeIndex)
                                        } catch (error) {}
                                      }
                                      // Case study
                                      if (
                                        node?.quiz?.quiz_type === TYPE_COURSE.STORY &&
                                        node?.quiz?.id
                                      ) {
                                        handleDeleteCaseStudy(node.quiz.id, path, treeIndex)
                                      }
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                                {typeTrial && (
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() =>
                                        handleLock(
                                          node.id,
                                          !node?.is_preview_locked,
                                          node?.course_section_type
                                        )
                                      }
                                    >
                                      {node?.is_preview_locked ? 'Unlock' : 'Lock'}{' '}
                                      {Object.keys(TEST_TYPE).includes(node.course_section_type)
                                        ? 'Test'
                                        : 'Case Study'}
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {allowRenderEditCreateCourse && (
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        if (
                                          Object.keys(TEST_TYPE).includes(node.course_section_type)
                                        ) {
                                          navigate(
                                            `${PageLink.TEST_EDIT}/${node.course_section_type}/${idCourse}/${node?.quiz?.id}?type=${node.course_section_type}`,
                                            { state: { onlyEditName: true } }
                                          )
                                        }
                                        // Case study
                                        if (node?.quiz?.quiz_type === TYPE_COURSE.STORY) {
                                          navigate(
                                            `${PageLink.CASE_STUDY}/${idCourse}/${node.id}/${node?.quiz?.id}`,
                                            { state: { onlyEditName: true } }
                                          )
                                        }
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        }
                      >
                        <div className='sapp-tree-popup'>
                          <KTIcon
                            iconName='dots-vertical'
                            className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                            iconType='solid'
                          />
                          {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                        </div>
                      </Popover>
                    )}
                  </>
                )}
              </>,
            ],
          })}
        />
      )}
      <ChooseTypeOfTestModal
        open={openChooseTypeOfTestModal}
        setOpen={setOpenChooseTypeOfTestModal}
      ></ChooseTypeOfTestModal>
    </div>
  )
}

const ItemTree = ({
  title,
  onClick,
  typeTrial,
}: {
  title: string
  onClick: () => void
  typeTrial: boolean | undefined
}) => {
  return (
    <>
      {typeTrial && (
        <div className='menu-item px-3'>
          <div className='menu-link px-3 sapp-item-tree' onClick={onClick}>
            {title}
          </div>
        </div>
      )}
    </>
  )
}
export default TreeCourse
