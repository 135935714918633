import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useConfirm } from 'src/hooks/use-confirm'
import {
  capitalizeFirstLetter,
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  formatTime,
  getDateInfo,
} from 'src/utils'
import { Link } from 'react-router-dom'
import { PageLink } from 'src/constants'
import { toast } from 'react-hot-toast'
import { EntranceApi } from 'src/apis/entrance-test'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import {
  OPTIONS_OF_STATUS_ACTIVE,
  OPTIONS_OF_STATUS_DEACTIVE,
  OPTIONS_OF_STATUS_DRAFT,
} from 'src/constants/entrancetest'
import { Select } from 'antd'
import SappBaseTable from 'src/common/SappBaseTable'
import ActionCell from '../base/action/ActionCell'
import AddParticipantEvent from './participants/AddParticipantEvent'
import { useUserContext } from 'src/context/UserProvider'
import { hasPermission } from 'src/utils/permission'
import { TITLE_EVENT_TEST_GR } from 'src/constants/permission'
import { IEventTestItem, IEventTestList } from 'src/type/event'

const { Option } = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  EntranceTestList: IEventTestList
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchEntranceTestList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

// define headers
const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Test name',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Program',
    className: 'min-w-125px pe-5',
  },
  {
    label: 'Event',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Number of phases',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Participants',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Status',
    className: 'max-w-50px pe-5',
  },
]

const TableEventTest = ({
  EntranceTestList,
  loading,
  setLoading,
  filterParams,
  fetchEntranceTestList,
  getParams,
  queryParams,
  isCheckedAll,
  toggleCheckAll,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const [openParticipant, setOpenParticipant] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string>('')
  const navigate = useNavigate()
  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)
  const showQueryParams =
    queryParams.name ||
    queryParams.course_category_id ||
    queryParams.subject ||
    queryParams.sortType ||
    queryParams.fromDate ||
    queryParams.toDate ||
    queryParams.status ||
    queryParams.instruction_mode ||
    queryParams.quizType
  // TODO: dùng để filter params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.course_category_id ?? '',
      queryParams.subject ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      queryParams.status ?? '',
      queryParams.quizType ?? 'ENTRANCE_TEST',
      queryParams.dateField ?? 'updated_at',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )
  // Using validate for input
  const { control, setValue } = useForm<any>()

  useEffect(() => {
    setLoading(true)
    fetchEntranceTestList(page_index || 1, page_size || 10, filterParams)
    handleChangeParams(page_index || 1, page_size || 10)
  }, [])

  useEffect(() => {
    EntranceTestList?.quizzes
      ?.map((e: any) => ({ label: e.status, value: e.status }))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, capitalizeFirstLetter(option.value))
      })
  }, [setValue, EntranceTestList?.quizzes])

  const handleChangeStatus = (value: unknown, data: any, index: number) => {
    const request: any = {
      name: data?.name,
      status: value,
      courseCategoryId: data?.courseCategoryId,
    }

    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: async () => {
        try {
          await EntranceApi.editStatusEntranceTest({ ...request }, data?.id)
          toast.success('Update Status Successfully!')
          fetchEntranceTestList(page_index || 1, page_size || 10, filterParams)
        } catch (error: any) {
          setValue(`status${index}`, data?.status)
          // if (error?.response?.data?.error?.code === '400|060005') {
          //   toast.error(`Permission denied!`)
          // }
          // if (error?.response?.data?.error?.code === '403|000000') {
          //   toast.error(DENIED_PERMISSIONS)
          // }
          setValue(`status${index}`, data?.status?.toUpperCase())
          fetchEntranceTestList(page_index || 1, page_size || 10, filterParams)
        } finally {
          setLoading(false)
        }
      },
      onClose: () => {
        fetchEntranceTestList(page_index || 1, page_size || 10, filterParams)
      },
    })
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      name: queryParams.name ?? '',
      course_category_id: queryParams?.course_category_id ?? '',
      subject: queryParams.subject ?? '',
      sortType: queryParams.sortType ?? '',
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
      status: queryParams.status ?? '',
      page_index: currenPage,
      page_size: size,
      quizType: 'ENTRANCE_TEST',
      dateField: 'updated_at',
    }
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchEntranceTestList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  const { profileMe } = useUserContext()
  const allowRenderAddStudentEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.ADD_STUDENT_EVENT_TEST
  )
  const allowRenderRemoveStudentEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.REMOVE_STUDENT_EVENT_TEST
  )

  const allowRenderGetEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.GET_EVENT_TEST
  )

  const allowRenderEditEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.EDIT_EVENT_TEST
  )
  const allowRenderCreateEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.CREATE_EVENT_TEST
  )

  return (
    <>
      {contextHolder}
      <SappBaseTable
        headers={headers}
        loading={loading}
        isCheckedAll={isCheckedAll}
        onChange={() => toggleCheckAll(!isCheckedAll, true)}
        hasCheck={false}
        data={EntranceTestList?.quizzes}
        dataResponse={EntranceTestList}
        handlePaginationChange={handlePaginationChange}
      >
        {EntranceTestList?.quizzes?.map((e: IEventTestItem, index: number) => {
          let showStatus: Array<any>
          switch (e.status) {
            case 'DRAFT':
              showStatus = OPTIONS_OF_STATUS_ACTIVE
              break
            case 'ACTIVE':
              showStatus = OPTIONS_OF_STATUS_DRAFT
              break
            case 'LOCKED':
              showStatus = OPTIONS_OF_STATUS_DEACTIVE
              break
            default:
              showStatus = []
          }
          return (
            <tr key={index}>
              <td>
                {index +
                  1 +
                  (EntranceTestList?.meta?.page_index - 1) * EntranceTestList?.meta?.page_size}
              </td>
              <td className='text-start'>
                <Link
                  className='text-gray-800 sapp-text-truncate-1 text-hover-primary'
                  to={
                    allowRenderGetEventTest ||
                    allowRenderEditEventTest ||
                    allowRenderCreateEventTest
                      ? `${PageLink.CREATE_EVENT_TEST}/${e.id}`
                      : '#'
                  }
                >
                  {e?.name}
                </Link>
              </td>
              <td className='text-start'>{e?.course_category?.name}</td>
              <td className='text-start'>
                <div className='sapp-text-truncate-1'>
                  {e?.event_phase_quizzes?.event_phase?.event?.name}
                </div>
              </td>
              <td className='text-start'>{e.event_phase_quiz_count}</td>
              <td className='text-center'>
                <Link
                  className='text-gray-800 sapp-text-truncate-1 text-hover-primary'
                  to={`${PageLink.EVENT_TEST}/${e?.id}${PageLink.PARTICIPANTS}`}
                >
                  {e?.quiz_user_count ?? 0}
                </Link>
              </td>
              <td className='text-start'>
                <HookFormSelectAntd
                  allowClear={false}
                  name={`status${index}`}
                  control={control}
                  dropdownStyle={{ maxWidth: 100 }}
                  placeholder='Status'
                  filterOption={true}
                  onChange={(selectedValue: unknown) => handleChangeStatus(selectedValue, e, index)}
                  defaultValue={e?.status ?? ''}
                  classNameHeight='sapp-h-35px'
                  isLocked
                  disabled
                  options={showStatus}
                />
              </td>
              <td className='text-end bg-white sapp-absolute-column'>
                {(allowRenderAddStudentEventTest ||
                  allowRenderRemoveStudentEventTest ||
                  allowRenderGetEventTest ||
                  allowRenderEditEventTest ||
                  allowRenderCreateEventTest) && (
                  <ActionCell>
                    {(allowRenderAddStudentEventTest ||
                      allowRenderGetEventTest ||
                      allowRenderEditEventTest ||
                      allowRenderRemoveStudentEventTest ||
                      allowRenderCreateEventTest) && (
                      <div
                        className='menu-item px-3'
                        onClick={() =>
                          navigate(`${PageLink.EVENT_TEST}/${e?.id}${PageLink.PARTICIPANTS}`)
                        }
                      >
                        <div className='menu-link px-3'>Participant List</div>
                      </div>
                    )}
                    {allowRenderAddStudentEventTest && (
                      <div
                        className='menu-item px-3'
                        onClick={() => {
                          setOpenParticipant(true)
                          setSelectedId(e.id)
                        }}
                      >
                        <div className='menu-link px-3'>Add Participant</div>
                      </div>
                    )}
                  </ActionCell>
                )}
              </td>
            </tr>
          )
        })}
      </SappBaseTable>
      <AddParticipantEvent id={selectedId} open={openParticipant} setOpen={setOpenParticipant} />
    </>
  )
}

export default TableEventTest
