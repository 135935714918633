import { useState } from 'react'
import AddMore from 'src/common/AddMore'
import ActionCell from 'src/components/base/action/ActionCell'
import SappLabel from 'src/components/base/label/SappLabel'
import AddFAQs from './AddFAQs'
import { INameAndId } from 'src/type/shop/common'

const FAQsContent = ({
  confirm,
  useFormProp,
}: {
  confirm: (option: any) => void
  useFormProp: any
}) => {
  const [openFAQs, setOpenFAQs] = useState(false)
  const { setValue, watch } = useFormProp
  const faqList = watch('faq_questions')

  const handleAddFaq = (data: INameAndId[]) => {
    setValue('faq_questions', data)
    setOpenFAQs(false)
  }

  const hanelDelete = (data: INameAndId) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => {
        setValue(
          'faq_questions',
          faqList?.filter((item: INameAndId) => item.id !== data.id)
        )
      },
    })
  }

  return (
    <div className='card card-flush'>
      <div className='card-body pt-10'>
        <SappLabel label='FAQs' isLabel />
        {faqList?.map((data: INameAndId, index: number) => (
          <div key={`faq-${data.id}-${index}`}>
            <div className='d-flex justify-content-between px-5'>
              <div className='d-flex align-items-center'>
                <div className='text-gray-800 fw-semibold text-hover-primary mb-1 sapp-fs-14'>
                  {index + 1}. {data.name}
                </div>
              </div>
              <div>
                <ActionCell className='sapp-h-50px'>
                  <div className='menu-item px-3' onClick={() => hanelDelete(data)}>
                    <div className='menu-link px-3'>Delete</div>
                  </div>
                </ActionCell>
              </div>
            </div>
            <div className='my-5' style={{ border: '1px dashed #DBDFE9' }} />
          </div>
        ))}
        <AddMore addNew={() => setOpenFAQs(true)} />
        <AddFAQs
          initValue={faqList}
          open={openFAQs}
          setOpen={setOpenFAQs}
          onAddFaq={handleAddFaq}
        />
      </div>
    </div>
  )
}

export default FAQsContent
