import { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { PageLink, TYPE_COURSE } from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import CreateLearningOutCome from '../../course-detail/modals/CreateLearningOutCome'
import CreateSection from '../../course-detail/modals/CreateSection'
import CreateActivity from '../../course-detail/modals/CreateActivity'
import ChooseTypeOfTestModal, { COURSE_TEST_TYPES } from '../../test/ChooseTypeOfTestModal'
import { EmptyCourseInfo } from 'src/common/icons'
import { CourseCondition, ICourseType } from 'src/type/courses'
import PreviewActivityModal from 'src/components/courses/shared/previews/PreviewActivityModal'
import { Skeleton } from 'antd'
import 'src/components/courses/course-detail/course-content/CourseContent.scss'
import { useUserContext } from 'src/context/UserProvider'
import { ParentsNode, Role } from 'src/type'
import { CODE_ADMIN, TITLE_COURSE_GR } from 'src/constants/permission'
import { CoursesAPI } from 'src/apis/courses'
import toast from 'react-hot-toast'
import { Dropdown } from 'react-bootstrap'
import { useConfirm } from 'src/hooks/use-confirm'
import { DESCRIPTION_POPUPCONFIRM, LANG_COURSES } from 'src/constants/lang'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import TreeCourse from 'src/components/courses/course-detail/tree/Tree'
import LinkSection from 'src/components/courses/course-detail/course-link/LinkSection'
import {
  ICourseContentMediumProps,
  IOpenCreateActivity,
  IOpenCreateSection,
  IOpenCreateTab,
  IOpenState,
  IOpenCreateLearning,
} from '../../../../type/course-medium/section'

const CourseContentMedium = ({
  setStep, // Hàm cập nhật bước hiện tại trong quá trình tạo khóa học
  step, // Bước hiện tại trong quá trình tạo khóa học
  handleGotoStep, // Hàm điều hướng đến bước cụ thể
}: ICourseContentMediumProps) => {
  const { courseDetail, getCourseDetail, loading, loadingDelete, course, rawCourseData } =
    useCourseContext()

  const templateUrl = useLocation()
  const template = templateUrl.pathname.includes('/course-medium/') ? 3 : 4

  const [openChooseTypeOfTestModal, setOpenChooseTypeOfTestModal] = useState<{
    type: string
    open: boolean
    id?: string
  }>({
    type: COURSE_TEST_TYPES.COURSE,
    open: false,
  })
  const { id } = useParams()

  const [open, setOpen] = useState<IOpenState>({
    status: false,
    data: undefined,
  })
  const [openCreateSection, setOpenCreateSection] = useState<IOpenCreateSection>({
    status: false,
  })
  const [openCreateLearning, setOpenCreateLearning] = useState<IOpenCreateLearning>({
    status: false,
  })
  const [openCreateTab, setOpenCreateTab] = useState<IOpenCreateTab>({
    status: false,
    course_section_id: '',
  })
  const [openCreateActivity, setOpenCreateActivity] = useState<IOpenCreateActivity>({
    status: false,
  })
  const [previewActivity, setPreviewActivity] = useState<{
    id: string
    open: boolean
  }>()

  const [treeData, setTreeData] = useState<Array<ParentsNode>>()
  const [clickedNode, setClickedNode] = useState<ParentsNode>()
  const [initialData, setInitialData] = useState<Array<ParentsNode>>()
  const [submitedData, setSubmitedData] = useState<Array<ParentsNode>>()
  const [courseCondition, setCourseState] = useState<CourseCondition>(new CourseCondition())
  const [enableDrag, setEnableDrag] = useState<boolean>(false)

  useEffect(() => {
    if (!id) return
    setInitialData(courseDetail)
    setCourseState(course?.state?.condition ?? new CourseCondition())
    getCourseDetail(id)
  }, [id])

  useEffect(() => {
    if (!id) return
    setInitialData(courseDetail)
  }, [courseDetail])

  const mockData = new Array(5).fill(0)
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const typePractice = course?.course_type === ICourseType.PRACTICE
  const typeTrial = course?.course_type === ICourseType.TRIAL
  const typeNormal = course?.course_type === ICourseType.NORMAL
  const allowRenderEditCreateCourse = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_COURSE_GR.CREATE_COURSE) ||
      hasPermission(role, TITLE_COURSE_GR.EDIT_COURSE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const submitData = async (data: ParentsNode[], course_id: string) => {
    if (!data || !data.length) {
      toast.success(`Saved successfully`)
      return
    }
    // Kiểm tra và cập nhật giá trị của prop position
    const formattedData: ParentsNode[] = data.map((item, index) => ({
      ...item, // Giữ nguyên dữ liệu gốc
      position: index, // Cập nhật vị trí mới
    }))
    // Gọi API và truyền data và course_id vào hàm changePosition
    const res = await CoursesAPI.changePosition(formattedData, course_id)
    if (res?.success && id) {
      getCourseDetail(id)
      toast.success(`Saved successfully`)
    }
  }
  const flattenTreeData = (data: ParentsNode[]): ParentsNode[] => {
    return data.reduce<ParentsNode[]>((acc, node) => {
      // Thêm node hiện tại vào mảng kết quả
      acc.push(node)

      // Nếu node có children, thực hiện đệ quy để thêm các children vào mảng kết quả
      if (node.children && node.children.length > 0) {
        acc.push(...flattenTreeData(node.children))
      }

      return acc
    }, [])
  }

  useEffect(() => {
    if (treeData) {
      const flatData = flattenTreeData(treeData)

      const filteredData = flatData.filter(
        (item: ParentsNode) => item.course_section_type !== 'LEARNINGOUTCOME'
      )

      setSubmitedData(filteredData)
    }
  }, [treeData])

  useEffect(() => {
    const treeElement = document.querySelector('.sapp-course-content-tree')
    if (courseCondition && enableDrag === true) {
      treeElement?.classList.add('enable-drag')
    } else {
      treeElement?.classList.remove('enable-drag')
    }
  }, [courseCondition, enableDrag])

  const { contextHolder, confirm } = useConfirm()
  const navigate = useNavigate()
  // TODO: hàm cancel mở popup confirm trở về màn course list
  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(PageLink.COURSE_3_LEVEL),
    })
  }

  const handleClickEnableDrag = () => {
    if (!id) {
      toast.error('Invalid course ID!')
      return
    }
    submitData(submitedData ?? [], id) // Nếu submitedData là undefined, truyền vào một mảng rỗng
    setEnableDrag(false)
  }

  return (
    <>
      {contextHolder}
      <Processbar
        step={step ?? []}
        setNewStep={setStep}
        showButtonPrimary={enableDrag}
        onCancel={hanleCancel}
        onClick={enableDrag ? handleClickEnableDrag : () => handleGotoStep(2)}
        cancelButtonCaption={LANG_COURSES.courseList}
      >
        {allowRenderEditCreateCourse && (
          <>
            {!enableDrag && course?.state?.condition?.can_edit_course_content?.can_add_section && (
              <Dropdown.Item
                as='button'
                onClick={() =>
                  setOpenCreateSection({
                    status: true,
                    data: undefined,
                    type:
                      // courseDetail?.template === 4 ?
                      TYPE_COURSE.SECTION,
                    // : TYPE_COURSE.PARTIALCHAPTER,
                    id: id,
                  })
                }
              >
                New Section
              </Dropdown.Item>
            )}
            {
              <Dropdown.Item
                as='button'
                onClick={() =>
                  setOpenChooseTypeOfTestModal({
                    type: COURSE_TEST_TYPES.COURSE,
                    open: true,
                  })
                }
              >
                New Test
              </Dropdown.Item>
            }
            {!enableDrag &&
              course?.state?.condition?.can_edit_course_content?.can_drag_drop_course_section && (
                <Dropdown.Item as='button' onClick={() => setEnableDrag(!enableDrag)}>
                  Enable Drag
                </Dropdown.Item>
              )}
          </>
        )}
      </Processbar>
      <div className='sapp-course-content-tree'>
        {loading || loadingDelete ? (
          <>
            <div className='sapp-course-content-tree-first'>
              {mockData.map((number: number) => (
                <Skeleton.Input size='large' active />
              ))}
            </div>
            <div className='sapp-course-content-tree-second'>
              {mockData.map((number: number) => (
                <Skeleton.Input size='large' active />
              ))}
            </div>
          </>
        ) : (
          <>
            {courseDetail && courseDetail.length > 0 ? (
              <TreeCourse
                rawCourseData={rawCourseData}
                template={template}
                data={courseDetail}
                id={courseDetail.id}
                openCreateLearning={openCreateLearning}
                openCreateSection={openCreateLearning}
                setOpenCreateLearning={setOpenCreateLearning}
                setOpenCreateSection={setOpenCreateSection}
                setOpenCreateTab={setOpenCreateTab}
                setTreeData={setTreeData}
                treeData={treeData}
                setOpenCreateActivity={setOpenCreateActivity}
                setOpenPreviewActivity={setPreviewActivity}
                clickedNode={clickedNode}
                setClickedNode={setClickedNode}
                typePractice={typePractice}
                courseCondition={courseCondition}
                enableDrag={enableDrag}
                typeTrial={false}
              />
            ) : (
              <div className='sapp-blank-content'>
                <EmptyCourseInfo />
                <div className='sapp-blank-content-title'>
                  Nội dung khóa học chưa được tạo, hãy tạo ngay!
                </div>
              </div>
            )}
            <LinkSection
              open={open.status}
              setOpen={setOpen}
              position={courseDetail?.length}
              body='Create a new section by linking section from other course.'
              title='Link Section'
            />
            {/* <CreateSection open={openCreateSection.status} setOpen={setOpenCreateSection} data={openCreateSection.data} type={'section'}/>÷ */}
            <CreateSection
              open={openCreateSection.status}
              setOpen={setOpenCreateSection}
              data={openCreateSection.data}
              type={openCreateSection.type}
              id={openCreateSection.id}
              path={openCreateSection.path}
              treeData={treeData}
              treeIndex={openCreateSection.treeIndex}
              setData={setTreeData}
              positionPart={treeData?.length}
              positionSubsection={clickedNode?.children?.length}
            />
            <CreateLearningOutCome
              open={openCreateLearning.status}
              setOpen={setOpenCreateLearning}
              course_section_id={openCreateLearning.id || ''}
              type={openCreateLearning.type || ''}
              onlyEditName={openCreateLearning.onlyEditName}
            />
          </>
        )}
        {/* <CreateTabs open={openCreateTab} setOpen={setOpenCreateTab} /> */}
        <CreateActivity
          open={openCreateActivity.status}
          setOpen={setOpenCreateActivity}
          id={openCreateActivity.id}
          lo={openCreateActivity.lo}
          selfId={openCreateActivity.selfId}
          setData={setTreeData}
          treeData={treeData}
          path={openCreateActivity.path}
          data={openCreateActivity.data}
          position={clickedNode?.children?.length}
          onlyEditName={openCreateActivity.onlyEditName}
        />
        {previewActivity?.id && (
          <PreviewActivityModal
            previewActivity={previewActivity}
            setPreviewActivity={setPreviewActivity}
          ></PreviewActivityModal>
        )}
        <ChooseTypeOfTestModal
          open={openChooseTypeOfTestModal}
          setOpen={setOpenChooseTypeOfTestModal}
          template={template}
        ></ChooseTypeOfTestModal>
      </div>
    </>
  )
}
export default CourseContentMedium
