import React from 'react'
import { TeacherPriorityStatus } from 'src/type/teacher'
import styles from './styles.module.scss'
import { TEACHER_PRIORITY_STATUS_LABEL } from 'src/constants/users'

interface PriorityBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  priorityStatus: TeacherPriorityStatus
  children?: React.ReactNode
}

const getBadgeColor = (priorityStatus: TeacherPriorityStatus): string => {
  switch (priorityStatus) {
    case 'HIGH_PRIORITY':
      return 'bg-light-green text-green'
    case 'MEDIUM_PRIORITY':
      return 'bg-light-primary text-primary'
    case 'NON_PRIORITY':
      return 'bg-gray-100 text-black'
    case 'NO_LESSON_PLAN':
      return 'bg-gray-100 text-grey'
    default:
      return 'bg-gray text-white'
  }
}

const PriorityBadge: React.FC<PriorityBadgeProps> = ({
  priorityStatus,
  children,
  className,
  ...props
}) => {
  const badgeColor = getBadgeColor(priorityStatus)

  return (
    <div
      className={`inline-flex items-center px-3 py-1 rounded text-center ${badgeColor} ${className} ${styles.priorityBadge}`}
      {...props}
    >
      {TEACHER_PRIORITY_STATUS_LABEL[priorityStatus]}
    </div>
  )
}

export default PriorityBadge
