import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import LoadingTable from 'src/common/LoadingTable'
import { HEADER_ENTRANCE_TEST_LIST, TIME_OF_DAY } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import { toast } from 'react-hot-toast'
import SappTableProfile from './SappTableProfile'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import { formatDateTime, formatDistanceToNow } from 'src/utils'

interface IProps {
  ListDevice: any
  loading: boolean
  confirm: (option: any) => void
  setLoading: Dispatch<SetStateAction<boolean>>
  fetchListDevice: (id: any, page_index: number, page_size: number) => void
  onDeleteSection: (user_id: string, session_id: string) => void
}

// define headers
const headers = [
  {
    label: '#',
    className: 'min-w-50px',
  },
  {
    label: 'Browsers',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Lasted Login',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Location',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'IP AdDress',
    className: 'min-w-100px pe-5',
  },
]

const DeviceManager = ({ ListDevice, loading, confirm, onDeleteSection }: IProps) => {
  const { contextHolder } = useConfirm()
  const { profileMe } = useUserContext()
  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.REMOVE_SECURITY_USER_GR) ||
      role.permissions?.includes(TITLE_GR.REMOVE_DEVICE_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const { setValue } = useForm<any>({
    mode: 'onChange',
  })

  useEffect(() => {
    ListDevice?.map((e: any) => ({ label: e.status, value: e.status }))?.forEach(
      (option: any, index: any) => {
        setValue(`status${index}`, option.value)
      }
    )
  }, [setValue, ListDevice])

  // const Action = (type: string, data: any) => {
  //   if (type === 'edit') {
  //     navigate(`${CLASS_PROFILE}/${data.id}/${CLASS_PROFILE_URL.SETTING}`)
  //   }
  //   if (type === 'content') {
  //     setOpenEditCourseContent({status: true, type: 'participants-detail', data: data})
  //   }
  // }
  return (
    <div className='card-body py-4'>
      {contextHolder}
      <SappTableProfile
        headers={headers}
        loading={loading}
        data={ListDevice}
        isCheckedAll={''}
        onChange={() => {}}
        hasCheck={false}
      >
        {loading ? (
          <>
            <div className='line-Height'>
              {HEADER_ENTRANCE_TEST_LIST.map((header, i) => (
                <LoadingTable key={header} headers={HEADER_ENTRANCE_TEST_LIST} />
              ))}
            </div>
          </>
        ) : (
          <>
            {ListDevice.map((e: any, index: number) => {
              const loginTime = new Date(e.updated_at)
              const currentTime = new Date()

              const timeDifference = currentTime.getTime() - loginTime.getTime()

              let formattedTime

              if (timeDifference < TIME_OF_DAY) {
                formattedTime = formatDistanceToNow(loginTime)
              } else {
                formattedTime = formatDateTime(loginTime, 'HH:mm:ss DD/MM/YYYY')
              }
              return (
                <tr key={index} className='table-row-underline'>
                  <td>{index + 1}</td>
                  <td className='text-gray-800 fw-semibold sapp-fs-14'>
                    {e.user_agent?.browserName} {e.user_agent?.browserVersion} (
                    {e.user_agent?.osName})
                  </td>
                  <td>{formattedTime}</td>
                  <td>{e.location}</td>
                  <td>{e.ip}</td>
                  {allowRender && (
                    <td>
                      <div>
                        <ButtonIconOnly
                          bg='gray-100'
                          iconName={'trash'}
                          activeColor='danger'
                          onClick={() => {
                            setTimeout(() => {
                              confirm({
                                okButtonCaption: 'Yes',
                                cancelButtonCaption: 'No',
                                body: 'Bạn có chắc chắn muốn xóa không?',
                                onClick: () => {
                                  onDeleteSection(e.user_id, e.id)
                                  toast.success('Delete successfully!')
                                },
                              })
                            })
                          }}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              )
            })}
          </>
        )}
      </SappTableProfile>
    </div>
  )
}

export default DeviceManager
