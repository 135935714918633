import { zodResolver } from '@hookform/resolvers/zod'
import { Skeleton } from 'antd'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import defaultImage from 'src/_metronic/assets/images/shop/image_default.png'
import { ClassesApi } from 'src/apis/classes'
import { UsersAPI } from 'src/apis/user'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { ACCEPT_UPLOAD_MIME_TYPE, DEFAULT_MAX_FILE_SIZE, VALIDATION_FIELD } from 'src/constants'
import { GetExamsBySubjectRes } from 'src/type'
import { z } from 'zod'
import SappModal from '../../../base/SappModal'
import { Student } from './types'
import HookFormTextArea from 'src/components/base/textaria/HookFormTextArea'
interface IProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  student: Student
}

interface Iform {
  examination_subject_id: string
  note: string
}
const ExamDeferModal = ({ openModal, setOpenModal, student }: IProps) => {
  const schema = z.object({
    examination_subject_id: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD }),
    note: z.string().optional(),
  })

  const { handleSubmit, control, reset, setValue, setError } = useForm<Iform>({
    resolver: zodResolver(schema),
  })

  const queryClient = useQueryClient()
  /**
   * @description sử dụng react-query để lấy data sau khi call API
   */
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, isSuccess } =
    useInfiniteQuery<GetExamsBySubjectRes>({
      queryKey: ['examsBySubject'],
      queryFn: ({ pageParam = 1 }) => {
        const res = ClassesApi.getExamsBySubject({
          page_index: pageParam,
          page_size: 10,
          id: student.class.id,
          student_id: student.user.id,
        })
        return res
      },
      getNextPageParam: (lastPage: any) => {
        return lastPage?.metadata?.page_index < lastPage?.metadata?.total_pages
          ? lastPage?.metadata.page_index + 1
          : undefined
      },
      enabled: openModal,
    })

  const { mutate, isLoading: isUpdatingExam } = useMutation({
    mutationFn: (data: Iform) =>
      UsersAPI.updateExam(student.user.id, {
        class_id: student.class.id,
        examination_subject_id: data.examination_subject_id,
        note: data.note,
      }),
    onSuccess: (res: any) => {
      toast.success(res.data.message)
      reset()

      void queryClient.invalidateQueries('ListExamStudents')
      setOpenModal(false)
    },
    onError: () => {
      toast.error("There's something wrong")
    },
  })
  const onSubmit: SubmitHandler<Iform> = (data) => {
    mutate(data)
  }

  return (
    <SappModal
      cancelButtonCaption='Cancel'
      okButtonCaption='OK'
      open={openModal}
      title={'Reschedule Exam'}
      handleClose={() => {
        reset()
        setOpenModal(false)
      }}
      centered
      classBody='pt-5 px-8 pb-8'
      handleSubmit={handleSubmit(onSubmit)}
      loading={isUpdatingExam}
      disabled={(isSuccess && data?.pages[0].data.length <= 0) || data === undefined}
    >
      <div className='d-flex flex-column'>
        <HookFormSelectAntd
          required
          label='Please select a new date for the exam'
          loading={isLoading || isFetchingNextPage}
          name={'examination_subject_id'}
          control={control}
          placeholder='Registered Exam Date'
          showSearch
          handleNextPage={() => hasNextPage && fetchNextPage()}
          options={
            data?.pages
              .flatMap((group) =>
                group.data.map((exam) => ({
                  label: exam.examination.name,
                  value: exam.id,
                }))
              )
              .filter((item) => item.value !== student?.examination_subject_id) || []
          }
        />

        <div className='mt-5'>
          <HookFormTextArea
            name='note'
            control={control}
            label='Note'
            disabled={(isSuccess && data?.pages[0].data.length <= 0) || data === undefined}
          />
        </div>
      </div>
    </SappModal>
  )
}

export default ExamDeferModal
